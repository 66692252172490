<template>
    <div class="tab-pane" id="week" role="tabpanel" aria-labelledby="week-tab">
        <table class="table">
        </table>
    </div>
</template>

<script>
    import Trans from "../../../mixins/Trans";
    import FormatTimeString from "../../../mixins/FormatTimeString";

    export default {
        name: 'tab-week',

        mixins: [Trans, FormatTimeString],

        computed: {
            baseUrl() {
                return this.$store.state.openingHours.baseUrl;
            },

            apiUrl() {
                return this.$store.state.openingHours.apiUrl;
            },

            exceptions() {
                return this.$store.getters['openingHours/getThisWeeksExceptions'];
            }
        },

        mounted() {
            setTimeout(() => {
                window.table = $('#week .table').DataTable({
                    "language": window.dtTranslations,
                    'processing': true,
                    'serverSide': true,
                    'ajax': this.apiUrl + '/exceptions?period=week',
                    'dom': '<<t>p>',
                    'pageLength': 10,
                    'columns': [
                        {data: 'is_available'},
                        {data: 'webshop_id', title: 'Webshop'},
                        {data: 'date', title: 'Datum'},
                        {data: 'from_till', title: 'Van / tot'},
                        {data: 'action', searchable: false, orderable: false, className: 'text-right'}
                    ],
                });
            });
        }
    }
</script>

<style scoped>
    table.dataTable {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
</style>
