<template>
    <div class="notification">
        <div class="notification-header">
            <b><span v-text="extraCost.created_at"></span></b>
        </div>
        <div>
            <div class="supplier-info" v-if="extraCost.supplier_price_excl">
                <div class="supplier-info-item">
                    <div class="supplier-info-item-icon">
                        <i class="fal fa-fw fa-euro-sign"></i>
                    </div>
                    <div class="supplier-info-item-col">Meerprijs klant</div>
                    <div class="supplier-info-item-col">{{extraCost.customer_price_incl | format_money}} <small>Incl. BTW</small></div>
                </div>
                <div class="supplier-info-item">
                    <div class="supplier-info-item-icon">
                        <i class="fal fa-fw fa-truck"></i>
                    </div>
                    <div class="supplier-info-item-col">Meerprijs leverancier</div>
                    <div class="supplier-info-item-col">{{extraCost.supplier_price_excl | format_money}} <small>Excl. BTW</small></div>
                </div>
                <div class="supplier-info-item">
                    <div class="supplier-info-item-icon">
                        <i v-if="extraCost.margin_percentage >= 0" class="fal fa-fw fa-long-arrow-up"></i>
                        <i v-if="extraCost.margin_percentage < 0" class="fal fa-fw fa-long-arrow-down"></i>
                    </div>
                    <div class="supplier-info-item-col">Marge</div>
                    <div v-if="extraCost.margin_percentage >= 0" class="supplier-info-item-col text-success">{{extraCost.margin_percentage }}%</div>
                    <div v-if="extraCost.margin_percentage < 0" class="supplier-info-item-col text-danger">{{extraCost.margin_percentage }}%</div>
                </div>
            </div>

            <div class="notification-message" v-if="extraCost.invoice_description">
                <span v-text="extraCost.invoice_description"></span>
            </div>
        </div>

        <div class="notification-footer">
            <span v-text="extraCost.extra_cost_type.name"></span>
            <span v-if="extraCost.persist_after_cancellation">(blijvend na annuleren)</span>

            <span v-if="extraCost.created_by"> aangemaakt door {{ extraCost.created_by }}</span>
        </div>

    </div>

</template>

<script>

    import FormControl from "../FormControl";

    export default {
        name: "ExtraCostDetails",
        components: { FormControl },
        props: ['extraCost'],

        data: () => ({
            editing: false,
        }),

        mounted() {

        },
        watch: {},
        methods: {}

    }
</script>
