import moment from 'moment'

export default {
  methods: {
    months (key = null) {
      let counter = 1
      const months = {}

      moment.months().forEach(item => {
        months[counter] = item
        counter++
      })

      if (key) {
        return months[key]
      }

      return months
    },
  }
}
