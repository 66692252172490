<template>
    <div v-show="$store.state.order.id && $store.getters.isEditable && $store.state.order.order_status_id == 'confirmed'">
        <div>
            <a @click="cancelOrder" class="btn-danger btn">
                Order annuleren
                <i class="fal fa-ban"></i>
            </a>
        </div>
        <Modal @onClose="onClose" @onSave="onSave" :show="showModal">
            <template v-slot:title>
                Annulering Order | Extra kosten
            </template>
            <template v-slot>
                <div v-show="!loading">
                    <ExtraCostForm ref="extraCostElement"></ExtraCostForm>
                </div>
                <div v-if="loading" class="col-4 d-flex justify-content-center align-items-center">
                    <div><i class="fa text-info fa-spinner fa-spin fa-2x"></i></div>
                </div>
            </template>
        </Modal>
    </div>

</template>

<script>

    import Modal from "./Modal";
    import ExtraCostForm from "./Exceptions/ExtraCostForm";

    export default {
        name: "CancelForm",
        components: { ExtraCostForm, Modal },
        props: [],

        data: () => ({
            showModal: false,
            loading: false,
        }),

        mounted() {

        },
        watch: {},
        methods: {
            cancelOrder() {
                this.showModal = true;
            },
            onSave() {
                this.loading = true;
                this.$refs.extraCostElement.save(false).then(() => {
                    this.$store.dispatch('cancelOrder').then(() => {
                        this.loading = false;
                        this.showModal = false;
                    });
                })
            },
            onClose() {
                this.showModal = false;
            }
        }

    }
</script>
