<template>
    <table class="table">
        <thead>
        <tr>
            <th>Dag</th>
            <th>Beschikbaar</th>
            <th>Van</th>
            <th>Tot</th>
            <th></th>
        </tr>
        </thead>

        <tbody>
            <daily-presence-row
                v-for="(data, day) in openingHours"
                :key="day"
                :day-name="day"
                :openingHour="data"
                :webshop-id="webshopId"
            ></daily-presence-row>
        </tbody>
    </table>
</template>

<script>
    import Trans from "../../mixins/Trans";
	import DailyPresenceRow from "./DailyPresenceRow";

	export default {
    	name: 'general-week-presence',

		components: {DailyPresenceRow},

		mixins: [Trans],

        props: {
    		webshopId: {
    			type: Number,
                required: true
            }
        },

        computed: {
			openingHours () {
			    console.log(this.$store.getters);
    	        return this.$store.getters['openingHours/getSortedGeneralForWebshop'](this.webshopId)
            }
        }
	}
</script>
