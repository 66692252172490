<template>
    <div class="summary-item">
        <div class="tag tag--square tag--withIcon">
            <div class="tag-icon tag-icon--location"></div>
        </div>
        <div class="summary-item-title">
            {{address.full_address}}
            <div class="summary-item-subtitle">
                <span v-if="date">{{date}} {{$store.state.order.delivery_preference_formatted}}</span>
                <span v-else>{{$store.state.order.preferred_delivery_at_formatted}} {{$store.state.order.delivery_preference_formatted}}</span>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'address-teaser',
        props: {
            address: { type: Object },
            date: {type: String, default: null}
        }
    }
</script>
