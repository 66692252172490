<template>
    <div>
        <div v-if="iframeUrl" class="d-flex flex-column p-3">
            <div class="d-flex justify-content-between">
                <div></div>
                <button @click="showMap = !showMap" class="btn btn-link">
                    Toon op kaart <i class="far fa-chevron-down"></i>
                </button>
            </div>
            <div>
                <div v-show="showMap">
                    <iframe v-if="showMap" :src="iframeUrl" frameborder="0" width="100%" height="700"></iframe>
                    <div class="text-sm">Powered by <a href="https://public.opendatasoft.com/explore/dataset/openpostcodevlakkenpc4/information/">OpenDataSoft</a></div>
                </div>
            </div>
        </div>

        <div v-if="success">
            <div class="box-content">
                <div class="alert alert-success">
                    <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
                    <i class="icon fa fa-ban"></i> <span @click="reload">Gegevens opgeslagen, klik hier om de pagina te verversen.</span>
                </div>
            </div>
        </div>

        <div v-show="!saving && show && !loading">
            <div class="box-content">
                <div class="form-row">
                    <div v-for="productGroup in productGroups" :key="productGroup.id" class="col">
                        <div class="form-item">
                            <label :for="'#product-group-' + productGroup.id" v-text="productGroup.name" class="control-label"></label>
                            <select :data-group="productGroup.id" multiple class="select2 js-group-day-select form-control">
                                <option :value="day.id" v-for="day in days"
                                        :selected="productGroup.selected.findIndex(d => d == day.id) !== -1"
                                        v-text="day.name"></option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">

                    <div class="col-5">
                        <div class="box-card box-card--single">
                            <div class="box-card-content">
                                <div class="form-row">
                                    <div class="col-6">
                                        <div class="form-item">
                                            <input :maxlength="4" class="form-control" placeholder="Van"
                                                   type="text"
                                                   v-model="from_zip_code">
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-item">
                                            <input :maxlength="4" class="form-control"
                                                   placeholder="t/m postcode"
                                                   type="text"
                                                   v-model="till_zip_code">
                                        </div>
                                    </div>
                                </div>

                                <button type="button" @click="loadZipCodes"
                                        class="btn btn-light">
                                    Reeks toevoegen
                                </button>
                            </div>
                        </div>

                    </div>
                    <div class="col-2">
                        <div class="box-card box-card--single">
                            <div class="box-card-content">
                                <div class="form-item">
                                    <select class="select2 js-municipality-select form-control">
                                        <option>Selecteer een gemeente</option>
                                        <option v-for="municipality in municipalities" :value="municipality.id"
                                                v-text="municipality.name" :key="municipality.id"></option>
                                    </select>
                                </div>

                                <button type="button" @click="loadZipCodes"
                                        class="btn btn-light">
                                    Gemeente toevoegen
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-2">
                        <div class="box-card box-card--single">
                            <div class="box-card-content">
                                <div class="form-item">
                                    <select class="select2 js-province-select form-control">
                                        <option>Selecteer een provincie</option>
                                        <option v-for="province in provinces" :value="province.id" v-text="province.name" :key="province.id"></option>
                                    </select>
                                </div>

                                <button type="button" @click="loadZipCodes"
                                        class="btn btn-light">
                                    Provincie toevoegen
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="box-card box-card--single">
                            <div class="box-card-content">
                                <div class="form-item">
                                    <select class="js-city-select form-control">
                                        <option>Selecteer een plaats</option>
                                        <option v-for="city in cities" :value="city.id"
                                                v-text="city.text" :key="city.id"></option>
                                    </select>
                                </div>

                                <button type="button" @click="loadZipCodes"
                                        class="btn btn-light">
                                    Plaats toevoegen
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="justify-content-end d-flex flex-fill">
                        <div class="mt-5">

                        </div>
                    </div>
                </div>

                <button type="button" @click="save" class="btn btn-primary">Opslaan</button>
            </div>

            <div class="box-content box-content--divided">


                <h1>Postcodes</h1>

                <table class="table">
                    <thead>
                    <tr>
                        <th>Postcode</th>
                        <th :width="calculateWidth()+ '%'" v-for="group in productGroups"
                            v-text="group.name"></th>
                        <th>Van periode</th>
                        <th>t/m periode</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(zipCode, i) in zipCodes" :key="'supplier-tr-'+zipCode.zip_code">
                        <td :width="calculateWidth()+ '%'"
                            v-text="zipCode.zip_code + ' (' + zipCode.city.name+')'"></td>
                        <td :width="calculateWidth()+ '%'"
                            v-for="group in zipCode.productGroups">
                            <select :data-zip-code="zipCode.zip_code" :data-group="group.id" multiple
                                    class="form-control js-day-select">
                                <option :value="day.id" v-for="day in days"
                                        :selected="group.selected.findIndex(d => d == day.id) !== -1"
                                        v-text="day.short"
                                        :key="day.id + supplier.id"></option>
                            </select>
                        </td>
                        <td>
                            <div class="form-row">
                                <div class="col-6">
                                    <input :maxlength="2" class="form-control form-control-sm" type="number"
                                           placeholder="Dag"
                                           v-model="zipCode.from_day">
                                </div>
                                <div class="col-6">
                                    <input :maxlength="2" class="form-control form-control-sm" type="number"
                                           placeholder="Maand"
                                           v-model="zipCode.from_month">
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="form-row">
                                <div class="col-6">
                                    <input :maxlength="2" class="form-control form-control-sm" type="number"
                                           placeholder="Dag"
                                           v-model="zipCode.till_day">
                                </div>
                                <div class="col-6">
                                    <input :maxlength="2" class="form-control form-control-sm" type="number"
                                           placeholder="Maand"
                                           v-model="zipCode.till_month">
                                </div>
                            </div>

                        </td>
                        <td>
                            <button @click="deleteItem(i)" type="button" class="btn btn-sm btn-danger"><i
                                class="fa fa-trash"></i></button>
                        </td>
                    </tr>
                    </tbody>

                </table>

            </div>


            <div v-if="saving" class="d-flex align-items-center justify-content-center">
                <h4>
                    Aan het opslaan...
                </h4>
                <div>&nbsp;<i class="fas fa-3x fa-spin fa-spinner"></i></div>
            </div>
            <div v-if="loading" class="d-flex align-items-center justify-content-center">
                <h4>
                    Aan het laden
                </h4>
                <div>&nbsp;<i class="fas fa-3x fa-spin fa-spinner"></i></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SupplierOperatingDays",
        props: ['productGroupProps', 'municipalities', 'supplier', 'operatingDays', 'provinces'],
        data: () => ({
            productGroups: [],
            zipCodes: [],
            cities: [],
            from_zip_code: null,
            till_zip_code: null,
            municipality_id: null,
            city_id: null,
            showMap: false,
            validated: null,
            saving: false,
            success: false,
            show: true,
            loading: false,
            days: [
                {
                    id: 1,
                    englishName: 'monday',
                    name: 'Maandag',
                    short: 'ma',
                },
                {
                    id: 2,
                    englishName: 'tuesday',
                    name: 'Dinsdag',
                    short: 'di',
                },
                {
                    id: 3,
                    englishName: 'wednesday',
                    name: 'Woensdag',
                    short: 'wo',
                },
                {
                    id: 4,
                    englishName: 'thursday',
                    name: 'Donderdag',
                    short: 'do',
                },
                {
                    id: 5,
                    englishName: 'friday',
                    name: 'Vrijdag',
                    short: 'vr',
                },
                {
                    id: 6,
                    englishName: 'saturday',
                    name: 'Zaterdag',
                    short: 'za',
                },
                {
                    id: 0,
                    englishName: 'sunday',
                    name: 'Zondag',
                    short: 'zo',
                },
            ]
        }),


        methods: {
            calculateWidth() {
                return 100 / (this.productGroups.length + 3.5);
            },
            reload() {
                location.reload()
            },

            addZipCodes(zipCodes) {
                zipCodes.forEach((zipCode) => {
                    this.zipCodes.unshift({
                        zip_code: zipCode.zip_code,
                        productGroups: Object.assign([], this.productGroups.map(group => Object.assign({}, group))),
                        from_month: null,
                        till_month: null,
                        from_day: null,
                        till_day: null,
                        city: zipCode.city,
                    });


                });
                setTimeout(() => {
                    this.renderDaySelects()
                });

            },
            loadZipCodes() {
                this.loading = true;
                return axios.post(`/suppliers/${this.supplier.id}/operating-days/zip-codes`, {
                    from_zip_code: this.from_zip_code,
                    till_zip_code: this.till_zip_code,
                    municipality_id: this.municipality_id,
                    city_id: this.city_id,
                    province_id: this.province_id,
                    exclude: this.zipCodes.map(z => z.zip_code)
                }).then(res => {
                    this.loading = false;
                    bootbox.confirm({
                        message: "Weet je zeker dat je de volgende postcodereeksen wilt toevoegen? " + res.data.joined,
                        buttons: {
                            confirm: {
                                label: 'Ja',
                                className: 'btn-success'
                            },
                            cancel: {
                                label: 'nee',
                                className: 'btn-danger'
                            }
                        },
                        callback: (result) => {
                            if (result) {
                                this.addZipCodes(res.data.items);
                                return;
                            }
                        }
                    });
                    return res;
                })
            },
            updateGroupValues(productGroupId, values) {
                this.productGroups = this.productGroups.map((group) => {
                    if (group.id == productGroupId) {
                        group.selected = values;
                    }
                    return group;
                })
            },
            save() {
                this.saving = true;
                return axios.post('/suppliers/' + this.supplier.id + '/operating-days', {
                    zip_codes: this.zipCodes,
                }).then(res => {
                    this.saving = false;
                    this.show = false;
                    this.success = res.data.success;
                    return res;
                })
            },

            deleteItem(index) {
                this.zipCodes.splice(index, 1);
            },
            renderDaySelects() {
                let self = this;
                $('.js-day-select').each(function () {
                    if ($(this).data('select2')) {
                        return;
                    }
                    $(this).select2();
                    $(this).on('change', function () {
                        let zipCodeId = parseInt($(this).data('zip-code'));
                        let groupId = parseInt($(this).data('group'));
                        self.zipCodes = self.zipCodes.map((zipCode) => {
                            if (zipCode.zip_code === zipCodeId) {
                                zipCode.productGroups = zipCode.productGroups.map(group => {
                                    if (group.id === groupId) {
                                        group.selected = $(this).val();
                                    }
                                    return group;
                                })
                            }

                            return zipCode;
                        })
                    });
                });
            }
        },
        computed: {
            iframeUrl() {
                if (!this.zipCodes.length) {
                    return;
                }
                let uniqueZipCodes = [];
                uniqueZipCodes = this.zipCodes.filter(((value, index, array) => array.indexOf(value) === index));
                return 'https://public.opendatasoft.com/explore/embed/dataset/openpostcodevlakkenpc4/map/?q=' + uniqueZipCodes.map(z => z.zip_code).join(' OR ');
            }
        },


        mounted() {
            this.productGroups = this.productGroupProps.map(group => {
                group.selected = group.default_delivery_days.map(day => {
                    let i = this.days.findIndex(d => {
                        return d.englishName === day
                    });
                    return this.days[i].id
                });

                return group;
            });
            this.zipCodes = this.operatingDays;
            let self = this;

            setTimeout(() => {
                $('.js-group-day-select').on('select2:select', function () {
                    let productGroupId = $(this).data('group');
                    let values = $(this).val();
                    self.updateGroupValues(productGroupId, values);
                }).on('select2:unselect', function () {
                    let productGroupId = $(this).data('group');
                    let values = $(this).val();
                    self.updateGroupValues(productGroupId, values)
                });

                $('.js-municipality-select').on('change', () => {
                    this.municipality_id = $('.js-municipality-select').val();
                });
                $('.js-province-select').on('change', () => {
                    this.province_id = $('.js-province-select').val();
                });
                $('.js-city-select').select2({
                    dropdownParent: $('.js-city-select').closest('.form-row'),
                    width: '100%',
                    minimumInputLength: 2,
                    ajax: {
                        url: '/suppliers/operating-days/cities',
                        dataType: 'json',
                        data: (params) => {
                            return { search: params.term, type: 'public' };
                        },
                        processResults: (data) => {
                            let items = [];
                            data.data.forEach(item => {
                                items.push({ id: item.id, text: item.name })
                            });

                            this.cities = items;

                            return { results: items }
                        }
                    }
                }).on('change', () => {
                    this.city_id = $('.js-city-select').val();
                });
            });

            setTimeout(() => {
                this.renderDaySelects();
            });
        }
    }
</script>
