<template>
    <div>
        <div class="box-header">
            <h2>Producten</h2>
            <div class="box-header-tools" v-if="$store.state.order.id">
                <a id="productToggleDropdown" class="dropdown-toggle" href="#" role="button"
                   data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <div class="icon icon--ellipsis"></div>
                </a>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="productToggleDropdown">
                    <a href="#" class="dropdown-item" @click="overrulePickup">
                        Ophaling overschrijven
                    </a>
                    <a href="#" class="dropdown-item" @click="cancelPickup">
                        Ophaling annuleren
                    </a>
                    <a v-if="mainOrderItem.purchase_invoiced_at" href="#" class="dropdown-item" @click="restartPurchaseInvoicing">
                        Herstart inkoopfacturatie
                    </a>
                    <a v-if="(!mainOrderItem.purchase_invoiced_at && order.supplier) || order.has_invoiceable_purchased_items" href="#" class="dropdown-item" @click="triggerPurchaseInvoicing">
                        Trigger inkoopfacturatie
                    </a>
                </div>
            </div>
        </div>

        <div class="box-products">
            <div class="box-product-wrapper" v-for="(orderItem, i) in $store.state.order.order_items">
                <product-details :order-item="orderItem" :index="i" :key="i"></product-details>
            </div>

            <div v-if="$store.getters.isEditable">
                <div v-if="addProductMessage !== true" class="box-products-message">
                    <span class="tag tag--warning" v-text="addProductMessage"></span>
                </div>
                <div class="box-products-message"
                     v-if="addProductMessage === true && !$store.state.order.order_items.length">
                    <div class="box box--empty">
                        <div class="box-content">
                            <a href="#" class="btn btn-light" @click="$store.dispatch('showProductSelection')">Product toevoegen <i class="fal fa-plus"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <overrule-pickup-form></overrule-pickup-form>
        <cancel-pickup-form></cancel-pickup-form>
        <order-item-form></order-item-form>
        <restart-purchase-invoice-form></restart-purchase-invoice-form>
    </div>

</template>

<script>

import FormControl from "./FormControl";
import ProductDetails from "./ProductDetails";
import OrderItemForm from "./OrderItemForm";
import OverrulePickupForm from "./OverrulePickupForm";
import CancelPickupForm from "./CancelPickupForm";
import RestartPurchaseInvoiceForm from "./RestartPurchaseInvoiceForm";

export default {
    name: "ProductForm",
    components: { RestartPurchaseInvoiceForm, OverrulePickupForm, OrderItemForm, ProductDetails, FormControl, CancelPickupForm },
    computed: {
        addProductMessage: function () {
            if (!this.$store.state.order.webshop) {
                return 'Selecteer een webshop';
            }

            if (!this.$store.state.order.customer) {
                return 'Selecteer een klant.';
            }

            return true;
        },
        mainOrderItem() {
            return this.order.order_items[0];
        },
        order(){
            return this.$store.state.order;
        }
    },
    methods: {
        overrulePickup() {
            if (!this.$store.getters.isEditable) {
                return;
            }

            this.$store.dispatch('showOverruleModal');
        },
        cancelPickup() {
            if (!this.$store.getters.isEditable) {
                return;
            }

            this.$store.dispatch('showCancelPickupModal');
        },
        restartPurchaseInvoicing() {
            if (!this.$store.getters.isEditable) {
                return;
            }

            this.$store.dispatch('showRestartPurchaseInvoicing');
        },
        triggerPurchaseInvoicing() {
            this.$store.dispatch('triggerPurchaseInvoicing');
        },
        edit() {
            if (this.$store.getters.isEditable) {
                this.$store.dispatch('showProductSelection', {
                    order_item: this.$store.state.order.order_items[0],
                });
            }
        }
    }

}
</script>
