<template>
    <modal @onClose="$emit('onClose')" :show="show" :hide-save="true">
        <template #title>
            Advies data
        </template>
        <order-item-teaser v-if="orderItem" :order-item="orderItem"></order-item-teaser>
        <pre>{{ order.advice_data }}</pre>
    </modal>
</template>

<script>

import OrderItemTeaser from "./OrderItemTeaser";
import ProductTeaser from "./ProductTeaser";
import Modal from "./Modal";

export default {
    name: "AdviceDataModal",
    props: ['show'],
    components: { OrderItemTeaser, ProductTeaser, Modal },
    computed: {
        order: function () {
            return this.$store.state.order;
        },
        orderItem: function () {
            return this.$store.state.order.order_items[0];
        },
    },
    mounted() {
        $('[data-toggle="data-tooltip"]').tooltip();
    },
    methods: {
        close() {
            this.unlink = false;
            this.$emit('onClose');
        },
    },

}
</script>
