<template>
    <div class="box-product">
        <div :class="(order.supplier ? 'box-product-header--success box-product-header' : 'box-product-header--warning') + ' box-product-header'">
	        <span v-if="$store.getters.isEditable">
			<a @click="edit" class="box-product-header-link">
            	<product-teaser :order-item="orderItem" :with-prices="true"/>
			</a>
            </span>
            <span v-else>
            	<product-teaser :order-item="orderItem" :with-prices="true"/>
            </span>

            <a :aria-controls="'#product-'+orderItem.track_id"
               data-toggle="collapse"
               aria-expanded="true"
               :href="'#product-'+orderItem.track_id"
               class="box-product-toggle show">
                <i class="fal fa-chevron-down"></i>
            </a>


        </div>
        <div v-if="index === 0" class="collapse show" :id="'product-'+orderItem.track_id">

            <div class="box-product-content">
                <div class="alert alert--orange" v-if="orderItem.custom_price_incl !== null">
                    Let op, dit product heeft een aangepaste prijs<span v-if="orderItem.custom_price_reason !== null">: {{orderItem.custom_price_reason}}</span><span v-if="orderItem.custom_price_type !== null">: {{orderItem.custom_price_type.name}}</span>
                </div>
                <div class="form-row pointer" @click="edit">
                    <form-control class="col-lg-6" :value="order.preferred_delivery_at_formatted" label="Voorkeur leverdatum"></form-control>
                    <form-control class="col-lg-6" :value="order.delivery_at_formatted" label="Definitieve leverdatum"></form-control>
                </div>
                <div class="form-row pointer" @click="edit">
                    <form-control class="col-lg-6" :value="order.preferred_pickup_at_formatted" label="Voorkeur ophaaldatum"></form-control>
                    <form-control class="col-lg-6" :value="order.pickup_at_formatted" label="Definitieve ophaaldatum"></form-control>
                </div>

                <div class="box-product-info">
                    <div @click="edit" :class="'box-product-info-item ' + ($store.getters.isEditable ? 'pointer' : '') " v-if="order.delivery_preference_id"><i class="far fa-fw fa-clock"></i>&nbsp; <span>Voorkeur dagdeel leveren:</span> {{deliveryPreferenceFormatted}}</div>
                    <div @click="edit" :class="'box-product-info-item ' + ($store.getters.isEditable ? 'pointer' : '') " v-if="order.time_indication"><i class="far fa-fw fa-clock"></i>&nbsp; <span>Tijdsindicatie leveren:</span> {{order.time_indication}}</div>
                    <div @click="edit" :class="'box-product-info-item ' + ($store.getters.isEditable ? 'pointer' : '') " v-if="order.pickup_preference_id"><i class="far fa-fw fa-history"></i>&nbsp; <span>Voorkeur dagdeel ophalen:</span> {{pickupPreferenceFormatted}}</div>
                    <div @click="edit" :class="'box-product-info-item ' + ($store.getters.isEditable ? 'pointer' : '') " v-if="order.pickup_time_indication"><i class="far fa-fw fa-history"></i>&nbsp; <span>Tijdsindicatie ophalen:</span> {{order.pickup_time_indication}}</div>
                    <div @click="edit" :class="'box-product-info-item ' + ($store.getters.isEditable ? 'pointer' : '') " v-if="order.call_before"><i class="far fa-fw fa-phone"></i>&nbsp; Bel 30 min. voor levering</div>
                    <div @click="open(order.tracking_url)" :class="'box-product-info-item ' + ($store.getters.isEditable ? 'pointer' : '') " v-if="order.tracking_url"><i class="far fa-fw fa-location"></i>&nbsp; <span>Track & Trace:</span> {{order.tracking_url}}</div>
                    <div @click="edit" :class="'box-product-info-item ' + ($store.getters.isEditable ? 'pointer'  : '') " v-if="order.notes"><i class="far fa-fw fa-pencil"></i>&nbsp; {{order.notes}}</div>
                    <div @click="edit" :class="'box-product-info-item ' + ($store.getters.isEditable ? 'pointer'  : '') " v-if="order.delivery_instructions"><i class="far fa-fw fa-truck-container"></i>&nbsp; {{order.delivery_instructions}}</div>
                    <div @click="edit" :class="'box-product-info-item ' + ($store.getters.isEditable ? 'pointer'  : '') " v-if="order.pickup_instructions"><i class="far fa-fw fa-truck-loading"></i>&nbsp; {{order.pickup_instructions}}</div>
                </div>

            </div>
        </div>

    </div>

</template>

<script>

    import FormControl from "./FormControl";
    import ProductTeaser from "./ProductTeaser";

    export default {
        name: "ProductDetails",
        components: {ProductTeaser, FormControl},
        props: ['orderItem', 'editing', 'index'],
        computed: {
            order() {
                console.log(this.$store.state.order);
                return this.$store.state.order;
            },
            deliveryPreferenceFormatted(){
                if (this.$store.state.order.delivery_preference_formatted){
                    return this.$store.state.order.delivery_preference_formatted;
                }
                let id = this.$store.state.order.delivery_preference_id;
                if (id){
                    return this.$store.state.deliveryPreferences.find(p => p.id === id).name;
                }
            },
            pickupPreferenceFormatted(){
                if (this.$store.state.order.pickup_preference_formatted){
                    return this.$store.state.order.pickup_preference_formatted;
                }
                let id = this.$store.state.order.pickup_preference_id;
                if (id){
                    return this.$store.state.pickupPreferences.find(p => p.id === id).name;
                }
            },
        },
        methods: {
            edit() {
                if (this.$store.getters.isEditable) {
                    this.$store.dispatch('showProductSelection', {
                        order_item: this.order.order_items[0],
                    });
                }
            },
            open(url){
                window.open(url, '_blank');
            }
        }

    }
</script>
