<template>
    <div v-if="order">
        <div v-if="$store.state.loading">
            <div class="d-flex justify-content-center align-items-center min-vh-100">
                <div><i class="fa text-info fa-spinner fa-spin fa-2x"></i></div>
            </div>
        </div>
        <div v-else>
            <div>
                <webshop-form :webshops="webshops"></webshop-form>
                <header class="app-header" v-if="order.webshop">
                    <div class="app-header-tools">
                        <a href="/master-data/tasks" class="btn btn-light btn--withIcon">
                            <div class="btn-icon btn-icon--primary">
                                <i class="far fa-bell"></i>
                            </div>
                            <strong>{{ taskAmount }}</strong> taken
                        </a>
                    </div>
                    <div class="app-header-tools">
                        <a class="js-notes-button btn btn-tool">
                            <i class="fal fa-sticky-note fa-fw"></i>
                            {{ order.notes_count }}
                        </a>
                        <a class="js-event-log-button btn btn-tool">
                            <i class="fal fa-list"></i>
                        </a>
                        <a href="#" class="btn btn-tool btn-tool--conversationBar js-toggleConversationBar">
                            <i class="fal fa-comments"></i>
                        </a>
                    </div>
                </header>

                <div class="page-header" v-if="order.webshop">
                    <div class="page-header-title">
                        <a :href="redirect ? redirect : '/orders'" class="page-header-title-action"><i class="fal fa-chevron-left"></i> <span v-text="redirect ? 'Terug' : 'Terug naar alle orders'"></span></a>
                        <span class="page-header-title-name" v-if="order.id && order.order_status_id === 'concept'">
                            Concept order - {{ order.order_nr }}
                            <a :title="'Dit is een wisselorder met order #' + order.parent.order_nr" data-toggle="tooltip" v-if="order.is_swap_order && order.parent" :href="'/orders/' + order.parent.id +'/edit'">
                                <i class="fal fa-exchange"></i>
                            </a>
                            <a :title="'Deze order is gewisseld, Wisselorder: #' + order.swap_order.order_nr" data-toggle="tooltip" v-if="order.swap_order_id && order.swap_order" :href="'/orders/' + order.swap_order.id +'/edit'">
                                <i class="fal fa-sync"></i>
                            </a>
                        </span>
                        <span class="page-header-title-name" v-if="order.id && $store.getters.isConfirmed">
                            Order #{{ order.order_nr }}
                            <a :title="'Dit is een wisselorder met order #' + order.parent.order_nr" data-toggle="tooltip" v-if="order.is_swap_order && order.parent" :href="'/orders/' + order.parent.id +'/edit'">
                                <i class="fal fa-exchange"></i>
                            </a>
                            <a :title="'Deze order is gewisseld, Wisselorder: #' + order.swap_order.order_nr" data-toggle="tooltip" v-if="order.swap_order_id && order.swap_order" :href="'/orders/' + order.swap_order.id +'/edit'">
                                <i class="fal fa-sync"></i>
                            </a>
                        </span>
                        <span class="page-header-title-name" v-if="order.id && order.is_cancelled">Geannuleerde order #{{ order.order_nr }}</span>
                        <span class="page-header-title-name" v-if="order.id && order.was_imported">Geïmporteerde order #{{ order.order_nr }}</span>
                    </div>

                    <div class="page-header-tools">
                        <div class="page-header-status" v-if="order.id && order.order_status_id === 'concept'">
                            Deze order is een concept
                        </div>
                        <div class="page-header-status" v-if="order.id && $store.getters.isConfirmed">
                            Deze order is actief
                        </div>

                        <prio-form></prio-form>
                        <delete-concept-order-form></delete-concept-order-form>
                        <cancel-form></cancel-form>

                        <div v-if="order.payment_method">
                            <div v-if="!order.id">
                                <loading-button
                                    v-if="order.payment_method.direct_debit && !order.customer.iban_account_number"
                                    childClass="btn btn-warning disabled"
                                    :loading="false"
                                >
                                    <i class="far fa-exclamation-triangle"></i> Geen IBAN voor klant
                                </loading-button>

                                <loading-button
                                    v-else-if="order.payment_method.confirms_immediately"
                                    childClass="btn btn-primary"
                                    :loading="$store.state.disableOrderCreateButton"
                                    @click.native="$store.dispatch('createOrder', true)"
                                >
                                    Order aanmaken
                                    <span v-if="order.payment_method.creates_payment_request"> + betaalverzoek sturen</span>
                                </loading-button>

                                <loading-button
                                    v-else
                                    childClass="btn btn-light"
                                    :loading="$store.state.disableOrderCreateButton"
                                    @click.native="$store.dispatch('createOrder', true)"
                                >
                                    Concept opslaan + betaalverzoek sturen
                                </loading-button>
                            </div>

                            <div
                                v-if="order.id && order.order_status_id === 'concept' && $store.getters.isEditable && (order.payment_method.manual_payment || order.payment_method.can_overrule_confirmation || order.customer.can_overrule_confirmation)">
                                <loading-button
                                    childClass="btn btn-primary"
                                    :loading="$store.state.disableOrderCreateButton"
                                    @click.native="$store.dispatch('confirmOrder', false)"
                                >
                                    Order bevestigen
                                </loading-button>
                            </div>

                            <div v-if="order.id && order.supplier && !order.pickup_at && $store.getters.isEditable && $store.getters.isConfirmed && !order.swap_order_id">
                                <button type="button" @click="showSwapOrder = true" class="btn btn-primary">
                                    Wissel aanvragen <i class="far fa-exchange"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
                <div v-if="order.webshop">
                    <order-tasks v-if="order.id"></order-tasks>
                    <div class="row">
                        <customer-details class="col-md-6"></customer-details>
                        <order-details class="col-md-6"></order-details>
                    </div>

                    <div class="alert alert--success mt-3" v-if="this.$store.state.callId && !order.id">
                        <i class="far fa-phone mr-2"></i>
                        <p class="mb-0 d-inline-block mr-2">
                            Wanneer je deze order opslaat, wordt hij gekoppeld aan het telefoongesprek.
                        </p>
                        <a class="btn btn-white btn-sm" @click="$store.dispatch('cancelCallId', null)">Annuleren</a>
                    </div>

                    <div class="box boxGrid--offsetTop">
                        <rental-period-indicator :key="orderItem.track_id" v-for="orderItem in $store.getters.rentOrderItems" :order-item="orderItem"></rental-period-indicator>
                    </div>

                    <div class="boxGrid boxGrid--offsetTop">
                        <div class="boxGrid-col boxGrid-col--fullHeight">
                            <div class="box">
                                <product-form></product-form>
                            </div>
                        </div>
                        <div class="boxGrid-col boxGrid-col--fullHeight">
                            <div class="box">
                                <supplier-details></supplier-details>
                            </div>
                        </div>
                        <div class="boxGrid-col boxGrid-col--fullHeight">
                            <div class="box">
                                <exception-details></exception-details>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <swap-form :show="showSwapOrder" @close="showSwapOrder = false"></swap-form>
        </div>
    </div>
</template>

<script>
import ProductForm from "./ProductForm";
import WebshopForm from "./WebshopForm";
import ExceptionDetails from "./Exceptions/ExceptionDetails";
import FormControl from "./FormControl";
import Modal from "./Modal";
import CancelForm from "./CancelForm";
import CustomerDetails from "./CustomerDetails";
import RentalPeriodIndicator from "./RentalPeriodIndicator";
import LinkedOrders from "./LinkedOrders";
import SupplierDetails from "./Supplier/SupplierDetails";
import SwapForm from "./SwapForm";
import PrioForm from "./PrioForm";
import OrderTasks from "./OrderTasks";
import LoadingButton from "../../LoadingButton";
import OrderDetails from "./OrderDetails.vue";
import DeleteConceptOrderForm from "./DeleteConceptOrderForm.vue";

export default {
    name: "OrderForm",
    components: {
        DeleteConceptOrderForm,
        OrderDetails,
        LoadingButton,
        OrderTasks,
        PrioForm,
        SwapForm,
        SupplierDetails,
        ExceptionDetails,
        LinkedOrders,
        RentalPeriodIndicator,
        CustomerDetails,
        CancelForm,
        Modal,
        FormControl,
        WebshopForm,
        ProductForm,
    },
    props: ['orderResource', 'taskAmount', 'redirect', 'call_id', 'webshops', 'canCreateOrdersToday', 'deliveryPreferences', 'pickupPreferences', 'orderStatuses', 'vatRates', 'extraCostTypes', 'orderOrigins', 'orderCustomerId', 'isProduction', 'customPriceTypes'],
    data() {
        return {
            showAdviceDataModal: false,
            showSwapOrder: false,
        }
    },
    computed: {
        order() {
            return this.$store.state.order;
        },
    },
    created() {
        window.addEventListener('beforeunload', this.beforeWindowUnload)
    },
    beforeDestroy() {
        window.removeEventListener('beforeunload', this.beforeWindowUnload)
    },
    mounted() {
        this.$store.commit('setOrder', this.orderResource);
        this.$store.commit('setCallId', this.call_id);
        this.$store.commit('setCanCreateOrdersToday', this.canCreateOrdersToday);
        this.$store.commit('setDeliveryPreferences', this.deliveryPreferences);
        this.$store.commit('setPickupPreferences', this.pickupPreferences);
        this.$store.commit('setOrderStatuses', this.orderStatuses);
        this.$store.commit('setVatRates', this.vatRates);
        this.$store.commit('setExtraCostTypes', this.extraCostTypes);
        this.$store.commit('setOrderOrigins', this.orderOrigins);
        this.$store.commit('setIsProduction', this.isProduction);

        this.$store.commit('setCustomPriceTypes', this.customPriceTypes);
        if (this.$store.state.order.webshop) {
            this.$store.dispatch('getPaymentMethodsForWebshop', {
                webshop_id: this.$store.state.order.webshop.id,
            });
        }
    },
    methods: {
        beforeWindowUnload(e) {
            if (this.$store.state.isConcept) {
                e.preventDefault();
                e.returnValue = '';
                return 'De order is nog niet opgeslagen. Weet je zeker dat je dit tabblad wil sluiten?';
            }
        },
    },
}
</script>
