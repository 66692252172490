<template>

    <div class="row">
        <div class="col-2">
            <h4> Mijn containers</h4>
            <div>
                <div class="form-item form-item--checkbox" v-for="product in products">
                    <input name="selected_products" v-model="selectedProducts" type="checkbox" :id="'call-before' + product.id" :value="product.id">
                    <label :style="'--check-color:' + product.color" :for="'call-before' + product.id" class="control-label">
                        <strong>{{ product.product }} {{ product.options }}</strong>
                        <br>
                        {{ product.address }}
                        <br>
                        <a :href="'/account/ledigingskalender/' + product.id + '/print'" target="_blank" v-if="showPrint">
                            <i class="fal fa-print fa-fw"></i> Print kalender
                        </a>
                    </label>
                </div>
            </div>
        </div>
        <div class="col-10">

            <v-calendar
                ref="calendar"
                locale="nl"
                is-range
                :min-date="minDate"
                :max-date="maxDate"
                :attributes="attributes"
                :columns="$screens({ lg: 4, md: 2, sm: 1, })"
                :rows="$screens({ lg: 3, md: 6, sm: 1 })"
                :is-expanded="true"
            >
                <div slot="day-popover" slot-scope="{ attributes, day, format, masks }">
                    <h5>Lediging op {{ format(day.date, "WWWW DD MMMM YYYY") }}</h5>

                    <div v-for="(attribute, key) in attributes" :key="key">
                        <div class="d-flex flex-row align-items-center justify-content-between">
                            <div class="d-flex flex-column">
                                <strong>
                                    <i class="far fa-exclamation-triangle" v-if="attribute.customData.exceptions.includes(day.date)"></i>
                                    {{ attribute.customData.product }}
                                </strong>
                                <span :style="'color: ' + attribute.customData.color">{{ attribute.customData.options }}</span>
                            </div>
                            <div v-if="showSuppliers">
                                <supplier-name :supplier="attribute.customData.occurrences[0].supplier"></supplier-name>
                            </div>
                        </div>
                    </div>
                </div>
            </v-calendar>

        </div>
    </div>

</template>

<script>
import SupplierName from "../Sales/Orders/Supplier/SupplierName";
import VCalendar from 'v-calendar/lib/components/calendar.umd.min'

export default {
    name: "EmptyingCalendar",
    components: { SupplierName, VCalendar },
    props: {
        products: {
            type: Array,
            default: [],
        },
        showSuppliers: {
            type: Boolean,
            default: false
        },
        showPrint: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            data: [],
            selectedProducts: [],
            maxDate: moment().endOf('year').add(1, 'year').toDate(),
            minDate: moment().startOf('year').toDate(),
            value: moment().startOf('year').toDate()
        }
    },
    mounted() {
        let today = moment().startOf('year');
        this.$refs.calendar.move({ year: today.get('year'), month: 1 })
        this.data = this.products;
        this.selectedProducts = this.data.filter(p => p.occurrences.length > 0).map(p => p.id);
    },
    computed: {
        attributes() {
            let attrs = this.data.filter(p => this.selectedProducts.findIndex(pr => pr == p.id) !== -1).map(product => {
                return {
                    // An optional key can be used for retrieving this attribute later,
                    // and will most likely be derived from your data object
                    key: product.id,
                    // Attribute type definitions
                    dot: {
                        style: {
                            backgroundColor: product.color,
                        },
                    },        // Boolean, String, Object
                    // popover: { ... }, // Only objects allowed
                    // Your custom data object for later access, if needed
                    customData: product,
                    // We also need some dates to know where to display the attribute
                    // We use a single date here, but it could also be an array of dates,
                    //  a date range or a complex date pattern.
                    dates: product.occurrences.map(o => o.date),
                    // You can optionally provide dates to exclude
                    //TODO: check temp stops
                    excludeDates: null,
                    popover: true,
                    // Think of `order` like `z-index`
                    order: 9
                }
            })

            let exceptions = this.data.filter(p => this.selectedProducts.findIndex(pr => pr == p.id) !== -1).map(product => {
                return {
                    key: product.id + '-exception',
                    // customData: product,
                    highlight: {
                        color: 'red',
                        fillMode: 'light',
                    },
                    dates: product.exceptions.map(o => o.date),
                    order: 7
                }
            })

            return [
                ...attrs,
                ...exceptions
                , {
                    key: 'today',
                    highlight: {
                        fillMode: 'light',
                    },
                    dates: new Date(),
                    order: 8
                }
            ];
        }
    }

}
</script>
