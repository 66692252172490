<template>
    <modal @onClose="close" :show="$store.state.outsourcing" v-if="location">
        <order-item-teaser v-if="orderItem" :order-item="orderItem"></order-item-teaser>

        <h3>Uitbesteding</h3>
        <div class="form-row">
            <div class="col">

                <form-control class="form-item--compact" name="location_id" label="Nieuwe leverancier" :edit="true">
                    <template v-slot:input>
                        <div class="form-item-select">
                            <dropdown-select class="form-view"
                                             @onSelect="onLocationSelect"
                                             :preload="false"
                                             :itemCallback="(item) => ({
                                                    id: item.id,
                                                    value: item.name,
                                                 })"
                                             url="/company/api/sales/orders/supplier/locations">
                            </dropdown-select>
                        </div>
                    </template>
                </form-control>
            </div>
            <div class="col" v-if="!order.purchase_invoice_items.length">
                <div class="form-item form-item--checkbox form-item--noLabel">
                    <input v-model="persistInvoiceItems" :disabled="!valid" type="checkbox"
                           id="persist-items">
                    <label for="persist-items" class="control-label">{{location.name}} factureert deze order</label>
                </div>
            </div>
        </div>

        <div v-if="newLocation">
            <div v-for="line in invoiceItems" class="form-row">
                <div class="col d-flex flex-row align-items-center">
                    {{line.description}}
                </div>
                <div class="col">
                    <form-control class="form-item--compact" name="invoice_supplier" label="Leverancier" :edit="true">
                        <template v-slot:input>
                            <div class="form-item-select">
                                <dropdown-select class="form-view"
                                                 @onSelect="(item) => onLineSelect(line, item)"
                                                 :preload="false"
                                                 :initialSelected="line.supplier"
                                                 :itemCallback="(item) => ({
                                                    id: item.id,
                                                    value: item.name,
                                                 })"
                                                 url="/company/api/sales/orders/supplier/locations">
                                </dropdown-select>
                            </div>
                        </template>
                    </form-control>
                </div>

            </div>
        </div>


        <div class="alert alert--warning" v-if="valid">
            <strong>
                Let op, door deze keuze wordt de order uitbesteed aan {{newLocation.name}}
            </strong>
            <ul>
                <li v-if="persistInvoiceItems && !this.invoiceItems.length">{{location.name}} zal de factuur sturen. De inkoop regel(s) worden aangemaakt.</li>
                <li v-if="persistInvoiceItems && this.invoiceItems.length">{{location.name}} zal de factuur sturen. De inkoop regel(s) blijven staan.</li>
                <li>Factuurregels zullen gekoppeld worden volgens bovenstaande configuratie.</li>
                <li>Enkel de nieuwe leverancier zal nieuwe mutaties ontvangen.</li>
            </ul>
        </div>
        <template #footer>
            <div>
                <button type="button" @click="close" class="btn btn-default">
                    <span>Annuleren</span>
                </button>
                <button v-if="valid" type="button" @click="save" class="btn btn-primary">
                    <span>Uitbesteden</span>
                </button>
            </div>
        </template>
    </modal>

</template>

<script>

    import Modal from "../Modal";
    import ProductTeaser from "../ProductTeaser";
    import OrderItemTeaser from "../OrderItemTeaser";
    import FormControl from "../FormControl";
    import SupplierName from "./SupplierName";

    export default {
        name: "OutsourceModal",
        components: { SupplierName, FormControl, OrderItemTeaser, ProductTeaser, Modal },
        data: () => ({
            newLocation: null,
            persistInvoiceItems: false,
            invoiceItems: [],
        }),
        computed: {
            valid: function () {
                return this.newLocation !== null && this.newLocation.id !== this.location.id;
            },
            location: function () {
                return this.$store.state.order.location;
            },
            orderItem: function () {
                return this.$store.state.order.order_items[0];
            },
            order: function () {
                return this.$store.state.order;
            },
        },
        mounted() {
        },
        methods: {
            onLocationSelect(value) {
                this.invoiceItems = this.order.purchase_invoice_items;
                this.newLocation = value;
            },
            onLineSelect(line, location) {
                var items = Object.assign([], this.invoiceItems);
                items[this.invoiceItems.indexOf(line)].location = location;
                this.invoiceItems = items;
            },
            save() {
                if (!this.valid) {
                    return;
                }
                this.$store.dispatch('saveOutsource', {
                    old_location_id: this.location.id,
                    new_location_id: this.newLocation.id,
                    persist_invoice_items: this.persistInvoiceItems,
                    items: this.invoiceItems,
                }).then(() => {
                    this.close();
                })
            },
            close() {
                this.invoiceItems = [];
                this.newLocation = [];
                this.$store.dispatch('stopOutsourcing')
            },
        },

    }
</script>
