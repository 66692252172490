<template>
    <div>
		<div class="form-item">
            <label class="control-label" for="price">Prijs</label>
            <select class="form-control" id="price" v-model="priceId">
                <option v-for="price in prices" :value="price.id" v-text="'Vanaf ' + price.date_formatted"></option>
            </select>
        </div>

        <table class="table" v-if="bundlesForPrice.length">
	        <thead>
		        <tr>
			        <th>
				        Product
			        </th>
			        <th>
				        Attribuut
			        </th>
			        <th>
				        Bundel product
			        </th>
			        <th>
				        Bundel attribuut
			        </th>
			        <th>
				        Prijs
			        </th>
			        <th>

			        </th>
		        </tr>
	        </thead>
	        <tbody>
	            <tr style="cursor: pointer" v-for="bundle in bundlesForPrice" @click="selectedBundle = bundle"
	                :class="selectedBundle.id == bundle.id ? 'tr--selected' : 'box-card'">
	                <td>
	                    {{bundle.product.name}}
	                </td>
	                <td>
	                    {{bundle.price_product_attribute.name}} {{bundle.price_product_attribute_option.name}}
	                </td>
	                <td>
	                    {{bundle.bundle_product.name}}
	                </td>
	                <td>
	                    {{bundle.bundle_attribute.name}} {{bundle.bundle_attribute_option.name}}
	                </td>
	                <td>
		                {{ bundle.discounted_bundle_price | format_money}}
	                </td>
	                <td>
	                    <span v-if="bundle.end_date">{{bundle.end_date | toDateString}}</span><span v-else><i
	                    class="fal fa-calendar-exclamation"></i></span>
	                </td>

	            </tr>
	        </tbody>
        </table>
        <div v-else>
	        <p>
            Er zijn nog geen bundels voor dit product
	        </p>
        </div>

        <div class="box-columns">
	        <div class="box-column">

	            <div v-if="selectedBundle.id" class="box-cards">
	                <div class="box-card box-card--toolSpacing">
		                <div class="box-card-content">
							<dl class="priceList">
								<dt>{{selectedBundle.product.name}}<br>{{selectedBundle.price_product_attribute.name}} {{selectedBundle.price_product_attribute_option.name}}</dt>
								<dd>{{selectedBundle.price_product_attribute_option.price_incl | format_money}}</dd>

								<dt>{{selectedBundle.bundle_product.name}}<br>{{selectedBundle.bundle_attribute.name}} {{selectedBundle.bundle_attribute_option.name}}</dt>
								<dd>{{selectedBundle.bundle_attribute_option.price_incl | format_money}}</dd>

								<dt v-if="selectedBundle.discount_percentage">Kortingspercentage</dt>
								<dd v-if="selectedBundle.discount_percentage">{{selectedBundle.discount_percentage}}%</dd>

								<dt v-if="selectedBundle.discount_price">Kortingsbedrag</dt>
								<dd v-if="selectedBundle.discount_price">{{selectedBundle.discount_price | format_money}}</dd>

								<dt>Totaalprijs zonder korting</dt>
								<dd>{{getTotalPrice() | format_money}}</dd>

								<dt class="priceList-totals">Totaalprijs bundel</dt>
								<dd class="priceList-totals">{{getTotalPriceWithDiscount() | format_money}}</dd>
							</dl>

		                </div>

		                <div class="box-card-tools">
			                <a class="box-card-tool" href="#" @click="edit()"><i class="fal fa-pencil"></i></a>
		                </div>
	                </div>
	            </div>
	        </div>
	        <div class="box-column" v-if="price">
	            <BundleForm :edit-bundle="editBundle" @saved="onSaved" :product="product" :price="price"
	                        :product-attributes="price.product_attributes"></BundleForm>
	        </div>
        </div>
    </div>

</template>

<script>
    import BundleForm from "./BundleForm";

    export default {
        name: "ProductBundles",
        components: { BundleForm },
        props: ['product'],
        data: () => ({
            prices: [],
            priceId: null,
            price: null,
            bundles: [],
            selectedBundle: {},
            editBundle: null,
        }),

        mounted() {
            this.prices = this.product.prices_without_customer;
            this.priceId = this.prices[0].id;
            this.loadBundles();
        },
        watch: {
            priceId: function (newPriceId) {
                this.price = this.prices.find(p => p.id === newPriceId)
            },
        },
        computed: {
            bundlesForPrice() {
                return this.bundles.filter(b => b.price_id === this.priceId);
            },
        },
        methods: {
            loadBundles() {
                axios.get('/company/api/products/' + this.product.id + '/bundles').then(res => {
                    this.bundles = res.data.data;
                })
            },
            getTotalPrice() {
                return parseAllFloat(this.selectedBundle.bundle_attribute_option.price_incl) + parseAllFloat(this.selectedBundle.price_product_attribute_option.price_incl);
            },
            getTotalPriceWithDiscount() {
                let price = this.getTotalPrice();

                if (this.selectedBundle.discount_price) {
                    price = price - parseAllFloat(this.selectedBundle.discount_price);
                }

                if (this.selectedBundle.discount_percentage) {
                    price = price * ((100 - this.selectedBundle.discount_percentage) / 100);
                }

                return price.toFixed(2);
            },
            edit() {
                this.editBundle = this.selectedBundle;
            },
            onSaved() {
                this.loadBundles();
                this.editBundle = null;
                this.selectedBundle = {};
            }
        }
    }
</script>
