<template>
    <modal @onClose="$emit('onClose')" @onSave="save()" :show="show" :save-text="'Goedkeuren & ophaalverzoek verzenden'">
        <order-item-teaser v-if="orderItem" :order-item="orderItem"></order-item-teaser>

        <div v-for="(orderItem, i) in order.order_items">
            <div v-if="orderItem.provided_pickup_pictures_at">
                <h6>
                    {{ orderItem.product.name }}
                    ({{ orderItem.attributes.map(a => a.product_attribute.name + ': ' + a.option.name).join(', ') }})
                </h6>

                <div>Weet je zeker dat je de ophaling wil inplannen? De klant heeft nog {{getOpenAmount()| format_money}} open staan</div>
            </div>
        </div>

    </modal>
</template>

<script>

import Modal from "../Modal";
import ProductTeaser from "../ProductTeaser";
import OrderItemTeaser from "../OrderItemTeaser";
import FormControl from "../FormControl";

export default {
    name: "SendRequestFromPhotosModal",
    props: ['show', 'uuid'],
    components: {OrderItemTeaser, ProductTeaser, Modal, FormControl},

    data: () => ({
    }),

    computed: {
        orderItem: function () {
            return this.$store.state.order.order_items[0];
        },
        order: function () {
            return this.$store.state.order;
        },
    },
    mounted() {
        $('[data-toggle="data-tooltip"]').tooltip();
    },
    methods: {
        save() {
            this.$store.dispatch('approvePickupPictures', {
                batch_id: this.uuid,
                sendRequest: true,
            }).then(() => {
                this.close();
            });
        },
        getOpenAmount() {
            return this.order.customer.unpaid_invoices.reduce((s, item) => {
                return s + item.open_amount
            }, 0)
        },
        close() {
            this.$emit('onClose');
        },
    },

}
</script>
