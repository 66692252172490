<template>


    <tr>
        <td>
            <supplier-request-warning :supplier-request="supplierRequest"/>
        </td>
        <td>{{ supplierRequest.sorting_order + 1 }}</td>
        <td>
            <location-name :location="supplierRequest.location" :supplier="supplierRequest.supplier"></location-name>
        </td>
        <td>
                                            <span v-if="supplierRequest.status_id === 'sent'"
                                                  class="tag tag--warning">Aangevraagd #{{ supplierRequest.supplier_order_nr }}</span>
            <span v-if="supplierRequest.status_id === 'accepted'"
                  class="tag tag--success">Geaccepteerd</span>
            <span v-if="supplierRequest.status_id === 'denied'"
                  class="tag tag--danger">Leveren niet mogelijk</span>
            <span v-if="supplierRequest.status_id === 'not_sent'"
                  class="tag tag--light">Nog niet verzonden</span>
            <span v-if="supplierRequest.status_id === 'unlinked'"
                  class="tag tag--warning">Ontkoppeld #{{ supplierRequest.supplier_order_nr }} </span>
            <span v-if="!supplierRequest.status_formatted" class="tag tag--light">Volgorde nog niet opgeslagen</span>
        </td>
        <rent-price-column :supplier-request="supplierRequest"/>

    </tr>



</template>

<script>
    import SupplierRequestWarning from "../SupplierRequestWarning";
    import SupplierName from "../SupplierName";
    import RentPriceColumn from "./RentPriceColumn";
    import LocationName from "../Locations/LocationName.vue";

    export default {
        name: "RentSupplierRow",
        components: {
            LocationName,
            RentPriceColumn,
            SupplierName,
            SupplierRequestWarning,
        },
        props: ['supplierRequest'],

    }
</script>
