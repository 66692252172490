export default function () {
    $(document).off('click', '.js-collection-add-btn');
    $(document).off('click', '.js-collection-add-row');
    //DELETE ROW
    var deleteDiv = '<div class="col-xs-1"><button type="button" class="btn btn-danger js-collection-delete-btn"><span class="fa fa-trash"></span></button></div>';

    $('.js-collection-widget.delete .js-collection-child').each(function () {
        if (!$(this).hasClass('has-delete')) {
            $(this).find('.form-group:last-child').append(deleteDiv);
            $(this).addClass('has-delete')
        }
    });

    $('.js-collection-delete-btn').on('click', function () {
        if (confirm("Are you sure?")) {
            $(this).closest('.js-collection-child').remove();
        }
    });

    //ADD ROW
    //CollectionType id: $relationshipName
    //'Add'-button id: $relationshipName_add
    $(document).on('click', '.js-collection-add-btn', add_collection_item);

    function add_collection_item(e) {
        e.preventDefault();

        var holder = $(this).closest('.js-collection-container');
        var list = $(holder.find($('.js-collection-widget [data-prototype]'))[0]);
        // grab the prototype template
        var newWidget = list.attr('data-prototype');
        let prototypeName = new RegExp(list.attr('data-prototype-name') ?list.attr('data-prototype-name') : '__name__' , 'g');
        console.log(prototypeName);
        // replace the "__name__" used in the id and name of the prototype
        // with a number that's unique to your categories
        // end name attribute looks like name="mainForm[subForm][2]"
        var index = list.children().length;
        newWidget = newWidget.replace(prototypeName, index);

        // create a new list element and add it to the list
        var newLi = $('<div class="js-collection-child"></div>').html(newWidget);

        $(newLi).find('.js-collection-delete-btn').on('click', function () {
            $(this).closest('.js-collection-child').remove();
        });

        newLi.appendTo(list);
        window.initializeAjaxSelects();
        $('.select2').each(function () {
            if (!$(this).data("select2-id")) {
                $(this).select2();
            }
        });
        window.initializePostcodeApi();
        $(this).trigger('after-click', index)
    }

    //ADD ROW
    //CollectionType id: $relationshipName
    //'Add'-button id: $relationshipName_add
    $(document).on('click', '.js-collection-add-row', add_collection_row);

    function add_collection_row(e) {
        e.preventDefault();

        var holder = $(this).closest('.js-collection-container');
        var list = $(holder.find($('.js-collection-widget [data-prototype]'))[0]);
        // grab the prototype template
        var newWidget = list.attr('data-prototype');
        let prototypeName = new RegExp(list.attr('data-prototype-name') ?list.attr('data-prototype-name') : '__name__' , 'g');
        console.log(prototypeName);
        // replace the "__name__" used in the id and name of the prototype
        // with a number that's unique to your categories
        // end name attribute looks like name="mainForm[subForm][2]"
        var index = list.children().length;
        newWidget = newWidget.replace(prototypeName, index);

        // create a new list element and add it to the list
        var newLi = $('<tr class="js-collection-child"></tr>').html(newWidget);

        $(newLi).find('.js-collection-delete-btn').on('click', function () {
            $(this).closest('.js-collection-child').remove();
        });

        newLi.appendTo(list);
        window.initializeAjaxSelects();
        $('.select2').each(function () {
            if (!$(this).data("select2-id")) {
                $(this).select2();
            }
        });
        window.initializePostcodeApi();
        $(this).trigger('after-click', index)
    }
};
