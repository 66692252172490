<template>
    <div>
        <div class="js-rrule" :id="'contract_form_contract_item_' + index">
            <div class="tag tag--success tag--offset" v-if="formValid">
                <i class="far fa-check"></i>&nbsp; {{ formattedString }}
            </div>
            <div class="tag tag--danger tag--offset" v-else>
                <i class="far fa-times"></i>&nbsp; {{ formattedString }}
            </div>
            <div :id="'rrule-generator-' + index">
                <div class="rrule-form">
                    <div class="form-row">
                        <div class="col-12">
                            <div class="form-item form-item--compact">
                                <label class="control-label">Startdatum contract <i class="fa fa-info-circle" title="Vanaf deze datum zal de facturatie starten." data-toggle="tooltip"></i></label>
                                <input :disabled="disabled"
                                       type="date"
                                       :name="'contract_item_frequencies_form[emptyings][' + index + '][start]'"
                                       v-model="start"
                                       class="form-control">
                            </div>
                        </div>
                        <div class="col-12">
                            <frequency-form
                                :freq="freq"
                                :interval="interval"
                                :disabled="disabled"
                                @frequencyChange="changeFrequency"
                                @intervalChange="changeInterval"
                            ></frequency-form>
                        </div>

                    </div>

                    <div class="box-card box-card--single box-card--active" v-if="!isShort">
                        <div class="box-card-content box-card-content--noPaddingBottom">

                            <input class="js-days-of-week" type="hidden" v-model="this.byDay">

                            <week-form :disabled="disabled" v-if="freq === 'weekly'"></week-form>

                            <month-form
                                v-if="freq === 'monthly'"
                                :month-type="monthType"
                                :by-month-day="byMonthDay"
                                :by-set-pos="bySetPos"
                                :month-day-select="monthDaySelect"
                                @setMonthType="setMonthType"
                                @setBySetPos="setBySetPos"
                                @setMonthDaySelect="setMonthDaySelect"
                            ></month-form>

                            <year-form
                                v-if="freq === 'yearly'"
                                :year-type="yearType"
                                :by-month-day="byMonthDay"
                                :by-month="byMonth"
                                :by-set-pos="bySetPos"
                                :month-day-select="monthDaySelect"
                                @setYearType="setYearType"
                                @setByMonthDay="setByMonthDay"
                                @setByMonth="setByMonth"
                                @setMonthDaySelect="setMonthDaySelect"
                                @setBySetPos="setBySetPos"
                            ></year-form>

                            <fieldset>
                                <div class="form-row">
                                    <div class="col-6">
                                        <div class="form-item form-item--compact">
                                            <label class="control-label">Einddatum leverancier</label>

                                            <select :disabled="disabled" name="end-type" id="end-type" v-model="endType"
                                                    class="form-control">
                                                <option value="none">Nooit</option>
                                                <!--
                                                    <option value="count">Na</option>-->
                                                <option value="until">Op</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-6" v-if="endType === 'until'">
                                        <div class="form-item form-item--compact">
                                            <label class="control-label">Datum</label>
                                            <input
                                                autocomplete="off"
                                                type="number"
                                                name="count"
                                                min="1"
                                                v-model="count"
                                                step="1"
                                                v-if="endType === 'count'"
                                                :disabled="endType !== 'count' || disabled"
                                                class="form-control"
                                            />
                                            <input
                                                v-if="endType === 'until'"
                                                type="text"
                                                :name="'contract_item_frequencies_form[emptyings][' + index + '][end]'"
                                                :disabled="endType !== 'until' || disabled"
                                                v-model="until"
                                                :class="'form-control js-datepicker-end-' + index"
                                            />
                                            <div class="input-group-append" v-if="endType === 'count'">
                                                <span class="input-group-text">keer</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                            <div v-if="showStartDate !== false" class="form-item form-item--compact">
                                <label class="control-label">Startdatum huidige frequentie <i data-toggle="tooltip" title="Pas deze datum aan wanneer je de frequentie aanpast. Deze datum wordt gebruikt om de ledigingskalender aan te passen" class="fa fa-info-circle"></i></label>
                                <input :disabled="disabled"
                                       type="date"
                                       :name="'contract_item_frequencies_form[emptyings][' + index + '][start_emptyings_at]'"
                                       v-model="startEmptyingsAt"
                                       class="form-control">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import AdvancedInput from "./AdvancedInput";
import FrequencyForm from "./FrequencyForm";
import WeekForm from "./WeekForm";
import MonthForm from "./MonthForm";
import init from './mixins/init'
import YearForm from "./YearForm";
import months from "./mixins/months";
import moment from "moment"
import Trans from "./mixins/Trans";

export default {
    name: 'rrule-generator',

    components: { YearForm, MonthForm, WeekForm, FrequencyForm, AdvancedInput },

    mixins: [init, months, Trans],
    props: ['index', 'frequencyItem', 'disabled', 'showStartDate', 'isShort'],


    data() {
        return {
            start: null,
            startEmptyingsAt: null,
            recurrent: true,
            editing: true,
            end: null,
            freq: 'weekly',
            interval: null,
            byDay: [],
            monthType: 'day',
            byMonthDay: [],
            bySetPos: null,
            monthDaySelect: "MO,TU,WE,TH,FR,SA,SU",
            yearType: 'once',
            byMonth: [],
            endType: 'none',
            count: null,
            until: null,
            frequency: this.frequencyItem ? this.frequencyItem : {},
            contractOfferId: null,
        }
    },

    computed: {
        formattedString() {
            if (!this.recurrent) return '';
            if (this.freq === 'daily' && parseInt(this.interval) > 0) {
                if (parseInt(this.interval) === 1) return 'Elke dag' + this.getEndString();
                return `Elke ${this.interval} dagen ${this.getEndString()}`;
            }

            if (this.freq === 'weekly' && this.interval > 0) return this.getWeekString();
            if (this.freq === 'monthly' && this.interval > 0) return this.getMonthString();

            if (this.freq === 'yearly' && this.interval > 0) return this.getYearString();
            if (this.freq === 'on_call') return 'Op afroep';

            return ''
        },
        rruleString() {
            if (!this.freq || !this.interval || !this.startIsValid) {
                console.log('null');
                return null;
            }

            let str = `FREQ=${this.freq.toUpperCase()};INTERVAL=${this.interval}`;

            if (this.freq === 'weekly' && this.byDay.length) str += `;BYDAY=${this.byDay.join()}`;

            if (this.freq === 'monthly' && this.monthType === 'day' && this.byMonthDay.length) {
                str += `;BYMONTHDAY=${this.byMonthDay.join()}`;
            }

            if (this.freq === 'monthly' && this.monthType === 'custom') {
                str += `;BYDAY=${this.monthDaySelect};BYSETPOS=${this.bySetPos}`;
            }

            if (this.freq === 'yearly' && this.yearType === 'once') {
                str += `;BYMONTHDAY=${this.byMonthDay.join()};BYMONTH=${this.byMonth[0]}`;
            }

            if (this.freq === 'yearly' && this.yearType === 'in') {
                str += `;BYMONTH=${this.byMonth.join()}`;
            }

            if (this.freq === 'yearly' && this.yearType === 'custom') {
                str += `;BYDAY=${this.monthDaySelect};BYSETPOS=${this.bySetPos};BYMONTH=${this.byMonth[0]}`;
            }

            if (this.freq === 'on_call') {
                return '';
            }

            return this.getRRuleEnd(str);
        },
        formValid() {
            if (this.freq === 'on_call' && this.startIsValid && ((this.endType === 'until' && this.until) || this.endType === 'none')) {
                return true;
            }

            if (this.isShort && this.freq && this.startIsValid) {
                return true;
            }

            if (!this.freq || this.interval < 1 || !this.startIsValid || !this.byDay.length || (this.endType === 'until' && !this.until)) {
                return false;
            }

            return true;
        },
        startIsValid() {
            if (this.start) {
                return true;
            }

            return !!this.contractOfferId;
        }
    },

    watch: {
        rruleString(val) {
            if (this.formValid && this.startIsValid) {
                if (!val) {
                    if (this.freq === 'on_call') {
                        $('.js-rrule-input' + this.index).val('on_call').trigger('on-rrule', {
                            rrule: 'on_call',
                            start: this.start,
                        }).trigger('change');
                        return;
                    }
                }

                $('.js-rrule-input' + this.index).val(val).trigger('on-rrule', {
                    rrule: val,
                    start: this.start,
                }).trigger('change');

                return;
            }
        },
        recurrent(val) {
            if (!val) {
                this.editing = false;
                this.frequency.rrule = '';
                return this.clearForm()
            }
        },

        start(val) {
            this.frequency.start = moment(val).format('YYYYMM/DD');
        },

        endType(val) {
            if (val === 'until') {
                setTimeout(() => {
                    $('.js-datepicker-end-' + this.index)
                        .datepicker({
                            format: 'dd-mm-yyyy'
                        })
                        .on('changeDate', (e) => {
                            this.until = $(e.currentTarget).val()
                            this.frequency.end = moment($(e.currentTarget).val())
                        })
                }, 150)
            } else {
                $('.rrule-form .js-datepicker-end-' + this.index).datepicker('destroy');

                this.until = null;
                this.frequency.end = null;
            }
        },

        until(val) {
            if (!val) return this.frequency.end = null;

            this.frequency.end = moment(val, 'DD-MM-YYYY', document.documentElement.lang);
        }
    },

    methods: {
        changeRecurrent(val) {
            this.recurrent = val
        },
        changeFrequency(val) {
            this.freq = val.toLowerCase()
        },

        changeInterval(val) {
            this.interval = parseInt(val)
        },

        setByDay(val) {
            if (!Array.isArray(val)) {
                val = val.split(',')
            }

            this.byDay = val
        },

        setBySetPos(val) {
            this.bySetPos = parseInt(val)
        },

        setMonthDaySelect(val) {
            this.monthDaySelect = val
        },

        setMonthType(val) {
            this.monthType = val.toLowerCase()
        },

        setByMonthDay(val) {
            if (!Array.isArray(val)) {
                val = val.toString().split(',').map(function (item) {
                    return parseInt(item)
                })
            }

            this.byMonthDay = val
        },

        setByMonth(val) {
            if (!Array.isArray(val)) {
                val = val.toString().split(',').map(function (item) {
                    return parseInt(item)
                })
            }

            this.byMonth = val
        },

        setYearType(val) {
            this.yearType = val
        },

        clearForm(except) {
            this.byDay = [];
            this.monthType = 'day';
            this.byMonthDay = [];
            this.bySetPos = null;
            this.monthDaySelect = "MO,TU,WE,TH,FR,SA,SU";
        },

        buttonClasses(day) {
            return {
                'btn': true,
                'btn-lg': true,
                'btn-outline-secondary': true,
                active: this.byDay.indexOf(day) > -1
            };
        },

        getRRuleEnd(str) {
            let until = moment(this.until, 'DD-MM-YYYY');
            // if (this.endType === 'count' && this.count) str += `;COUNT=${this.count}`;
            if (this.endType === 'until' && until) {
                str += `;UNTIL=${until.format('YYYYMMDD')}`;
            }

            return str;
        },

        getDays(abbr = null, key = null) {
            const days = [{
                abbr: 'MO',
                short: 'Ma',
                name: 'maandag'
            }, {
                abbr: 'TU',
                short: 'Di',
                name: 'dinsdag'
            }, {
                abbr: 'WE',
                short: 'Wo',
                name: 'woensdag'
            }, {
                abbr: 'TH',
                short: 'Do',
                name: 'donderdag'
            }, {
                abbr: 'FR',
                short: 'Vr',
                name: 'vrijdag'
            }, {
                abbr: 'SA',
                short: 'Za',
                name: 'zaterdag'
            }, {
                abbr: 'SU',
                short: 'Zo',
                name: 'zondag'
            }];

            if (!abbr) return days
            let day = days.find(item => abbr === item.abbr)
            if (day && key) return day[key]
            return day
        },


        getWeekString() {
            let str = 'Elke '
            str += parseInt(this.interval) === 1 ? 'week' : this.interval + ' weken'

            if (this.byDay.length) {
                str += ` op ${this.getByDayString()}`
            }

            return str + this.getEndString()
        },

        getMonthString() {
            let str = 'Elke '

            str += parseInt(this.interval) === 1 ? 'maand' : this.interval + ' maanden'

            if (this.monthType === 'day') {
                let length = this.byMonthDay.length
                if (length) str += ' op dag '
                if (length === 1) str += this.byMonthDay
                if (length > 1) str += this.byMonthDay.sort((a, b) => a - b).join()
            }

            if (this.monthType === 'custom') str += this.getBySetPosString()

            return str + this.getEndString()
        },

        getYearString() {
            let str = ''

            str += parseInt(this.interval) === 1 ? 'Elk jaar' : `Elke ${this.interval} jaar`

            if (this.yearType === 'once') str += ` op ${this.byMonthDay[0]} ${this.months(this.byMonth[0])}`
            if (this.yearType === 'in') str += ` in ${this.getByMonthString()}`
            if (this.yearType === 'custom') str += `${this.getBySetPosString()} in ${this.months(this.byMonth[0])}`

            return str + this.getEndString()
        },

        getByDayString() {
            const vue = this
            let res = []

            this.getDays().forEach(function (day) {
                if (vue.byDay.indexOf(day.abbr) > -1) {
                    res.push(day.short.toLowerCase())
                }
            });

            return res.join(', ').replace(/,(?=[^,]*$)/, ' en');
        },

        getByMonthString() {
            let res = [];
            const months = this.months();

            for (let key in months) {
                if (this.byMonth.indexOf(parseInt(key)) > -1) res.push(months[key]);
            }

            return res.join(', ').replace(/,(?=[^,]*$)/, ' en');
        },

        getBySetPosString() {
            let str = ' op de ';

            switch (this.bySetPos) {
                case 1:
                    str += 'eerste ';
                    break;
                case 2:
                    str += 'tweede ';
                    break;
                case 3:
                    str += 'derde ';
                    break;
                case 4:
                    str += 'vierde ';
                    break;
                case -1:
                    str += 'laatste ';
                    break;
                default:
                    str += '';
            }

            if (this.monthDaySelect && this.monthDaySelect.length === 2) str += this.getDays(this.monthDaySelect).name;
            if (this.monthDaySelect && this.monthDaySelect.length === 5) str += 'dag in het weekend';
            if (this.monthDaySelect && this.monthDaySelect.length === 14) str += 'werkdag';
            if (this.monthDaySelect && this.monthDaySelect.length === 20) str += 'dag';

            return str;
        },

        getEndString() {
            // if (this.endType === 'count' && this.count) return ` stopt na ${this.count} keer`;
            if (this.endType === 'until' && this.until) return ` tot ${this.until}`;
            return '';
        },
    },

    mounted() {
        this.init();
    },
}
</script>

