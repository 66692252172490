<template>
    <button
        :class="[
          childClass,
          loading ? 'loading' : '',
        ]"
        :disabled="loading || disabled"
        type="button"
    >
        <slot></slot>

        <i class="fas fa-spinner-third fa-spin ml-2" v-if="loading"></i>
    </button>
</template>

<script>
export default {
    name: "LoadingButton",
    components: {},
    props: {
        childClass: {
            type: String,
            required: false,
            default: 'btn btn-primary'
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    data(){
        return {
            internalLoading: false,
        }
    },
    watch: {
        loading: function(newVal){
            this.internalLoading = newVal;
        }
    },
}
</script>
