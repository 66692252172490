<template>
    <modal @onClose="$emit('onClose')" :show="show" :hide-save="true">
        <template #title>
            Gekoppeld telefoongesprek
        </template>
        <order-item-teaser v-if="orderItem" :order-item="orderItem"></order-item-teaser>

        <table class="table table--noHeader" v-if="order.latest_call">
            <tr>
                <td>Telefoonnummer</td>
                <td>{{ order.latest_call.phone_number }}</td>
            </tr>
            <tr>
                <td>Aangemaakt op</td>
                <td>{{ order.latest_call.created_at }}</td>
            </tr>
            <tr>
                <td>Duur</td>
                <td>{{ order.latest_call.duration }}</td>
            </tr>
            <tr>
                <td>Agent</td>
                <td>{{ order.latest_call.agent }}</td>
            </tr>
            <tr>
                <td>Verzonden naar AdCalls</td>
                <td>
                    <i class="far fa-check text-success" v-if="order.latest_call.analytics_sent_at"></i>
                    <i class="far fa-times" v-else></i>
                </td>
            </tr>
            <tr>
                <td>Opmerkingen</td>
                <td>
                    <span v-text="order.latest_call.notes"></span>
                </td>
            </tr>
        </table>
    </modal>
</template>

<script>

import OrderItemTeaser from "./OrderItemTeaser";
import ProductTeaser from "./ProductTeaser";
import Modal from "./Modal";

export default {
    name: "CallDataModal",
    props: ['show'],
    components: { OrderItemTeaser, ProductTeaser, Modal },
    computed: {
        order: function () {
            return this.$store.state.order;
        },
        orderItem: function () {
            return this.$store.state.order.order_items[0];
        },
    },
    mounted() {
        $('[data-toggle="data-tooltip"]').tooltip();
    },
    methods: {
        close() {
            this.unlink = false;
            this.$emit('onClose');
        },
    },

}
</script>
