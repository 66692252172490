<template>
    <div>
        <select class="form-select" @change="saveRating()" v-model="rating">
            <option v-for="value in maxRating">{{ value }}</option>
        </select>
    </div>
</template>

<script>
export default {
    name: "SupplierRating",
    props: ['supplier_id', 'current_rating', 'type_id'],
    data() {
        return {
            rating: this.current_rating,
            maxRating: 5
        }
    },
    methods: {
        saveRating() {
            return axios.post(`/suppliers/${this.supplier_id}/rating`, {
                supplier_id: this.supplier_id,
                type_id: this.type_id,
                rating: this.rating,
            });
        }
    },
}
</script>
