export default {
    data(){
        return {
            trans: [],
        }
    },

    methods: {
        t(key) {
            if (!window.translations){
                return key;
            }
            return window.translations[key] ? window.translations[key] : key;
        },
    }
}
