<template>
    <div>

        <div>

            <SupplierSelection v-if="mainOrderItem" :requests="suppliers" @close="showModal = false" :show-modal="showModal === true"></SupplierSelection>

            <div class="box-header">
                <h2 class="box-title">Leverancier</h2>
            </div>
            <div class="box-content">
                <div class="alert alert--orange" v-if="supplierMessage">Let op, {{ supplierMessage }} heeft hier al een container staan</div>
                <div v-if="loadSupplierMessage !== true">
                    <div class="tag tag--warning">{{ loadSupplierMessage }}</div>
                </div>
                <div v-if="!order.location && loadSupplierMessage === true && $store.getters.isEditable">

                    <div v-if="!loading">
                        <div class="table-wrapper">
                            <table class="table table--noHeader">
                                <tbody>
                                <template v-for="supplierRequest in suppliers">
                                    <template v-if="!mainOrderItem.product.purchase_rent_only">
                                        <all-in-supplier-row :supplier-request="supplierRequest"></all-in-supplier-row>
                                    </template>
                                    <template v-else>
                                        <rent-supplier-row :supplier-request="supplierRequest"></rent-supplier-row>
                                    </template>
                                </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div v-if="loading" class="d-flex justify-content-center align-items-center">
                        <i class="fa fa-spinner fa-spin"></i>
                    </div>
                </div>
                <div v-if="order.location && !order.accepted_supplier_request" class="supplier-header-tools pointer">
                    <a data-toggle="tooltip" @click.stop="showTodosSupplier" title="Mutaties leveranciers bekijken" href="#" class="btn btn-light">
                        <i class="fal fa-clipboard-check"></i>
                        <div class="badge" v-if="order.todos.length">{{ order.todos.length }}</div>
                    </a>
                </div>


                <div v-if="order.supplier && order.accepted_supplier_request" class="pointer"
                     @click="showSupplierModal = true">

                    <div class="supplier-header">
                        <div class="supplier-header-name">
                            <div v-if="order.supplier.image_url" class="supplier-header-image">
                                <img :src="order.supplier.image_url">
                            </div>
                            <div class="d-flex flex-column">

                            <span>
	                            <b><span
                                    @click.stop="editSupplierNr">{{ order.location.name }} #{{
                                        order.supplier_order_nr
                                    }}</span></b>

	                        </span>
                                <div class="d-flex flex-row align-items-center w-100" v-if="editingNumber" @click.stop>
                                    <div class="form-item">
                                        <label class="control-label">Nieuw nummer</label>
                                        <input type="text" class="form-control" v-model="supplierNumber">
                                    </div>
                                    <button class="btn btn-sm btn-primary" @click.stop="saveNumber" type="button"><i
                                        class="fa fa-save"></i></button>
                                    <button class="btn btn-sm btn-secondary" @click.stop="editingNumber = false"
                                            type="button"><i class="fa fa-times text-danger"></i></button>
                                </div>
                            </div>

                        </div>

                        <div class="supplier-header-tools">
                            <a data-toggle="tooltip" @click.stop="showUnlinkSupplier" title="Leverancier ontkoppelen"
                               href="#" class="btn btn-light"><i class="fal fa-unlink"></i></a>
                            <a data-toggle="tooltip" @click.stop="showTodosSupplier" title="Mutaties leveranciers bekijken" href="#" class="btn btn-light">
                                <i class="fal fa-clipboard-check"></i>
                                <div class="badge" v-if="order.todos.length">{{ order.todos.length }}</div>
                            </a>
                            <outbound-call-button v-if="supplier && supplier.order_intake_phone" :number="supplier.order_intake_phone">
                                <span class="btn btn-light" :title="supplier.order_intake_phone" data-toggle="data-tooltip">
                                    <i class="fal fa-phone"></i>
                                </span>
                            </outbound-call-button>
                            <a data-toggle="tooltip" title="Tijdsindicatie aanvragen"
                               @click.stop="requestTimeIndication" href="#" class="btn btn-light"><i
                                class="fal fa-clock"></i></a>
                        </div>
                    </div>
                    <div v-if="order.preferred_pickup_at && !order.pending_pickup_requests.length && !order.pickup_at && !order.swap_order"
                         class="alert alert--warning">
                        <strong>Er is een voorkeur ophaaldatum bekend maar nog geen aanvraag verstuurd naar de leverancier!</strong>
                    </div>

                    <div class="supplier-info" v-if="order.accepted_supplier_request">
                        <div class="supplier-info-item">
                            <div class="supplier-info-item-icon">
                                <i class="fal fa-fw fa-euro-sign"></i>
                            </div>
                            <div class="supplier-info-item-col" v-if="!mainOrderItem.product.purchase_rent_only">
                                Inkoop product:
                            </div>
                            <div class="supplier-info-item-col" v-if="mainOrderItem.product.purchase_rent_only">
                                Inkoop transportkosten
                            </div>
                            <div class="supplier-info-item-col">
                                <span class="text-success" v-if="marginPrice > 0">
                                    {{ order.accepted_supplier_request.calculated_price_excl | format_money }} / +{{ marginPrice | format_money }}
                                </span>
                                <span class="text-danger" v-if="marginPrice <= 0">
                                    {{ order.accepted_supplier_request.calculated_price_excl | format_money }} / -{{ marginPrice | format_money }}
                                </span>
                            </div>
                        </div>
                        <div class="supplier-info-item" v-if="mainOrderItem.product.purchase_rent_only">
                            <div class="supplier-info-item-icon">
                                <i class="fal fa-fw fa-euro-sign"></i>
                            </div>
                            <div class="supplier-info-item-col">
                                Staffel huur huidige periode
                            </div>
                            <div class="supplier-info-item-col">
                                <div v-for="price in order.accepted_supplier_request.rent_prices">
                                    Vanaf dag {{ price.from_day }} elke {{ price.rent_frequency }} dagen {{ price.rent_price_excl | format_money }}
                                </div>
                            </div>
                        </div>
                        <div class="supplier-info-item">
                            <div class="supplier-info-item-icon">
                                <span><i class="fal fa-fw fa-clock"></i></span>
                            </div>
                            <div class="supplier-info-item-col">
                                Opdracht verstuurd op:
                            </div>
                            <div class="supplier-info-item-col"><span>{{
                                    order.accepted_supplier_request.sent_at_date
                                }}</span> om <span>{{ order.accepted_supplier_request.sent_at_time }}</span></div>
                        </div>
                        <div class="supplier-info-item">
                            <div class="supplier-info-item-icon">
                                <span><i class="fal fa-fw fa-file"></i></span>
                            </div>
                            <div class="supplier-info-item-col">
                                Opdracht geaccepteerd op:
                            </div>
                            <div class="supplier-info-item-col">
                                <span>{{ order.accepted_supplier_request.accepted_at_date }}</span> om
                                <span>{{ order.accepted_supplier_request.accepted_at_time }}</span></div>
                        </div>
                        <div class="supplier-info-item" v-if="mainOrderItem.purchase_invoiced_at">
                            <div class="supplier-info-item-icon">
                                <span><i class="fal fa-fw fa-money-bill"></i></span>
                            </div>
                            <div class="supplier-info-item-col">
                                Inkoop gefactureerd op:
                            </div>
                            <div class="supplier-info-item-col">
                                <span>{{ mainOrderItem.purchase_invoiced_at_date }}</span> om
                                <span>{{ mainOrderItem.purchase_invoiced_at_time }}</span>
                            </div>
                        </div>
                        <div class="supplier-info-item" v-if="mainOrderItem.expected_purchase_at && !mainOrderItem.purchase_invoiced_at">
                            <div class="supplier-info-item-icon">
                                <span><i class="fal fa-fw fa-money-bill"></i></span>
                            </div>
                            <div class="supplier-info-item-col">
                                Verwachte facturatiedatum inkoop:
                            </div>
                            <div class="supplier-info-item-col">
                                <span>{{ mainOrderItem.expected_purchase_at }}</span>
                            </div>
                        </div>
                        <div class="supplier-info-item" v-if="order.purchase_memorial_sent_at_date">
                            <div class="supplier-info-item-icon">
                                <span><i class="far fa-check"></i></span>
                            </div>
                            <div class="supplier-info-item-col">
                                Memoriaal naar exact op:
                            </div>
                            <div class="supplier-info-item-col">
                                <span>{{ order.purchase_memorial_sent_at_date }}</span> om
                                <span>{{ order.purchase_memorial_sent_at_time }}</span>
                            </div>
                        </div>
                        <div class="supplier-info-item" v-if="order.cash_memorial_to_exact_at_date">
                            <div class="supplier-info-item-icon">
                                <span><i class="far fa-check"></i></span>
                            </div>
                            <div class="supplier-info-item-col">
                                Contant betaling memorial op:
                            </div>
                            <div class="supplier-info-item-col">
                                <span>{{ order.cash_memorial_to_exact_at_date }}</span> om
                                <span>{{ order.cash_memorial_to_exact_at_time }}</span>
                            </div>
                        </div>
                        <div class="supplier-info-item" v-if="order.revert_cash_memorial_to_exact_at_date">
                            <div class="supplier-info-item-icon">
                                <span><i class="far fa-check"></i></span>
                            </div>
                            <div class="supplier-info-item-col">
                                Tegenboeking Contant betaling memorial op:
                            </div>
                            <div class="supplier-info-item-col">
                                <span>{{ order.revert_cash_memorial_to_exact_at_date }}</span> om
                                <span>{{ order.revert_cash_memorial_to_exact_at_time }}</span>
                            </div>
                        </div>
                        <div v-for="pickupRequest in order.pickup_requests" class="supplier-info-item">
                            <div class="supplier-info-item-icon">
                        		<span v-if="!pickupRequest.responded_at">
                        			<i class="fal fa-fw fa-clock"></i>
                        		</span>
                                <span v-if="pickupRequest.responded_at && pickupRequest.accepted">
                        			<i class="fal fa-fw fa-check"></i>
                        		</span>
                                <span v-if="pickupRequest.responded_at && !pickupRequest.accepted">
                        			<i class="fal fa-fw fa-clock"></i>
                        		</span>
                            </div>

                            <div class="supplier-info-item-col">
                                <div class="text-warning"
                                     v-if="!pickupRequest.responded_at_date">
                                    Aanvraag {{ getType(pickupRequest) }} verstuurd op:
                                </div>

                                <div
                                    v-if="pickupRequest.responded_at_date && pickupRequest.accepted">
                                    Aanvraag {{ getType(pickupRequest) }} geaccepteerd op:
                                </div>

                                <div
                                    v-if="pickupRequest.responded_at_date && !pickupRequest.accepted">
                                    Aanvraag {{ getType(pickupRequest) }} afgewezen op:
                                </div>
                            </div>

                            <div class="supplier-info-item-col">
                                <div v-if="pickupRequest.responded_at_date">
                                    <span>{{ pickupRequest.responded_at_date }}</span> om
                                    <span>{{ pickupRequest.responded_at_time }}</span>
                                </div>
                                <div class="text-warning" v-else>
                                    <span>{{ pickupRequest.sent_at_date }}</span> om <span>{{
                                        pickupRequest.sent_at_time
                                    }}</span>
                                </div>
                            </div>

                        </div>
                        <div class="supplier-info-item" v-if="order.time_indication_requested_at_date">
                            <div class="supplier-info-item-icon">
                                <span><i class="fal fa-fw fa-clock"></i></span>
                            </div>
                            <div class="supplier-info-item-col">
                                Tijdsindicatie aangevraagd op:
                            </div>
                            <div class="supplier-info-item-col">
                                <span>{{ order.time_indication_requested_at_date }}</span> om
                                <span>{{ order.time_indication_requested_at_time }}</span>
                            </div>
                        </div>
                        <div class="supplier-info-item" v-if="order.time_indication">
                            <div class="supplier-info-item-icon">
                                <span><i class="fal fa-fw fa-clock"></i></span>
                            </div>
                            <div class="supplier-info-item-col">
                                Tijdsindicatie plaatsing:
                            </div>
                            <div class="supplier-info-item-col">
                                tussen <span>{{ order.time_indication }} uur</span>
                            </div>
                        </div>
                        <div class="supplier-info-item" v-if="order.pickup_time_indication">
                            <div class="supplier-info-item-icon">
                                <span><i class="fal fa-fw fa-clock"></i></span>
                            </div>
                            <div class="supplier-info-item-col">
                                Tijdsindicatie ophaling:
                            </div>
                            <div class="supplier-info-item-col">
                                tussen <span>{{ order.pickup_time_indication }} uur</span>
                            </div>
                        </div>
                    </div>

                </div>
                <div v-if="!order.location && !$store.getters.isEditable">
                    Geen leverancier gekoppeld
                </div>
                <div v-if="!order.location && $store.getters.isEditable">
                    <button @click="showModal = true" type="button" class="btn w-100 btn-primary">Leveranciers bekijken
                        en koppelen <i class="fa fa-link"></i></button>
                </div>
                <supplier-modal ref="supplierModal" @onClose="showSupplierModal = false" :show="showSupplierModal"></supplier-modal>
                <outsource-modal></outsource-modal>
                <todos-modal ref="todosModal" @onClose="showTodosModal = false" :show="showTodosModal"></todos-modal>

            </div>
        </div>

    </div>

</template>

<script>
import EventBus from "../../../../eventbus";
import FormControl from "../FormControl";
import SupplierSelection from "./SupplierSelection";
import SupplierModal from "./SupplierModal";
import SupplierRequestWarning from "./SupplierRequestWarning";
import SupplierName from "./SupplierName";
import TodosModal from "./TodosModal";
import AllInSupplierRow from "./All-InPriced/All-InSupplierRow";
import RentSupplierRow from "./PurchaseRentOnly/RentSupplierRow";
import OutsourceModal from "./OutsourceModal";
import OutboundCallButton from "../../../OutboundCallButton";

export default {
    name: "SupplierDetails",
    components: {
        OutsourceModal,
        RentSupplierRow,
        AllInSupplierRow,
        SupplierName,
        SupplierRequestWarning,
        TodosModal,
        SupplierModal,
        SupplierSelection,
        FormControl,
        OutboundCallButton
    },

    data: () => ({
        showModal: false,
        showSupplierModal: false,
        showTodosModal: false,
        suppliers: [],
        loading: true,
        editingNumber: false,
        supplierNumber: '',
    }),
    mounted() {
        $('[data-toggle="data-tooltip"]').tooltip();
        EventBus.$on('globalCloseModals', () => this.showSupplierModal = false)
        if (this.order && this.order.delivery_address){
            this.$store.dispatch('loadLinkedOrders', this.order.delivery_address);
        }
    },
    computed: {
        order: function () {
            return this.$store.state.order;
        },
        mainOrderItem() {
            return this.$store.state.order.order_items[0];
        },
        marginPrice: function () {
            return this.mainOrderItem.total_price_excl - this.order.accepted_supplier_request.calculated_price_excl
        },
        loadSupplierMessage: function () {
            let order = this.order;
            if (!order.webshop.id) {
                return 'Kies een webshop.'
            }
            if (!order.order_items.length) {
                return 'Kies een product'
            }
            if (!order.preferred_delivery_at) {
                return 'Kies een afleverdatum';
            }
            this.loadData();

            return true;
        },
        supplier: function () {
            return this.$store.state.order.supplier;
        },
        supplierMessage() {
            console.log(this.$store.state.linkedOrders);
            let names = [...new Set(this.$store.state.linkedOrders.filter(o => o.supplier != null).map(o => o.supplier.name))].join(', ');
            if (!names) {
                return false;
            }
            return names;
        },
    },
    methods: {
        showUnlinkSupplier() {
            this.showSupplierModal = true;
            this.$refs.supplierModal.unlink = true;
        },
        showTodosSupplier() {
            console.log('show todos supplier');
            this.showTodosModal = true;
        },
        editSupplierNr() {
            if (this.$store.state.isProduction) {
                return;
            }
            this.editingNumber = true;
        },
        saveNumber() {
            this.$store.dispatch('updateSupplierNumber', this.supplierNumber).then(() => {
                this.editingNumber = false;
                this.supplierNumber = '';
            })
        },
        requestTimeIndication(e) {
            if (confirm('Let op, er wordt een tijdsindicatie verstuurd naar de leverancier via de mail.')) {
                this.$store.dispatch('requestTimeIndication')
            }
        },
        unlinkSupplier() {
            this.$store.dispatch('unlinkSupplier')
        },
        loadData() {
            let webshopId = this.order.webshop.id;
            let deliveryAt = this.order.delivery_at ? this.order.delivery_at : this.order.preferred_delivery_at;

            if (!webshopId) {
                return;
            }

            if (!deliveryAt) {
                return;
            }

            let products = this.order.order_items;
            if (!products.length) {
                return;
            }

            this.loadOrderRequests(webshopId, products, deliveryAt);
        },
        loadOrderRequests(webshopId, products, deliveryAt) {
            this.loading = true;
            let url = '/company/api/sales/orders/supplier/locations';

            if (this.mainOrderItem.product.purchase_rent_only) {
                url = '/company/api/sales/orders/supplier/locations/rent';
            }
            let dataRequests = [];
            axios.post(url, {
                order_id: this.order.id,
                webshop_id: webshopId,
                products,
                date: deliveryAt,
                delivery_address_id: this.order.delivery_address.id,
            }).then(({ data }) => {
                if (data.data.length > 0) {
                    dataRequests = data.data.filter(i => {
                        return this.order.supplier_requests.findIndex(s => s.supplier_id === i.supplier_id) === -1;
                    });

                    if (this.order.supplier_requests.length > 0) {
                        dataRequests = dataRequests.map(s => {
                            s.should_send = false;
                            return s;
                        })
                    }

                }
            }).catch(err => {
                window.triggerAlert("Kon leveranciersaanvragen niet ophalen.", 'danger')
            }).finally(() => {
                let suppliers = this.order.supplier_requests;
                if (dataRequests.length > 0) {
                    suppliers = [...this.order.supplier_requests, ...dataRequests]
                }

                this.suppliers = suppliers.sort((a, b) => a.sorting_order - b.sorting_order);
                this.loading = false;
            });
        },
        getType(pickupRequest) {
            return pickupRequest.is_swap_order ? 'wissel' : 'ophaling';
        },
        syncMemos() {
            axios.post('/company/api/sync-memos').then(() => {
                window.triggerAlert("Sync gestart. Dit kan een paar seconden duren.")
            });
        },
        getPaymentMethod() {
            return this.$store.state.paymentMethods.find(p => p.id === this.$store.state.order.payment_method_id)
        },
    },

}
</script>
