<template>
    <div class="form-item">
	    <label class="control-label">
        {{label}} <slot name="label-icon"></slot>
            <i ref="tooltip" class="fa fa-info-circle" v-if="tooltip" data-toggle="tooltip" :title="tooltip"></i>
        </label>
      <div class="form-view" v-if="!edit">
        <span>
        	{{value}}<slot name="icon"></slot>
        </span>
        <span class="form-view-icon">
          <slot name="action"></slot>
        </span>
      </div>
      <div class="form-edit" v-if="edit">
          <slot name="input"></slot>
          <span class="text-danger"
                v-if="$store.state.errors[name] && $store.state.errors[name].length"
                v-text="$store.state.errors[name][0]"></span>
      </div>
    </div>
</template>

<script>
    export default {
        name: "FormControl",
        props: ['value', 'edit', 'label', 'name', 'tooltip'],
        mounted() {
            if (this.tooltip){
                $(this.$refs.tooltip).tooltip();
            }
            if(this.$slots.input && this.$slots.input.length){
                if (this.$slots.input[0].data.staticClass.includes('js-datepicker')){
                    $('.js-datepicker').datepicker({
                        format: 'yyyy-mm-dd',
                        language: 'nl',
                        todayHighlight: true
                    }).on('changeDate', (e) => {
                        e.currentTarget.dispatchEvent(new Event('input'));
                    })
                }
            }
        },
        watch: {
            value(val){
                if(this.$slots.input && this.$slots.input.length){
                    if (this.$slots.input[0].data.staticClass.includes('js-datepicker')){
                        $('.js-datepicker').datepicker({
                            format: 'yyyy-mm-dd',
                            language: 'nl',
                            todayHighlight: true
                        }).on('changeDate', (e) => {
                            e.currentTarget.dispatchEvent(new Event('input'));
                        })
                    }
                }
            }
        }
    }
</script>
