<template>
    <td>
        <span class="noWrap">{{supplierRequest.base_rent_price_excl | format_money }} transport</span><br>
        <span class="noWrap">Elke {{ supplierRequest.rent_prices[0].rent_frequency }} dagen {{ supplierRequest.rent_prices[0].rent_price_excl |format_money }}</span>
    </td>
</template>

<script>
    export default {
        name: "RentPriceColumn",
        props: ['supplierRequest'],
        computed: {
            tooltip() {
                return this.supplierRequest.rent_prices.map(p => `Vanaf dag ${p.from_day} elke ${p.rent_frequency} dagen ${p.rent_price_excl} euro Excl. BTW`).join(". ");
            }
        },
        mounted() {
        }

    }
</script>
