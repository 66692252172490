<template>
    <div v-if="order">
        <button @click="updatePrio" type="button" :class="'btn btn-' + order.prio_class"><i class="fa fa-exclamation-circle"></i> {{order.prio}}</button>
    </div>
</template>

<script>
    export default {
        name: "PrioForm",
        computed:{
            order(){
                return this.$store.state.order;
            }
        },
        methods: {
            updatePrio() {
                this.$store.dispatch('updatePrio');
            },
        }

    }
</script>
