<template>
    <modal @onClose="$emit('onClose')" :show="show" :hide-save="true">
        <order-item-teaser v-if="orderItem" :order-item="orderItem"></order-item-teaser>

        <h3>Leverancier</h3>
        <table class="table table--noHeader" v-if="supplier">
            <tbody>
            <tr>
                <td>
                    <div class="tag tag--withImage mr-1" v-if="supplier.image_url">
                        <div class="tag-image" :style="'background-image: url(' + supplier.image_url + ')'"></div>
                    </div>
                    {{supplier.name}}
                </td>
                <td v-if="order.accepted_supplier_request">
                    Aangenomen op: <span>{{order.accepted_supplier_request.accepted_at_date }} om {{order.accepted_supplier_request.accepted_at_time }}</span>
                </td>
                <td>
                    <a href="#" class="btn btn-light">
                        <i class="fal fa-phone"></i>
                    </a>
                </td>
            </tr>
            </tbody>
        </table>

        <h3>Mutaties</h3>

        <table class="table" v-if="supplier">
            <thead>
            <tr>
                <th>Leverancier</th>
                <th>Aanmaakdatum</th>
                <th>Gezien</th>
                <th>Type</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="!order.todos || !order.todos.length">
                <td class="text-center">
                    Geen mutaties
                </td>
            </tr>
            <tr v-else v-for="todo in order.todos">
                <td>
                    <supplier-name :supplier="todo.supplier"></supplier-name>
                </td>
                <td>
                    <span data-toggle="tooltip" title="Aangemaakt op">{{ todo.created_at }}</span>
                </td>
                <td>
                    <span v-if="todo.seen_at_by_supplier_timestamp" data-toggle="tooltip"><i class="fal fa-check-circle text-success mr-2"></i>{{ todo.seen_at_by_supplier_timestamp }}</span>
                    <span v-else class="fal fa-times-circle text-danger" data-toggle="tooltip" title="Niet gezien door leverancier"></span>
                </td>
                <td class="w-25">
                    {{ todo.type }}
                    <p class="text-muted text-sm mb-0" v-text="todo.description"></p>
                </td>
            </tr>
            </tbody>
        </table>
    </modal>

</template>

<script>

    import Modal from "../Modal";
    import ProductTeaser from "../ProductTeaser";
    import OrderItemTeaser from "../OrderItemTeaser";
    import SupplierName from "./SupplierName";

    export default {
        name: "TodosModal",
        props: ['show'],
        components: { SupplierName, OrderItemTeaser, ProductTeaser, Modal },
        computed: {
            supplier: function () {
                return this.$store.state.order.supplier;
            },
            orderItem: function () {
                return this.$store.state.order.order_items[0];
            },
            order: function () {
                console.log(this.$store.state.order.todos);
                return this.$store.state.order;
            },
        },
        mounted() {
            $('[data-toggle="data-tooltip"]').tooltip();
        },
        methods: {
            close() {
                this.unlink = false;
                this.$emit('onClose');
            },
        },

    }
</script>
