<template>
    <div class="exceptions-tabs">
        <ul class="nav nav-tabs box-tabs box-tabs--inContent" id="exceptionsTab" role="tablist">
            <li
                class="nav-item"
                v-for="tab in tabs"
                :key="tab"
            >
                <a
                    :class="['nav-link', { active: currentTab === tab }]"
                    :id="tab + '-tab'"
                    data-toggle="tab"
                    :href="'#' + tab"
                    role="tab"
                    :aria-controls="tab"
                    :aria-selected="currentTab === tab"
                    @click="currentTab = tab"
                >
                    {{ trans(tab) }}
                </a>
            </li>
        </ul>

		<component :is="currentTabComponent"></component>
    </div>
</template>

<script>
import TabWeek from "./Exceptions/TabWeek";
import TabMonth from "./Exceptions/TabMonth";
import TabFuture from "./Exceptions/TabFuture";
import TabHistory from "./Exceptions/TabHistory";

export default {
    name: 'exceptions-overview',

    components: {TabWeek, TabMonth, TabFuture, TabHistory},

    props: {
        type: {
            type: String,
            default: 'future'
        }
    },

    data() {
        return {
            currentTab: 'future',
            tabs: ['future', 'history', 'week', 'month']
        }
    },

    computed: {
        currentTabComponent: function () {
            return 'tab-' + this.currentTab.toLowerCase()
        },

        absence() {
            switch (this.type) {
                case 'week':
                    return this.$store.getters['openingHours/getThisWeeksExceptions'];
                case 'month':
                    return this.$store.getters['openingHours/getThisMonthsAbsence'];
                default:
                    return this.$store.getters['openingHours/getThisWeeksExceptions']
            }
        }
    },

    methods: {
        trans: function (x) {
            const lang = {
                'week': "Week",
                'month': "Maand",
                'future': "Toekomst",
                'history': "Geschiedenis"
            };

            return lang[x];
        }
    },

    mounted() {
        window.AdminModal.On('submit', (res) => {
            $('.exceptions-tabs .dataTable').DataTable().ajax.reload(null, false);
        });

        $(document)
            .on('ajax:success', '.js-table-delete', function (data, status, xhr) {
                toastr.success('Verwijderd');
                $('.exceptions-tabs .dataTable').DataTable().ajax.reload(null, false);
            })
            .on('ajax:error', '.js-table-delete', function () {
                toastr.error('Fout');
            })
    }
}
</script>
