export default {
    startOutsourcing({ commit, state, dispatch }) {
        state.outsourcing = true;
    },
    stopOutsourcing({ commit, state, dispatch }) {
        state.outsourcing = false;
    },
    saveOutsource({ commit, state, dispatch }, payload) {
        return axios.post('/company/api/sales/orders/' + state.order.id + '/outsource', payload).then(res => {
            commit('setOrder', res.data.order);
            return res.data.data
        }).catch(err => dispatch('onError', err))
    }


}
