<template>
    <div v-click-outside="clickAway">
        <div class="form-item-select-input">
            <div style="cursor: pointer" v-if="selectedItem && !showItems" @click="onFocus">
                <slot name="list-item" v-bind:list-item="selectedItem">
                    {{ selectedItem.value }}
                </slot>
            </div>
            <div style="cursor: pointer" @click="onFocus" v-if="!selectedItem && !showItems">
                {{placeholder}}
            </div>
            <input v-if="showItems" ref="input" v-show="!selectedItem" :placeholder="placeholder" @focus="onFocus"
                   type="text"
                   v-model="searchTerm">
            <div class="form-item-select-input-clear" v-if="showItems" @click="onDismiss" style="cursor: pointer"><i class="fa fa-times"></i></div>
        </div>

        <div v-show="showItems" class="form-item-select-dropdown">
            <div class="form-item-select-dropdown-placeholder" v-if="!searchTerm">
                Begin met typen
            </div>
            <div  class="form-item-select-dropdown-empty" v-if="searchTerm && !items.length && !typing && !loading">
                Geen resultaten gevonden
            </div>
            <div  class="form-item-select-dropdown-loading" v-if="loading">
                <i class="fa fa-spinner fa-spin"></i>
            </div>
            <div class="form-item-select-dropdown-results">
	            <div @click="onSelect(selectItem)" style="cursor: pointer" class="form-item-select-dropdown-result" v-for="selectItem in items">
	                <slot name="list-item" v-bind:list-item="selectItem">
	                    {{ selectItem.value }}
	                </slot>
	            </div>
            </div>
        </div>
        <slot v-if="showItems" name="extra-action"></slot>

    </div>
</template>


<script>

    import { debounce } from "../helpers";

    export default {
        name: "DropdownSelect",
        components: {},
        props: {
            url: {
                type: String,
            },
            itemCallback: {
                type: Function,
            },
            onItemSelected: {
                type: Function,
            },
            placeholder: {
                type: String,
                default: "Klik om te selecteren",
            },
            initialSelected: {
                type: Object,
                default: null,
            },
            preload: {
                type: Boolean,
                default: false,
            },
        },

        data: () => ({
            searchTerm: '',
            items: [],
            responseItems: [],
            page: 1,
            params: [],
            showItems: false,
            typing: true,
            loading: false,
            selectedResponseItem: null,
            selectedItem: null,
        }),

        mounted() {
            if (this.initialSelected) {
                this.selectDefault();
            }
        },
        watch: {
            initialSelected: function (val) {
                if (val) {
                    this.selectDefault();
                }
            },
            searchTerm: debounce(function (value) {
                this.typing = false;
                this.page = 0;
                if (!value && !this.preload) {
                    return;
                }

                this.loadItems(value);
            }, 500)
        },

        methods: {
            selectDefault() {
                this.onItemsReceived([this.initialSelected]);
                this.onSelect(this.items[0], false);
            },
            onItemsReceived(responseItems) {
                this.responseItems = responseItems;
                if (this.itemCallback) {
                    this.items = Object.assign([], responseItems.map(this.itemCallback));
                } else {
                    this.items = this.mapItems(responseItems)
                }
                this.typing = true;
                this.loading = false;
            },
            loadItems(value) {
                this.loading = true;
                axios.get(this.getUrl(value)).then(res => {
                    this.page++;
                    return res.data.data;
                }).then(this.onItemsReceived)
            },
            mapItems(responseItems) {
                return responseItems.map(item => ({
                    id: item.id,
                    value: item.full_name ? item.full_name : item.name,
                }))
            },
            getUrl(value) {
                let cleanUrl = this.url.split('?')[0];
                let ownParams = this.url.split('?')[1];
                let params = '';
                if (ownParams) {
                    params = '&' + ownParams;
                }
                return `${cleanUrl}?search=${value}&page=${this.page}${params}`;
            },
            onSelect(item, notify = true) {
                this.selectedItem = item;
                let responseItem = this.responseItems.find(i => i.id === item.id);
                this.selectedResponseItem = responseItem;
                if (notify) {
                    if (this.onItemSelected) {
                        this.onItemSelected(responseItem);
                    } else {
                        this.$emit('onSelect', responseItem);
                        this.$el.dispatchEvent(new CustomEvent('item-select', { detail: { item: Object.assign({}, responseItem) } }));
                    }
                }

                this.showItems = false;
            },
            onFocus() {
                this.showItems = true;
                this.selectedItem = null;
                if (this.preload) {
                    this.loadItems('');
                }
                this.$nextTick(() => {
                    this.$refs.input.focus();
                });
            },
            clickAway() {
                if (!this.selectedItem && this.initialSelected) {
                    this.selectDefault();
                    this.showItems = false;
                } else {
                    this.onFocusLost()
                }
            },
            onFocusLost() {
                this.typing = false;
                this.loading = false;
                this.items = [];
                this.searchTerm = '';
                this.showItems = false;
            },
            onDismiss() {
                this.selectedResponseItem = null;
                this.selectedItem = null;
                this.searchTerm = '';
                this.onFocusLost();
                this.$nextTick(() => {
                    this.$refs.input.focus();
                });

                this.onFocus();
            }
        }
    }
</script>
