export default {
    methods: {
        setPhoneNumber(phoneNumber) {
            this.callBeforePhoneNumber = phoneNumber;
        },
        onAddPhoneNumberClick() {
            $(this.$refs.modal.$refs.el).data('clear', false);
            $(this.$refs.modal.$refs.el).modal('hide');
            window.PhoneNumberModal.RemoveListeners('submit');

            window.PhoneNumberModal.On('submit', (e) => {
                $(this.$refs.modal.$refs.el).modal('show');
                $(this.$refs.modal.$refs.el).data('clear', true);
                this.setPhoneNumber(e.item);
            });

            window.PhoneNumberModal.Load(`/master-data/phone-numbers/create?customer_id=${this.order.customer.id}`)
        },
        attributeIsRequired(priceAttribute) {
            return this.getCategoryAttributeFromPriceAttribute(priceAttribute).is_required;
        },
        attributeIsLocked(priceAttribute) {
            return this.getCategoryAttributeFromPriceAttribute(priceAttribute).locked_after_delivery && this.order.is_delivered;
        },
        getCategoryAttributeFromPriceAttribute(priceAttribute) {
            const category = this.selectedProduct.product_category;
            const index = category.product_category_attributes.findIndex(a => {
                return a.id === priceAttribute.product_category_attribute_id
            });
            return category.product_category_attributes[index];
        },
        isChecked(attribute, option) {
            const selectedAttribute = this.getSelectedAttribute(attribute);
            if (!selectedAttribute) {
                return false;
            }

            return selectedAttribute.option_id === option.id;
        },
        getAttributeTotalPrice() {
            return this.selectedAttributes.reduce((val, attribute) => {
                let priceAttribute = this.selectedProduct.price.price_attributes.find(a => a.id === attribute.id);
                if (!priceAttribute) {
                    return val;
                }
                return val + parseFloat(priceAttribute.options.find(o => o.id === attribute.option_id).price_incl);
            }, 0);
        },
        getSelectedAttribute(priceAttribute) {
            return this.selectedAttributes.find(a => a.id === priceAttribute.id)
        },
        getPriceDifference(option) {
            if (!option) {
                return 0;
            }


            let optionIds = [];

            if (this.order.id) {
                this.oldOrderItem.attributes.map(product_attribute => {
                    optionIds.push(product_attribute.option.id)
                });
            }

            if (optionIds.includes(option.id)) {
                return 0;
            }

            if (this.customPriceIncl) {
                return parseFloat(option.price_incl) - parseFloat(this.customPriceIncl);
            }

            const otherCurrentOptionsPrice = this.oldOrderItem.attributes.filter(product_attribute => {
                return product_attribute.price_product_attribute_id === option.price_product_attribute.id;
            }).reduce((acc, curr) => acc + parseFloat(curr.option.price_incl), 0);

            return parseFloat(option.price_incl) - otherCurrentOptionsPrice;
        },
        selectOption(attribute, option, canEdit = false) {
            console.log(this.attributeIsLocked(attribute), !this.productIsEditable);
            if (this.attributeIsLocked(attribute)) {
                return;
            }
            if (!this.productIsEditable && !canEdit) {
                return;
            }

            const currentIndex = this.selectedAttributes.findIndex(a => a.id === attribute.id);
            if (currentIndex === -1) {
                this.selectedAttributes.push({
                    id: attribute.id,
                    option_id: option.id,
                })
            } else {
                const temp = Object.assign([], this.selectedAttributes);
                if (temp[currentIndex].option_id === option.id && !this.attributeIsRequired(attribute)) {
                    temp.splice(currentIndex, 1);
                } else {
                    temp[currentIndex].option_id = option.id;
                }
                this.selectedAttributes = temp;
            }


            this.customPriceIncl = null;
            this.customPriceReason = null;
            this.customPriceTypeId = null;
        },
        setProduct(productId) {
            this.productId = productId;
            this.selectedProduct = this.products.find(p => p.id === parseInt(productId));
            this.setProductAttributes();
        },
        setProductAttributes() {
            if (this.$store.state.editingOrderItem && this.$store.state.editingOrderItem.attributes && this.$store.state.editingOrderItem.attributes.length && this.productId === this.$store.state.editingOrderItem.product.id) {
                this.selectedAttributes = this.$store.state.editingOrderItem.attributes.map(product_attribute => {
                    return {
                        id: product_attribute.price_product_attribute_id,
                        option_id: product_attribute.price_product_attribute_option_id,
                    }
                });
                return;
            }

            // select default
            const selectedAttributes = [];
            this.selectedProduct.price.price_attributes.forEach(priceAttribute => {
                if (priceAttribute.default_price_option_id && this.attributeIsRequired(priceAttribute)) {
                    selectedAttributes.push({
                        id: priceAttribute.id,
                        option_id: priceAttribute.default_price_option_id,
                    })
                }
            })
            this.selectedAttributes = selectedAttributes;
        },
        getProductName(product) {
            if (product.customers) {
                return product.name + ' - ' + product.customers
            }
            return product.name;
        },
        hasRequiredAttributesSelected() {
            for (const priceAttributesKey in this.selectedProduct.price.price_attributes) {
                const attribute = this.selectedProduct.price.price_attributes[priceAttributesKey];
                if (this.attributeIsRequired(attribute) && !this.getSelectedAttribute(attribute)) {
                    return false
                }
            }
            return true;
        },
        totalDiscountLabel() {
            if (this.customPriceIncl === null) {
                return '';
            }
            const newPriceIncl = parseFloat(this.customPriceIncl)
            const totalPrice = this.getAttributeTotalPrice();
            const discountAmount = this.$options.filters.format_money((this.getAttributeTotalPrice() - newPriceIncl).toFixed(2));
            const percentage = newPriceIncl / totalPrice * 100
            return `${discountAmount} (${percentage.toFixed(0)}%)`;
        }
    },
    computed: {
        productIsEditable() {
            return !this.$store.state.order.id || (this.isEditingOrderItem && this.$store.state.editingOrderItem && this.$store.state.editingOrderItem.product_is_editable)
        }
    }
}
