<template>
    <div>
        <div class="box box--offset">
            <div class="box-grid" v-if="company.webshops && company.webshops.length">

                <div class="box-grid-col box-grid-col--aside">
                    <div class="box-cards box-cards--tabs nav" id="myTab" role="tablist">
                        <a
                            v-for="(webshop, index) in company.webshops"
                            :class="['box-card', { 'active': currentTab === index }]"
                            data-toggle="tab"
                            :href="'#tab-'+index"
                            role="tab"
                            :key="webshop.id"
                        >

                            <div class="box-card-content">
                                <div class="box-card-list">
                                    {{ webshop.name }}
                                </div>
                            </div>
                        </a>
                    </div>
                </div>

                <div class="box-grid-col box-grid-col--content">
                    <div class="tab-content">
                        <div
                            :class="['tab-pane fade', { 'show active': currentTab === index }]"
                            v-for="(webshop, index) in company.webshops"
                            :aria-selected="currentTab === index"
                            :key="webshop.id"
                            :id="'tab-'+index"
                        >

                            <div class="box-card">
                                <div class="box-card-header">
                                    <div class="box-card-header-title">
                                        <h3>{{ webshop.name }}</h3>
                                    </div>
                                </div>
                                <div class="box-card-content">
                                    <general-week-presence :webshop-id="webshop.id"></general-week-presence>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="box">
            <div class="box-content">
                <h5 class="card-title d-flex">
                    <span class="flex-grow-1">Uitzonderingen</span>
                    <button
                        type="button"
                        role="button"
                        class="btn btn-sm btn-primary"
                        title="Toevoegen"
                        :data-modal="this.baseUrl + '/exceptions/create'"
                    >
                        <i class="far fa-plus-circle"></i>
                    </button>
                </h5>

                <exceptions-overview class="mt-3"></exceptions-overview>
            </div>
        </div>
    </div>
</template>

<script>
import Trans from "../../mixins/Trans";
import GeneralWeekPresence from "./GeneralWeekPresence";
import ExceptionsOverview from "./ExceptionsOverview";
import AvailabilityExceptionModal from "./Exceptions/Modal";

export default {
    name: 'webshop-presence-page',

    components: {AvailabilityExceptionModal, ExceptionsOverview, GeneralWeekPresence},

    mixins: [Trans],

    props: {
        baseUrl: {
            type: String,
            required: true
        },

        apiUrl: {
            type: String,
            required: true
        },

        company: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            shownLocation: 0,
            currentTab: 0,
        }
    },

    methods: {
        toggleExceptionsModal() {
            this.$refs.exceptionsModal.shown = !this.$refs.exceptionsModal.shown;
        },

        // updateDeadline() {
        //     axios.put(this.clientUrl, {deadline: this.deadline})
        //         .then(res => {
        //             $.notify(this.t('msg.saved'), 'success');
        //         })
        //         .catch(err => {
        //             if (err.response && err.response.data && err.response.data.errors && err.response.data.errors.deadline) {
        //                 $.notify(err.response.data.errors.deadline[0], 'error');
        //             }
        //         })
        // }
    },

    created() {
        this.$store.commit('openingHours/setBaseUrl', this.baseUrl);
        this.$store.commit('openingHours/setApiUrl', this.apiUrl);
        this.$store.commit('openingHours/setCompany', this.company);
        this.$store.dispatch('openingHours/setOpeningHours', this.company.opening_hours);
    }
}
</script>

