export default {
    namespaced: true,

    state: {
        days: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
        baseUrl: null,
        apiUrl: null,
        permissions: {},
        general: [],
        exceptions: [],
        client: null
    },

    mutations: {
        setBaseUrl (state, payload) {
            state.baseUrl = payload;
        },

        setApiUrl (state, payload) {
            state.apiUrl = payload;
        },

        setPermissions (state, payload) {
            state.permissions = payload;
        },

        setCompany (state, payload) {
            if (state.company && state.company.id === payload.id) state.company = null;
            else state.company = payload;
        },

        setGeneral (state, payload) {
            state.general = payload;
        },

        setExceptions (state, payload) {
            state.exceptions = payload;
        },

        addGeneral (state, payload) {
            state.general.push(payload)
        },

        updateGeneral (state, payload) {
            const availability = state.general.find(item => item.id === payload.id);

            for (let key in payload) {
                if (payload.hasOwnProperty(key) && availability.hasOwnProperty(key)) {
                    availability[key] = payload[key];
                }
            }
        },

        deleteGeneral (state, payload) {
            const index = state.general.indexOf(payload);


            if (index > -1) state.general.splice(index, 1);
        },

        addException (state, payload) {
            state.exceptions.push(payload)
        }
    },

    actions: {
        setOpeningHours ({commit}, payload) {
            const general = [];
            const exceptions = [];

            payload.map(openingHour => {
                if (!openingHour.is_exception) general.push(openingHour);
                else exceptions.push(openingHour)
            });

            commit('setGeneral', general);
            commit('setExceptions', exceptions);
        },

        updateOrCreate ({dispatch, commit}, payload) {
            if (payload.id) {
                return dispatch('update', payload);
            }
            else {
                return dispatch('create', payload);
            }
        },

        create ({dispatch, commit, state}, payload) {
            return axios.post(state.apiUrl, payload)
                .then(res => {
                    if (res && res.data && res.data.item) {
                        commit('addGeneral', res.data.item)
                        return res;
                    }
                });
        },

        update ({dispatch, commit, state}, payload) {
            return new Promise((resolve, reject) => {
                if (!payload || !payload.id) return reject();

                const data = {...payload, ...{ '_method': 'PUT' }};
                axios.post(state.apiUrl + '/' + payload.id, data)
                    .then(res => {
                        commit('updateGeneral', payload);
                        resolve(res)
                    })
                    .catch(err => reject(err));
            });
        },

        destroy ({commit, state}, payload) {
            return new Promise((resolve, reject) => {
                if (!payload || !payload.id) return reject();

                axios.post(state.apiUrl + '/' + payload.id, { '_method': 'DELETE' })
                    .then(res => {
                        commit('deleteGeneral', payload);
                        resolve(res)
                    })
                    .catch(err => reject(err));
            });
        }
    },

    getters: {
        getSortedGeneralForWebshop (state) {
            return webshopId => {
                const general = state.general.filter(presence => presence.webshop_id === webshopId && !presence.is_exeption);
                const res = {};

                state.days.forEach(dayName => {
                    res[dayName] = general.find(obj => obj.day_of_week === dayName);
                });

                return res;
            }
        },

        getThisWeeksExceptions (state) {
            let thisWeek = moment().week();

            return state.exceptions.filter(exception => {
                let date = moment(exception.date.slice(0,10));
                return thisWeek === date.week();
            });
        }
    }
};
