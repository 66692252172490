export default {
    methods: {
      trans (key) {
        return key.split('.').reduce((o,i)=>o[i], window.translations)
      },

      t (key) {
        let data
        let parts = key.split('.')

        for (let i = 0; i < parts.length; i++) {
          if (!data) data = window.translations
          if (!(parts[i] in data)) return key
          data = data[parts[i]]
        }

        return data
      }
    }
}