<template>
    <div>
        <h2>
            Extra kosten
        </h2>

        <table class="table table--noHeader" v-if="$store.state.order.extra_costs">
            <tbody>
            <tr v-for="(extraCost, i) in $store.state.order.extra_costs" :key="i">
                <td>
                    <span v-text="extraCost.extra_cost_type.name"></span>
                </td>
                <td>
                    &euro; <span v-text="extraCost.customer_price_incl"></span>
                </td>
                <td class="text-right">
                    <span v-if="!extraCost.is_invoiced">
                        <a class="btn btn-light" @click="edit(extraCost)">
                            <i class="fal fa-pencil"></i>
                        </a>
                    </span>
                </td>
            </tr>
            </tbody>
        </table>
        <div @click="edit()" v-if="!editing" class="table-add">
            Klik hier om extra kosten toe te voegen
        </div>

        <div v-if="editing && editingExtraCost" class="box-card box-card--single box-card--offset">
            <div class="box-card-content">
                <div class="form-row">
                    <div class="col-12">
                        <form-control label="Type extra kosten" :edit="true"
                                      :name="'extra_costs.'+editingIndex+'.extra_cost_type_id'">
                            <template v-slot:input>
                                <select class="form-control" v-model="editingExtraCost.extra_cost_type_id">
                                    <option disabled value="">Selecteer een type</option>
                                    <option v-for="option in $store.state.extraCostTypes" :value="option.id"
                                            v-text="option.name"></option>
                                </select>
                            </template>
                        </form-control>
                    </div>
                </div>

                <div class="form-row" v-if="editingExtraCost.extra_cost_type_id">
                    <div class="col-6">
                        <div class="form-row">
                            <div class="col-6">

                                <form-control tooltip="Prijs incl. BTW" label="Meerprijs klant" :edit="true"
                                              :name="'extra_costs.'+editingIndex+'.customer_price_incl'">
                                    <template v-slot:input>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">&euro;</span>
                                            </div>
                                            <input type="text" class="form-control"
                                                   v-model="editingExtraCost.customer_price_incl"
                                                   placeholder="Meerprijs klant">
                                        </div>
                                    </template>
                                </form-control>

                            </div>
                            <div class="col-6">
                                <form-control :label="'Excl. BTW (' + (vatPercentage) +'%)'" :edit="false" :value="customerPriceExcl | format_money">

                                </form-control>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-row">
                            <div class="col-3">
                                <form-control label="Leverancier" :edit="true"
                                              :name="'extra_costs.'+editingIndex+'.custom_supplier_id'">
                                    <template v-slot:input>
                                        <div class="form-item-select form-item-select">
                                            <dropdown-select class="form-view"
                                                             @onSelect="onSupplierSelect"
                                                             :preload="false"
                                                             :initial-selected="editingExtraCost.custom_supplier"
                                                             :itemCallback="(item) => ({
                                                        id: item.id,
                                                        value: item.name,
                                                     })"
                                                             url="/company/api/sales/orders/suppliers">
                                            </dropdown-select>
                                        </div>
                                    </template>
                                </form-control>
                            </div>
                            <div class="col-3">
                                <form-control tooltip="Prijs excl. BTW" label="Meerprijs leverancier" :edit="true"
                                              :name="'extra_costs.'+editingIndex+'.supplier_price_excl'">
                                    <template v-slot:input>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">&euro;</span>
                                            </div>
                                            <input type="text" class="form-control" v-model="editingExtraCost.supplier_price_excl" placeholder="Meerprijs leverancier">
                                        </div>
                                    </template>
                                </form-control>
                            </div>
                            <div class="col-2">
                                <div class="form-item form-item--noLabel">
                                    <span v-if="marginPrice >= 0" class="tag tag--large tag--success">{{ marginPrice }}%</span>
                                    <span v-if="marginPrice < 0" class="tag tag--large tag--danger">{{ marginPrice }}%</span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="form-row" v-if="editingExtraCost.extra_cost_type">
                    <div class="col-12">

                        <form-control label="Omschrijving factuurregel" :edit="true" :name="'extra_costs.'+editingIndex+'.invoice_description'">
                            <template v-slot:input>
                                <textarea class="form-control" v-model="editingExtraCost.invoice_description" :placeholder="'Standaard waarde: ' + (editingExtraCost.extra_cost_type.default_invoice_description ? editingExtraCost.extra_cost_type.default_invoice_description : editingExtraCost.extra_cost_type.name)"/>

                            </template>
                        </form-control>
                    </div>
                </div>
                <div class="form-row" v-if="editingExtraCost.extra_cost_type">
                    <div class="col-6">

                        <div class="form-item form-item--checkbox">
                            <input id="persist-cancellation" v-model="editingExtraCost.persist_after_cancellation" type="checkbox">
                            <label for="persist-cancellation" class="control-label">Behouden na order annulering</label>
                        </div>
                    </div>
                    <div class="col-6">

                        <div class="form-item form-item--checkbox">
                            <input id="never-credit" v-model="editingExtraCost.never_credit" type="checkbox">
                            <label for="never-credit" class="control-label">Nooit crediteren</label>
                        </div>
                    </div>
                </div>
                <button type="button" class="btn btn-light" @click="doneEditing">Toevoegen <i
                    class="fal fa-plus-circle"></i></button>
            </div>
        </div>

    </div>

</template>

<script>
import FormControl from "../FormControl";

export default {
    name: "ExtraCostForm",
    components: { FormControl },

    data: () => ({
        editing: false,
        editingExtraCost: null,
        editingIndex: null,
    }),

    computed: {
        customerPriceExcl() {
            if (!this.editingExtraCost) {
                return;
            }
            if (!this.editingExtraCost.extra_cost_type) {
                return;
            }

            let percentage = parseAllFloat(this.vatPercentage) / 100;
            return parseAllFloat(this.editingExtraCost.customer_price_incl) / (1 + percentage);
        },
        marginPrice() {
            if (!this.editingExtraCost) {
                return 0;
            }

            if (!this.editingExtraCost.extra_cost_type) {
                return 0;
            }

            if (!this.customerPriceExcl || !this.editingExtraCost.supplier_price_excl) {
                return 0;
            }

            return (((this.customerPriceExcl - parseAllFloat(this.editingExtraCost.supplier_price_excl)) / this.customerPriceExcl) * 100).toFixed(2);
        },
        vatRate() {
            return this.$store.state.vatRates.find(v => v.id == this.editingExtraCost.extra_cost_type.vat_rate_id);
        },
        vatPercentage() {
            if (this.editingExtraCost.extra_cost_type) {
                return this.$store.state.order.customer.vat_percentage ? this.$store.state.order.customer.vat_percentage : this.vatRate.current_percentage.percentage;
            }
        }
    },
    watch: {
        'editingExtraCost.extra_cost_type_id': function (val) {
            if (!val) {
                return;
            }
            if (this.editingExtraCost.extra_cost_type && this.editingExtraCost.extra_cost_type.id === val) {
                return;
            }

            let cost_type = this.$store.state.order.supplier_costs.find(v => v.extra_cost_type_id == val);
            if (cost_type) {
                this.editingExtraCost.supplier_price_excl = cost_type.price_excl;
            }

            this.editingExtraCost.extra_cost_type = this.$store.state.extraCostTypes.find(v => v.id == val);
            this.editingExtraCost.persist_after_cancellation = this.editingExtraCost.extra_cost_type.persist_after_cancellation;
            this.editingExtraCost.never_credit = this.editingExtraCost.extra_cost_type.never_credit;
        }
    },
    methods: {
        onSupplierSelect(value) {
            this.editingExtraCost.custom_supplier = value;
        },
        onClose() {
            this.editing = false;
            this.editingExtraCost = null;
            this.editingIndex = null;
        },
        doneEditing() {
            let costs = Object.assign([], this.$store.state.order.extra_costs);
            costs[this.editingIndex] = Object.assign({}, this.editingExtraCost);
            let data = {
                add: true,
                extraCosts: costs,
            };
            if (this.editingExtraCost.id) {
                data.add = false;
            }
            return this.$store.dispatch('setExtraCosts', data).then(done => {
                this.editing = false;
                this.editingIndex = null;
                this.editingExtraCost = null;
                return true;
            });
        },
        save(andInvoice = true) {
            if (this.editing) {
                return this.doneEditing().then(() => {
                    return this.$store.dispatch('saveExtraCosts', andInvoice);
                }).catch(err => {
                    this.editing = true;
                    throw err;
                });
            }
            return this.$store.dispatch('saveExtraCosts', andInvoice).catch(err => {
                this.editing = true;
                throw err;
            });
        },
        edit(extraCost = null, customerPrice = null) {
            let index = null;
            let supplier = this.$store.state.order.supplier;

            if (!extraCost) {
                index = this.$store.state.order.extra_costs.length;
                extraCost = {
                    id: null,
                    customer_price_incl: customerPrice,
                    supplier_price_excl: null,
                    extra_cost_type: null,
                    extra_cost_type_id: '',
                    is_invoiced: false,
                    invoiced_at: false,
                    invoice_description: null,
                    persist_after_cancellation: false,
                    custom_supplier: supplier ? supplier : null,
                    never_credit: false,
                }
            } else {
                index = this.$store.state.order.extra_costs.indexOf(extraCost)
            }
            this.editingIndex = index;
            this.editingExtraCost = Object.assign({}, extraCost);
            this.editing = true;
        }
    }

}
</script>
