import moment from 'moment'

export default {
    methods: {
        init() {

            $('.js-contract-offer-id').on('change', () => {
                this.contractOfferId = $('.js-contract-offer-id').val();
            });
            this.contractOfferId = $('.js-contract-offer-id').val();

            if (this.frequency.start) {
                this.start = moment(this.frequency.start).format('YYYY-MM-DD');
            }

            if (this.frequency.start_emptyings_at) {
                this.startEmptyingsAt = moment(this.frequency.start_emptyings_at).format('YYYY-MM-DD');
            }
            if (this.frequency.rrule) {
                let data = {};

                this.frequency.rrule.split(';').forEach(function (rule) {
                    let props = rule.split('=');
                    if (props.length > 1) data[props[0].toLowerCase()] = props[1];
                });

                if (data.freq) this.changeFrequency(data.freq);
                if (data.interval) this.changeInterval(data.interval);

                if (data.bysetpos) {
                    if (data.freq === 'MONTHLY') this.monthType = 'custom';
                    this.setBySetPos(data.bysetpos);
                    this.setMonthDaySelect(data.byday);
                }

                if (data.byday) this.setByDay(data.byday);
                if (data.bymonthday) this.setByMonthDay(data.bymonthday);
                if (data.bymonth) this.setByMonth(data.bymonth.split(',').map(item => parseInt(item)));
                if (data.count) this.count = parseInt(data.count);
                if (data.until) this.until = moment(data.until).format('YYYY-MM-DD');

                if (data.freq === 'YEARLY' && data.byday && data.bysetpos && data.bymonth) {
                    this.setMonthDaySelect(data.byday);
                    this.yearType = 'custom';
                } else if (data.freq === 'YEARLY' && data.bymonth && data.bymonthday) {
                    this.yearType = 'once';
                } else {
                    this.yearType = 'in';
                }

                if (data.count) {
                    this.endType = 'count';
                    this.count = parseInt(data.count);
                }

                if (data.until) {
                    this.endType = 'until';
                    this.until = moment(data.until).format('DD-MM-YYYY');
                }

                this.recurrent = true;
            } else {
                if (this.frequency.start) {
                    this.freq = 'on_call';
                    if (this.frequency.end) {
                        this.endType = 'until';
                        this.until = moment(this.frequency.end).format('DD-MM-YYYY');
                    }
                }
            }
        }
    }
}
