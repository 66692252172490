<template>
    <div style="display: flex; flex-direction: column">
        <div v-if="order.customer" class="h-100">
            <div class="customerCard h-100">
                <div class="customerCard-tools dropdown">
                    <a id="customerDropdown" class="dropdown-toggle customerCard-tool" href="#" role="button"
                       data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <div class="icon icon--ellipsis"></div>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="customerDropdown">
                        <a @click="editing = true" class="dropdown-item" href="#">
                            Bewerken
                        </a>
                    </div>
                </div>
                <div class="customerCard-brand">
                    <div class="customerCard-brand-logo"
                         :style="'background-image: url(' + order.webshop.image_url + ')'"></div>
                </div>
                <div class="customerCard-header">
                    <h2 class="customerCard-header-title">

                        <a href="#" class="pointer" @click="editing = true">
                            <span v-if="order.customer.company_name">{{ order.customer.company_name }} - </span>
                            {{ order.customer.full_name }}
                        </a>
                    </h2>

                    <div class="customerCard-header-details">
                        <div class="customerCard-header-detail">
                            <i class="fal fa-user"></i>&nbsp; {{ order.customer.customer_nr }}
                        </div>
                        <div class="customerCard-header-detail">
                            <div>
                                <outbound-call-button :number="order.phone_number.phone_number" :webshop="order.webshop" v-if="order.phone_number">
                                    <i class="fal fa-phone"></i>&nbsp; {{ order.phone_number.phone_number }}
                                </outbound-call-button>
                                <outbound-call-button v-else-if="order.customer.phone_number.phone_number" :number="order.customer.phone_number.phone_number" :webshop="order.webshop">
                                    <i class="fal fa-phone"></i>&nbsp; {{ order.customer.phone_number.phone_number }}
                                </outbound-call-button>
                                <outbound-call-button v-else-if="order.customer.phone_mobile" :number="order.customer.phone_mobile" :webshop="order.webshop">
                                    <i class="fal fa-phone"></i>&nbsp; {{ order.customer.phone_mobile }}
                                </outbound-call-button>
                                <outbound-call-button v-else-if="order.customer.phone" :number="order.customer.phone" :webshop="order.webshop">
                                    <i class="fal fa-phone"></i>&nbsp; {{ order.customer.phone }}
                                </outbound-call-button>
                                <span v-else class="text-danger">
                                    <i class="fal fa-phone"></i>&nbsp; ?
                                </span>
                            </div>
                        </div>
                        <div class="customerCard-header-detail js-customer-mails pointer">
                            <i class="fal fa-envelope-open"></i>&nbsp; {{ order.customer.email }}
                        </div>
                        <div class="customerCard-header-detail js-customer-mails pointer" v-if="order.customer.administration_email">
                            <i class="fal fa-envelope-open-dollar"></i>&nbsp; {{ order.customer.administration_email }}
                        </div>
                        <div class="customerCard-header-detail">
                            <i class="fal fa-handshake"></i>&nbsp; {{ order.customer.payment_condition }}
                        </div>
                    </div>
                </div>

                <div class="customerCard-content">
                    <div class="customerCard-column">
                        <div class="customerCard-content-item">
                            <a href="#" :data-modal="'/customers/' + order.customer.id + '/modal'" class="btn btn-light">
                                Klantportaal en informatie <i class="far fa-external-link"></i>
                            </a>
                        </div>
                        <div class="customerCard-content-item">
                            <div @click="editing = true" class="alert alert--danger pointer" v-if="order.customer.unpaid_invoices_count > 0">
                                <span v-if="order.customer.unpaid_invoices_count == 1">
                                   {{order.customer.orders_count}} orders | Er staat nog {{ order.customer.unpaid_invoices_count }} factuur open | {{ getOpenAmount() | format_money }} | +{{ order.customer.unpaid_invoices[0].days_ago }} dgn
                                </span>
                                <span v-if="order.customer.unpaid_invoices_count > 1">{{order.customer.orders_count}} orders | <span v-text="order.customer.unpaid_invoices_count"></span> openstaande facturen | {{ getOpenAmount() | format_money }} | +{{ order.customer.unpaid_invoices[0].days_ago }} dgn</span>
                            </div>
                            <div class="tag tag--success" v-if="order.customer.unpaid_invoices_count == 0">
                                {{order.customer.orders_count}} orders | Betaald
                            </div>
                        </div>
                    </div>
                    <div class="customerCard-column">
                        <div class="customerCard-content-item" v-for="financialEvent in order.customer.financial_events">
                            <div :class="financialEvent.event_type.alert_class">
                                {{ financialEvent.event_type.name }}
                            </div>
                        </div>
                    </div>
                    <div class="customerCard-column">
                        <div class="customerCard-content-item">
                            <a class="btn btn-light" :href="'/customers/' + order.customer.id + '/edit'">
                                Klantenkaart openen <i class="fal fa-user"></i>
                            </a>
                        </div>
                        <div v-if="order.price_list_id" class="customerCard-content-item">
                            <a class="btn btn-light" :href="'/customers/' + order.customer.id + '/prices'">
                                Prijslijst actief <i class="fal fa-tag"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!order.customer">
            <div class="customerCard customerCard--empty">
                <div class="box box--empty">
                    <div class="box-content">
                        <a @click="editing = true" class="btn btn-light" href="#">
                            Klant toevoegen <i class="fal fa-plus"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <customer-form @onClose="onCustomerFormClose" :editing="editing"></customer-form>
    </div>
</template>

<script>

import FormControl from "./FormControl";
import Modal from "./Modal";
import CustomerForm from "./CustomerForm";
import OutboundCallButton from "../../OutboundCallButton";
import AdviceDataModal from "./AdviceDataModal";
import CallDataModal from "./CallDataModal";

export default {
    name: "CustomerDetails",
    components: { AdviceDataModal, CallDataModal, OutboundCallButton, CustomerForm, Modal, FormControl },

    data: () => ({
        editing: false,
        showAdviceDataModal: false,
        showCallDataModal: false,
    }),

    mounted() {
        console.log(this.order.customer)
        if (!this.order.id) {
            this.editing = true;
        }
    },
    computed: {
        order() {
            return this.$store.state.order;
        }
    },
    methods: {
        onCustomerFormClose(showProductForm = false) {
            this.editing = false;
            if (showProductForm) {
                this.editItem();
            }
        },
        resendPaymentRequest() {
            this.$store.dispatch('resendPaymentRequest')
        },
        getPaymentMethod() {
            return this.$store.state.paymentMethods.find(p => p.id === this.order.payment_method_id)
        },
        getOrderOrigin() {
            if (!this.order.order_origin_id) {
                return;
            }
            return this.$store.state.orderOrigins.find(p => p.id === this.order.order_origin_id)
        },
        getTotalAmount() {
            return this.order.customer.unpaid_invoices.reduce((s, item) => {
                return s + item.open_amount
            }, 0)
        },
        getOpenAmount() {
            return this.order.customer.unpaid_invoices.reduce((s, item) => {
                return s + item.open_amount
            }, 0)
        },
        loadCustomerModal() {
            if (this.order.customer) {
                window.QuickCustomerEdit.Load(`/order-customer/${this.order.customer.id}/edit`);
            } else {
                window.QuickCustomerEdit.Load(`/order-customer/create`);
            }
        },
        editItem() {
            if (this.$store.getters.isEditable) {
                this.$store.dispatch('showProductSelection', {
                    order_item: this.order.order_items[0],
                });
            }
        },
        showAdviceData() {
            this.showAdviceDataModal = true;
        },

        showCallData() {
            this.showCallDataModal = true;
        },
    }
}
</script>
