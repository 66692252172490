import Vue from 'vue';

import Vuex from "vuex";
import mutations from "./mutations";
import actions from "./actions";
import openingHours from "./opening-hours";

Vue.use(Vuex);
const store = new Vuex.Store({
    state: {
        order: null,
        errors: {},
        canCreateOrdersToday: null,
        orderStatuses: [],
        pickupPreferences: [],
        deliveryPreferences: [],
        vatRates: [],
        extraCostTypes: [],
        initialOrderStatusId: null,
        isEditingOrderItem: false,
        paymentMethods: [],
        saving: false,
        orderOrigins: [],
        editingOrderItem: null,
        linkedOrders: [],
        isProduction: false,
        overrulingPickup: false,
        cancelPickup: false,
        restartPurchaseInvoicing: false,
        isConcept: true,
        callId: null,
        outsourcing: false,
        disableOrderCreateButton: false,
    },
    modules: {
        openingHours: openingHours,
    },
    mutations: mutations,
    actions: actions,
    getters: {
        isEditable: state => {
            if (!state.order) {
                return false;
            }
            if (state.order.is_cancelled) {
                return false;
            }
            return !state.order.was_imported;

        },
        rentOrderItems(state){
            return state.order.order_items.filter(o => o.has_rental_product === true);
        },
        isConfirmed(state){
            return state.order.order_status_id === 'confirmed';
        },
        orderStatuses: (state) => {
            if (!state.orderStatuses.length) {
                return false;
            }
            if (!state.initialOrderStatusId) {
                state.initialOrderStatusId = state.order.order_status_id
            }
            if (state.initialOrderStatusId === 'confirmed') {
                return state.orderStatuses.filter(status => {
                    return status.id === 'confirmed' || status.id === 'cancelled';
                })
            }

            if (state.initialOrderStatusId === 'concept') {
                return state.orderStatuses.filter(status => {
                    return status.id === 'confirmed' || status.id === 'concept';
                })
            }

            if (state.initialOrderStatusId === 'cancelled') {
                return state.orderStatuses.filter(status => {
                    return status.id === 'cancelled';
                })
            }

        }
    }
});

export default store;
