<template>

    <tr>
        <td>
            <supplier-request-warning :supplier-request="supplierRequest"/>
        </td>
        <td>
            <location-name :location="supplierRequest.location" :supplier="supplierRequest.supplier"></location-name>
        </td>
        <td class="text-center">
            <span v-if="supplierRequest.status_id === 'sent'" class="tag tag--warning w-100">Aangevraagd #{{ supplierRequest.supplier_order_nr }}</span>
            <span v-if="supplierRequest.status_id === 'accepted' && supplierRequest.conditional === false" class="tag tag--success w-100">Geaccepteerd</span>
            <span v-if="supplierRequest.status_id === 'accepted' && supplierRequest.conditional === true" class="tag tag--warning w-100">Andere levering mogelijk <p class="small">({{ supplierRequest.delivery_at_formatted }})</p></span>
            <span v-if="supplierRequest.status_id === 'denied'" class="tag tag--danger w-100">Leveren niet mogelijk</span>
            <span v-if="supplierRequest.status_id === 'not_sent'" class="tag tag--light w-100">Beschikbaarheid onbekend</span>
            <span v-if="supplierRequest.status_id === 'unlinked'" class="tag tag--warning w-100">Ontkoppeld #{{ supplierRequest.supplier_order_nr }} </span>
            <span v-if="!supplierRequest.status_formatted" class="tag tag--light w-100">Volgorde nog niet opgeslagen</span>
            <i class="ml-2 fa fa-info-circle text-success" data-toggle="data-tooltip" title="Geaccepteerd in beschikbaarheidsaanvraag" v-if="supplierRequest.supplier_availability_request"></i>
        </td>
    </tr>


</template>

<script>
import SupplierRequestWarning from "../SupplierRequestWarning";
import SupplierName from "../SupplierName";
import LocationName from "../Locations/LocationName.vue";

export default {
    name: "AllInSupplierRow",
    components: {LocationName, SupplierName, SupplierRequestWarning},
    props: ['supplierRequest'],

    mounted() {
        $('[data-toggle="data-tooltip"]').tooltip();
    },

    computed: {
        mainOrderItem() {
            return this.$store.state.order.order_items[0];
        },
    },
}
</script>
