<template>
    <tr>
        <td v-text="trans(dayName)"></td>
        <td>
            <button class="btn btn-link text-success" v-if="isAvailable" @click="clearInputs">
                <i class="fas fa-check-circle fa-fw"></i>
            </button>

            <span class="icon" v-else>
                <i class="fas fa-times-circle text-muted fa-fw"></i>
            </span>
        </td>

        <td>
            
                <div class="form-item form-item--compactNoLabel">
                    <input type="time" placeholder="Van" class="form-control" v-model="from"
                           @input="setChanged" @focusout="savePresence">
                </div>
        </td>
        <td>
                <div class="form-item form-item--compactNoLabel">
                    <input type="time" placeholder="Tot" class="form-control" max="5" v-model="till"
                           @input="setChanged" @focusout="savePresence">
                </div>
        </td>

        <td class="px-0" style="width: 44px;">
            <div class="spinner-container" v-if="updating">
                <i class="far fa-spinner fa-spin text-primary"></i>
            </div>
        </td>
    </tr>
</template>

<script>
    import Trans from "../../mixins/Trans";
    import FormatTimeString from "../../mixins/FormatTimeString";

    export default {
        name: 'daily-presence-row',

        mixins: [Trans, FormatTimeString],

        props: {
            webshopId: {
                type: Number,
                required: true
            },

            dayName: {
                type: String,
                required: true
            },

            openingHour: {
                validator: prop => typeof prop === 'object' || prop === null || prop === undefined,
                required: true
            }
        },

        data() {
            return {
                changed: false,
                updating: false,
                from: '',
                till: '',
            }
        },

        computed: {
            isAvailable() {
                return this.from.length === 5 && this.till.length === 5
            }
        },

        methods: {
            trans: function (x) {
                const lang = {
                    'monday': "Maandag",
                    'tuesday': "Dinsdag",
                    'wednesday': "Woensdag",
                    'thursday': "Donderdag",
                    'friday': "Vrijdag",
                    'saturday': "Zaterdag",
                    'sunday': "Zondag"
                };

                return lang[x];
            },

            setChanged() {
                this.changed = true;
            },

            savePresence() {
                if (!this.openingHour && !this.isAvailable) return;
                this.updating = true;

                if (this.isAvailable) {
                    this.$store.dispatch('openingHours/updateOrCreate', this.getEntry())
                        .then(res => {
                            if (res && res.data && res.data.item) toastr.success('Opgeslagen');
                            this.updating = false;
                        })
                        .catch(err => {
                            toastr.error('Fout');
                            this.updating = false;
                        })
                } else if (this.openingHour) {
                    this.$store.dispatch('openingHours/destroy', this.openingHour)
                        .then(res => {
                            toastr.success('Verwijderd');
                            this.updating = false;
                        })
                        .catch(err => {
                            toastr.error('Fout');
                            this.updating = false;
                        });
                }
            },

            getEntry() {
                if (this.isAvailable && this.openingHour) {
                    return {...this.openingHour, ...{from: this.from, till: this.till}};
                }

                if (this.isAvailable) {
                    return {
                        'webshop_id': this.webshopId,
                        'day_of_week': this.dayName,
                        'from': this.from,
                        'till': this.till
                    };
                }
            },

            clearInputs() {
                this.from = '';
                this.till = '';

                this.savePresence();
            }
        },

        beforeMount() {
            if (!this.openingHour) return;

            this.from = this.formatTimestamp(this.openingHour.from);
            this.till = this.formatTimestamp(this.openingHour.till);
        }
    }
</script>
