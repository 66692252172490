<template>
    <div>
        <modal ref="modal" :save-disabled="saveDisabled" @onSave="save" @onClose="$emit('onClose')" :show="isEditing">
            <template #title>
                <span>Klant bewerken</span>
            </template>
            <span class="text-danger"
                  v-if="$store.state.errors.customer_id && $store.state.errors.customer_id.length"
                  v-text="$store.state.errors.customer_id[0]"></span>
            <div v-if="!savingCustomer">

                <div v-if="!selectedCustomer">

                    <form-control class="form-item--compact" name="customer_id" label="Klant" :edit="true">
                        <template v-slot:input>
                            <div class="form-item-select form-item-select--withAdd">
                                <dropdown-select
                                    class="form-view"
                                    :initial-selected="selectedCustomer"
                                    @onSelect="(item) => selectedCustomer = item"
                                    url="/company/api/sales/customers">
                                </dropdown-select>
                                <div @click="newCustomer" class="form-item-select-add">
                                    <i class="far fa-plus-circle"></i>
                                </div>
                            </div>
                        </template>
                    </form-control>

                </div>
                <div v-if="selectedCustomer">

                    <div class="form-row">
                        <div class="col">
                            <form-control class="form-item--compact" name="company_name" label="Bedrijfsnaam *" :edit="true">
                                <template v-slot:input>
                                    <input class="form-control js-kvk-search js-customer-company-name" type="text" v-model="selectedCustomer.company_name">
                                </template>
                            </form-control>
                        </div>
                    </div>
<!--                    <div class="form-row">-->
                        <div class="js-search-results">
                            <div class="js-ts-customers"></div>
                            <div class="js-kvk-customers"></div>
                        </div>
<!--                    </div>-->
                    <div class="form-row">
                        <div class="col">
                            <form-control class="form-item--compact" name="nickname" label="Voornaam *" :edit="true">
                                <template v-slot:input>
                                    <input class="form-control" type="text" v-model="selectedCustomer.nickname">
                                </template>
                            </form-control>
                        </div>
                        <div class="col">
                            <form-control class="form-item--compact" name="middle_name" label="Tussenv." :edit="true">
                                <template v-slot:input>
                                    <input class="form-control" type="text" v-model="selectedCustomer.middle_name">
                                </template>
                            </form-control>
                        </div>
                        <div class="col">
                            <form-control class="form-item--compact" name="surname" label="Achternaam *" :edit="true">
                                <template v-slot:input>
                                    <input class="form-control" type="text" v-model="selectedCustomer.surname">
                                </template>
                            </form-control>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="col">
                            <form-control class="form-item--compact" name="email" label="E-mail *" :edit="true">
                                <template v-slot:input>
                                    <input class="form-control" type="text" v-model="selectedCustomer.email">
                                </template>
                            </form-control>
                        </div>
                        <div class="col">
                            <form-control class="form-item--compact" name="administration_email" label="E-mail administratie" :edit="true">
                                <template v-slot:input>
                                    <input class="form-control" type="text" v-model="selectedCustomer.administration_email">
                                </template>
                            </form-control>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col">
                            <form-control class="form-item--compact" name="iban_account_number" label="IBAN" :edit="true">
                                <template v-slot:input>
                                    <input class="form-control" type="text" v-model="selectedCustomer.iban_account_number">
                                </template>
                            </form-control>
                        </div>
                        <div class="col">
                            <form-control class="form-item--compact" name="iban_account_holder" label="IBAN Houder" :edit="true">
                                <template v-slot:input>
                                    <input class="form-control" type="text" v-model="selectedCustomer.iban_account_holder">
                                </template>
                            </form-control>
                        </div>
                    </div>
                    <div v-if="selectedCustomer.id">
                        <form-control class="form-item--compact" name="phone_number_id" label="Telefoonnummer" :edit="true">
                            <template #input>
                                <div class="form-item-select form-item-select--withAdd">
                                    <dropdown-select class="form-view" :initial-selected="selectedPhoneNumber"
                                                     @onSelect="setPhoneNumber"
                                                     :preload="true"
                                                     :itemCallback="(item) => ({
                                                     id: item.id,
                                                     value: item.display,
                                                 })"
                                                     :url="'/company/api/sales/customers/' + selectedCustomer.id + '/phone-numbers'">
                                    </dropdown-select>

                                    <div class="form-item-select-add" @click="onAddPhoneNumberClick()">
                                        <i class="far fa-plus-circle"></i>
                                    </div>
                                </div>
                            </template>
                        </form-control>

                        <h3 class="h3--offsetTop">Factuurgegevens</h3>
                        <div class="form-row">
                            <div class="col">
                                <form-control class="form-item--compact" name="invoice_address_id" label="Factuuradres" :edit="true">
                                    <template #input>
                                        <div class="form-item-select form-item-select--withAdd">

                                            <dropdown-select class="form-view" :initial-selected="selectedInvoiceAddress"
                                                             @onSelect="setInvoiceAddress"
                                                             :preload="true"
                                                             :itemCallback="(item) => ({
		                                                                 id: item.id,
		                                                                 value: item.name,
		                                                                 })"
                                                             :url="'/company/api/sales/customers/' + selectedCustomer.id + '/addresses?type=invoice'">

                                            </dropdown-select>

                                            <div class="form-item-select-add" @click="onAddAddressClick()">
                                                <i class="far fa-plus-circle"></i>
                                            </div>

                                        </div>
                                    </template>
                                </form-control>
                            </div>

                        </div>
                        <div class="form-row">
                            <div class="col">
                                <form-control class="form-item--compact" :edit="editing" name="order_origin_id" label="Bron *"
                                              :value="selectedOriginId ? getOrderOrigin().name : 'Onbekend'">
                                    <template v-slot:input>
                                        <select class="form-control" v-model="selectedOriginId">
                                            <option :value="option.id" v-text="option.name"
                                                    v-for="option in $store.state.orderOrigins">{{ option }}
                                            </option>
                                        </select>
                                    </template>
                                </form-control>
                            </div>
                        </div>

                        <h3>Facturen</h3>
                        <table class="table table--noHeader">
                            <tbody>
                            <tr v-for="invoice in selectedCustomer.unpaid_invoices">
                                <td>
                                    <div class="order-table-info">
                                        Factuur {{ invoice.number_label }}
                                        <div v-if="invoice.is_paid && invoice.price_incl" class="text-primary">Credit</div>
                                        <div v-if="invoice.credit_invoice_id " class="text-primary">Gecrediteerd</div>
                                    </div>
                                    <div class="order-table-details">
                                        <a href="#" :data-modal="invoice.download_url">Factuur _{{ invoice.number_label }} .pdf</a>
                                    </div>
                                </td>
                                <td>
                                    Order
                                </td>
                                <td>
                                    Vervallen dagen: {{ invoice.days_ago }}
                                    ({{ invoice.date }})
                                </td>
                                <td>
                                    {{ invoice.price_incl | format_money }}
                                </td>
                                <td>
                                    <div v-if="invoice.open_amount == 0" class="tag tag--success">Betaald</div>
                                    <div v-else class="tag tag--danger">
                                        {{ invoice.open_amount | format_money }} / {{ invoice.price_incl | format_money }}
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div v-else>
                        <button @click="saveCustomer" :disabled="savingCustomer" class="btn btn-primary">Klant aanmaken</button>
                    </div>
                </div>
            </div>
            <div v-else class="col-4 d-flex justify-content-center align-items-center">
                <div><i class="fa text-info fa-spinner fa-spin fa-2x"></i></div>
            </div>

            <template #footer>
                <div>
                    <button v-if="$store.getters.isEditable" type="button" @click="$emit('onClose')" class="btn btn-default">
                        Annuleren
                    </button>
                    <button v-if="$store.getters.isEditable" :disabled="saveDisabled" type="button" @click="save()" class="btn btn-primary">Opslaan</button>
                    <button v-if="$store.getters.isEditable && !order.id" :disabled="saveDisabled" type="button" @click="save(true)" class="btn btn-success">Opslaan + product toevoegen</button>
                </div>
            </template>
        </modal>
    </div>

</template>

<script>

import FormControl from "./FormControl";
import Modal from "./Modal";

export default {
    name: "CustomerForm",
    components: { Modal, FormControl },
    props: ['editing'],

    data: () => ({
        selectedCustomer: null,
        selectedInvoiceAddress: null,
        selectedPhoneNumber: null,
        loadingCustomers: false,
        savingCustomer: false,
        overrideEditing: null,
        selectedOriginId: 'phone',
    }),

    mounted() {
        window.AddressModal = window.AddressModal || AdminModal.Instance();
        window.PhoneNumberModal = window.PhoneNumberModal || AdminModal.Instance();

        window.AddressModal.On('submit', (e) => {
            this.overrideEditing = true;
            this.selectedInvoiceAddress = e.item;
            this.setInvoiceAddress(e.item);
        });

        if (this.order.customer) {
            this.selectedCustomer = this.order.customer;
        }

        if (this.order.invoice_address) {
            this.selectedInvoiceAddress = this.order.invoice_address;
        }

        if (this.order.phone_number) {
            this.selectedPhoneNumber = this.order.phone_number;
        }

        if (this.order.order_origin_id) {
            this.selectedOriginId = this.order.order_origin_id;
        }

    },
    computed: {
        order() {
            return this.$store.state.order
        },
        isEditing: function (){
            if (this.overrideEditing !== null){
                return this.overrideEditing;
            }

            return this.editing;
        },
        saveDisabled: function () {
            if (!this.selectedCustomer || !this.selectedCustomer.id) {
                return true;
            }

            if (!this.selectedPhoneNumber || !this.selectedPhoneNumber.id) {
                return true;
            }

            if (!this.selectedInvoiceAddress || !this.selectedInvoiceAddress.id) {
                return true;
            }

            if (!this.selectedOriginId) {
                return true;
            }


            return false;
        }
    },
    watch: {
        selectedCustomer: function (val) {
            if (this.order.customer && val.id === this.order.customer.id && this.selectedInvoiceAddress && this.selectedPhoneNumber) {
                return;
            }

            this.addresses = val.addresses;
            this.selectedAddress = val.addresses[0];
            if (val.invoice_addresses.length) {
                this.selectedInvoiceAddress = val.invoice_addresses[0];
            } else {
                this.selectedInvoiceAddress = val.addresses[0];
            }

            if (val.phone_numbers.length) {
                this.selectedPhoneNumber = val.phone_numbers[0];
            }
        },
    },
    methods: {
        newCustomer() {
            this.selectedCustomer = {
                company_name: '',
                nickname: '',
                middle_name: '',
                surname: '',
                email: '',
                addresses: [],
                invoice_addresses: [],
                iban_account_number: '',
                iban_account_holder: '',
                phone_numbers: [],
            };
        },
        getOrderOrigin() {
            return this.$store.state.orderOrigins.find(p => p.id === this.selectedOriginId)
        },
        saveCustomer() {
            this.savingCustomer = true;
            this.$store.dispatch('saveCustomer', this.selectedCustomer).then(customer => {
                if (customer) {
                    this.selectedCustomer = customer
                }
            }).finally(() => {
                this.savingCustomer = false;
            });
        },
        save(andOpenProduct = false) {
            this.savingCustomer = true;
            let newData = {
                customer: this.selectedCustomer,
                invoice_address: this.selectedInvoiceAddress,
                phone_number: this.selectedPhoneNumber,
                order_origin_id: this.selectedOriginId,
            };

            this.$store.dispatch('setGeneralData', newData).then(() => {
                this.$emit('onClose', andOpenProduct);
            }).finally(() => {
                this.savingCustomer = false;
            });
        },
        onAddAddressClick() {
            this.overrideEditing = false;

            window.AddressModal.RemoveListeners('submit');

            window.AddressModal.On('submit', (e) => {
                this.overrideEditing = true;
                this.setInvoiceAddress(e.item);
            });

            window.AddressModal.Load(`/master-data/addresses/create?customer_id=${this.selectedCustomer.id}&type=invoice`)
        },
        onAddPhoneNumberClick() {
            this.overrideEditing = false;
            window.PhoneNumberModal.RemoveListeners('submit');

            window.PhoneNumberModal.On('submit', (e) => {
                this.overrideEditing = true;
                this.setPhoneNumber(e.item);
            });

            window.PhoneNumberModal.Load(`/master-data/phone-numbers/create?customer_id=${this.selectedCustomer.id}`)
        },
        setPhoneNumber(phoneNumber) {
            this.selectedPhoneNumber = phoneNumber;
        },
        setInvoiceAddress(address) {
            this.selectedInvoiceAddress = address;
        },

    }

}
</script>
