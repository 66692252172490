<template>
    <div ref="el" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <slot name="title"></slot>
                    </h5>
                    <button type="button" class="close" @click="$emit('onClose')"
                            aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <slot></slot>
                </div>
                <div class="modal-footer">
                    <slot name="footer">
                        <div>
                            <button type="button" @click="$emit('onClose')" class="btn btn-default">
                                Sluiten
                            </button>
                            <button :disabled="saveDisabled" v-show="!hideSave" type="button" @click="$emit('onSave')"
                                    class="btn btn-primary">{{saveText}}
                            </button>
                        </div>
                    </slot>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

    export default {
        name: "Modal",
        props: {
            show: {
                type: Boolean,
                required: true
            },
            saveDisabled: {
                type: Boolean,
                default: false,
            },
            hideSave: {
                type: Boolean,
                default: false,
            },
            saveText: {
                type: String,
                default: 'Opslaan'
            }
        },
        watch: {
            show: function (val) {
                if (val) {
                    $(this.$refs.el).modal('show');
                } else {
                    $(this.$refs.el).modal('hide');
                }
            }
        },
        mounted() {
            this.$nextTick(() => {
                $(this.$refs.el).on('hidden.bs.modal', () => {
                    this.$emit('onClose');
                });
            });
        }
    }
</script>
