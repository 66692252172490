<template>
    <div>

        <div class="form-row">
            <div class="col-6">
                <div class="form-item form-item--compact">
                    <label class="control-label" for="freq">Frequentie</label>
                    <select :disabled="disabled" id="freq" name="freq" class="form-control" v-model="intFreq">
                        <option value="daily" class="days">Dagelijks</option>
                        <option value="weekly" class="weeks">Wekelijks</option>
                        <option value="monthly" class="months">Maandelijks</option>
                        <option value="yearly" class="years">Jaarlijks</option>
                        <option value="on_call">Afroep</option>
                    </select>
                </div>
            </div>

            <div v-if="intFreq !== 'on_call'" class="col-6">
                <div class="form-item form-item--compact">
                    <label class="control-label" for="interval">Elke </label>
                    <div class="input-group">
                        <input
                            :disabled="disabled"
                            type="number"
                            id="interval"
                            name="interval"
                            v-model="intInterval"
                            size="2"
                            min="1"
                            step="1"
                            class="form-control"
                            aria-label="Interval"
                            aria-describedby="frequency-unit"
                        />
                        <div class="input-group-append">
                            <span class="input-group-text" id="frequency-unit" v-text="freqUnit()"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: 'frequency-form',

        props: {
            freq: String,
            interval: Number,
            disabled: Boolean
        },

        computed: {
            intFreq: {
                get() {
                    return this.freq
                },
                set(val) {
                    this.$emit('frequencyChange', val)
                }
            },

            intInterval: {
                get() {
                    return this.interval ? this.interval : this.$emit('intervalChange', 1)
                },
                set(val) {
                    this.$emit('intervalChange', val)
                }
            }
        },

        methods: {
            freqUnit() {
                const units = {
                    daily: ['dag', 'dagen'],
                    weekly: ['week', 'weken'],
                    monthly: ['maand', 'maanden'],
                    yearly: ['jaar', 'jaren'],
                };

                const plural = parseInt(this.interval) !== 1 ? 1 : 0

                return units[this.freq][plural]
            },
        }
    }
</script>
