<template>
    <div class="box-product-header-teaser">
        <div v-if="orderItem.product.image_url" class="box-product-image"><div class="tag-image" :style="'background-image: url(' + orderItem.product.image_url + ')'"></div></div>&nbsp;

        <div class="box-product-name">
            {{orderItem.product.name}}
            <div class="box-product-type">
                {{orderItem.attributes.map(a => a.product_attribute.name + ': ' + a.option.name).join(', ')}}
                <span> | {{orderItem.total_price_excl | format_money}} | {{orderItem.total_price_incl | format_money}}</span>
            </div>
            {{$store.state.order.delivery_address.full_address}}
        </div>
    </div>
</template>
<script>
    export default {
        name: 'product-teaser',
        props: {
            orderItem: { type: Object }
        }
    }
</script>
