<template>
    <div>
        <div class="row">
            <div class="col-12" v-if="saved">
                <div class="alert alert-success alert-dismissible">
                    <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
                    <i class="icon fa fa-check"></i> <span>Bundel opgeslagen</span>
                </div>
            </div>
        </div>
        <div v-if="editBundle" class="d-flex justify-content-between">
            <div>
                <h4>Bewerken bundel</h4>
            </div>

        </div>
        <div v-else><h2>Bundel toevoegen</h2></div>
        <div v-if="!saving">
            <div v-if="showOption">
                <div class="form-item">
                    <label class="control-label" for="attribute">Attribuut</label>
                    <select id="attribute" class="form-control" v-model="attributeId">
                        <option v-for="attribute in productAttributes" :value="attribute.id"
                                v-text="attribute.name"></option>
                    </select>
                </div>
                <div class="form-item" v-if="selectedAttribute">
                    <label class="control-label" for="option">Optie</label>
                    <select id="option" class="form-control" v-model="optionId">
                        <option v-for="option in selectedAttribute.options"
                                :value="option.id"> {{option.name }} - &euro;{{ option.price_incl }}
                        </option>
                    </select>
                </div>

                    <button :disabled="!optionId" class="btn btn-primary"
                            @click="showOption = false">Volgende
                    </button>

            </div>
            <div v-if="!showOption && selectedAttribute && selectedOption" class="box-card box-card--toolSpacing box-card--single">
                <div class="box-card-content">
                    <span>1. {{product.name}} - {{selectedAttribute.name}}: {{selectedOption.name}}</span>
                    <div class="box-card-tools">
                    <a class="box-card-tool" @click="showOption = true"
                       href="#"><i class="fal -hand-pointer-o fa-pencil"></i>
                    </a>
                    </div>
                </div>
            </div>

            <div v-if="!showOption && showBundle">
                <h5>Kies bundelproduct</h5>
                <div class="form-item">
                    <label class="control-label" for="option">Product</label>
                    <select class="form-control" v-model="bundlePriceId">
                        <option v-for="price in prices"
                                :value="price.id"> {{ price.product.name }} - {{price.date_formatted}}
                        </option>
                    </select>
                </div>
                <div class="form-item" v-if="bundlePrice">
                    <label class="control-label" for="option">Attribuut</label>
                    <select class="form-control" v-model="bundleAttributeId">
                        <option v-for="attribute in bundlePrice.product_attributes"
                                :value="attribute.id"> {{attribute.name }}
                        </option>
                    </select>
                </div>
                <div class="form-item" v-if="bundleAttribute">
                    <label class="control-label" for="option_bundle">Optie</label>
                    <select id="option_bundle" class="form-control" v-model="bundleOptionId">
                        <option v-for="option in bundleAttribute.options"
                                :value="option.id"> {{option.name }} - &euro;{{ option.price_incl }}
                        </option>
                    </select>
                </div>
                <div class="form-row mt-2">
                    <button :disabled="!bundleOptionId" class="btn btn-primary"
                            @click="showBundle = false">Volgende
                    </button>
                </div>
            </div>
            <div v-if="!showBundle && showPricing" class="box-card box-card--toolSpacing box-card--single">
                <div class="box-card-content">
                    <span>2. {{bundlePrice.product.name}} - {{bundleAttribute.name}}: {{bundleOption.name}}</span>
                    <div class="box-card-tools">
                    <a class="box-card-tool"
                        @click="showBundle = true"
                        href="#"><i
                        class="fal -hand-pointer-o fa-pencil"></i>
                    </a>
                    </div>
                </div>
            </div>
            <div v-if="showPricing && !showBundle && !showOption">
                <h3>Korting</h3>
                <div class="form-row">
	                <div class="col-6">
		                <div class="form-item">
			                <div class="input-group mb-3">
			                    <div class="input-group-prepend">
			                        <span class="input-group-text">&euro;</span>
			                    </div>
			                    <input placeholder="Kortingsbedrag" id="discount_price" :disabled="discount_percentage != ''"
			                           class="form-control"
			                           v-model="discount_price"/>


			                </div>
		                </div>
	                </div>
	                <div class="col-6">
		                <div class="form-item">
			                <div class="input-group mb-3">

			                    <input placeholder="Kortingspercentage" id="discount_percentage" :disabled="discount_price != ''"
			                           class="form-control"
			                           v-model="discount_percentage"/>
			                    <div class="input-group-append">
			                        <span class="input-group-text" id="basic-addon2">%</span>
			                    </div>
			                </div>
		                </div>
	                </div>
                </div>
                <div class="form-item">
                    <label class="control-label" for="end_date">Einddatum</label>
                    <input id="end_date"
                           class="form-control"
                           type="date"
                           v-model="end_date"/>
                </div>
                <h3>Prijzen</h3>
                <div class="box-cards">
	                <div class="box-card">
		                <div class="box-card-content">
			                <dl class="priceList">
		                        <dt>{{product.name}} - {{selectedAttribute.name}} {{selectedOption.name}}</dt>
		                        <dd>{{selectedOption.price_incl | format_money}}</dd>

		                        <dt>{{bundlePrice.product.name}} - {{bundleAttribute.name}} {{bundleOption.name}}</dt>
		                        <dd>{{bundleOption.price_incl | format_money}}</dd>

		                        <dt v-if="discount_percentage !== ''">Kortingspercentage</dt>
		                        <dd v-if="discount_percentage !== ''">{{discount_percentage}}%</dd>

		                        <dt v-if="discount_price !== ''">Kortingsbedrag</dt>
		                        <dd v-if="discount_price !== ''">{{discount_price | format_money}}</dd>

		                        <dt>Totaalprijs zonder korting</dt>
		                        <dd>{{getTotalPrice() | format_money}}</dd>

		                        <dt>Totaalprijs bundel</dt>
		                        <dd>{{getTotalPriceWithDiscount() | format_money}}</dd>
			                </dl>
		                </div>
	                </div>
                </div>

				<div class="form-actions">
                <button @click="save" class="btn btn-primary">Opslaan <i class="fal fa-save"></i>
                </button>

                <a class="btn btn-light" href="#" @click="stopEditing()">Annuleren</a>
				</div>
            </div>
        </div>
        <div v-if="saving" class="d-flex align-items-center justify-content-center">
            <h4>
                Aan het opslaan...
            </h4>
            <div>&nbsp;<i class="fas fa-3x fa-spin fa-spinner"></i></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "BundleForm",
        props: ['productAttributes', 'price', 'product', 'editBundle'],
        data: () => ({
            attributeId: null,
            optionId: null,
            selectedOption: null,
            selectedAttribute: null,
            prices: [],
            bundlePriceId: null,
            bundlePrice: null,
            bundleAttribute: null,
            bundleOption: null,
            bundleAttributeId: null,
            bundleOptionId: null,
            showOption: true,
            showBundle: true,
            showPricing: true,
            discount_price: '',
            discount_percentage: '',
            saving: false,
            saved: false,
            end_date: null,
        }),
        mounted() {
            if (this.productAttributes.length === 1) {
                this.attributeId = this.productAttributes[0].id;
            }
            this.loadProducts()

        },
        watch: {
            attributeId: function (newId) {
                this.selectedAttribute = null;
                if (!newId) {
                    return;
                }
                this.selectedAttribute = this.productAttributes.find(p => p.id === newId);
                if (this.selectedAttribute.options.lenght === 1) {
                    this.optionId = this.selectedAttribute.options[0].id
                }
            },
            optionId: function (newId) {
                this.selectedOption = null;
                if (!newId) {
                    return;
                }
                this.selectedOption = this.selectedAttribute.options.find(p => p.id === newId)
            },
            productAttributes: function (value) {
                if (value.length === 1) {
                    this.attributeId = value[0].id;
                }
                this.optionId = null;
            },
            price: function (value) {
                this.loadProducts();
            },
            bundlePriceId: function (id) {

                this.bundleAttributeId = null;
                this.bundleOptionId = null;
                this.bundlePrice = null;
                if (!id) {
                    return;
                }
                this.bundlePrice = this.prices.find(p => p.id === id);
                if (this.bundlePrice.product_attributes.length === 1) {
                    this.bundleAttributeId = this.bundlePrice.product_attributes[0].id;
                }
            },
            bundleAttributeId: function (id) {
                this.bundleOptionId = null;
                if (!id) {
                    return;
                }
                this.bundleAttribute = this.bundlePrice.product_attributes.find(p => p.id === id);
                if (this.bundleAttribute.options.length === 1) {
                    this.bundleOptionId = this.bundleAttribute.options[0].id;
                }
            },
            bundleOptionId: function (id) {
                console.log(id);
                this.bundleOption = null;
                if (!id) {
                    return;
                }
                this.bundleOption = this.bundleAttribute.options.find(p => p.id === id);
            },
            editBundle: function (value) {
                if (!value) {
                    this.showPricing = true;
                    this.showBundle = true;
                    this.showOption = true;
                    return;
                }

                this.bundlePriceId = value.bundle_price_id;
                this.attributeId = value.price_product_attribute_id;
                this.optionId = value.price_product_attribute_option_id;
                this.bundleAttributeId = value.bundle_attribute_id;
                this.discount_price = value.discount_price ? value.discount_price : '';
                this.discount_percentage = value.discount_percentage ? value.discount_percentage : '';
                this.end_date = value.end_date;
                this.bundleOptionId = value.bundle_attribute_option_id;
                this.bundleOption = value.bundle_attribute_option;

                this.showPricing = true;
                this.showBundle = false;
                this.showOption = false;

            }
        },

        methods: {
            loadProducts() {
                axios.get('/company/api/bundles/products?price_id=' + this.price.id).then(res => {
                    this.prices = res.data.data.flatMap(product => {
                        return product.prices_without_customer.map(price => {
                            price.product = product;

                            return price;
                        })
                    });
                })
            },
            getTotalPrice() {
                return parseAllFloat(this.bundleOption.price_incl) + parseAllFloat(this.selectedOption.price_incl);
            },
            getTotalPriceWithDiscount() {
                let price = this.getTotalPrice();

                if (this.discount_price !== '') {
                    price = price - parseAllFloat(this.discount_price);
                }

                if (this.discount_percentage !== '') {
                    price = price * ((100 - this.discount_percentage) / 100);
                }

                return price.toFixed(2);
            },
            save() {
                this.saving = true;
                axios.post('/company/api/bundles/store', {
                    id: this.editBundle ? this.editBundle.id : null,
                    product_id: this.product.id,
                    bundle_product_id: this.bundlePrice.product.id,
                    price_id: this.price.id,
                    bundle_price_id: this.bundlePrice.id,
                    price_product_attribute_id: this.selectedAttribute.id,
                    price_product_attribute_option_id: this.selectedOption.id,
                    bundle_attribute_id: this.bundleAttribute.id,
                    bundle_attribute_option_id: this.bundleOption.id,
                    discount_price: this.discount_price,
                    discount_percentage: this.discount_percentage,
                    end_date: this.end_date,
                }).then(res => {
                    if (res.data.success) {
                        this.$emit('saved');
                        this.reset();
                        this.saved = true;
                        return;
                    }
                })
            },
            reset() {
                this.showPricing = false;
                this.showBundle = true;
                this.showOption = true;
                this.attributeId = null;
                this.optionId = null;
                this.bundleAttributeId = null;
                this.bundleOptionId = null;
                this.discount_price = '';
                this.discount_percentage = '';
                this.end_date = null;
                this.saving = false;
                this.showPricing = true;
            },
            stopEditing() {
                this.$emit('saved');
                this.reset();
            }
        }
    }
</script>
