<template>

    <div v-if="openTasks.length" class="mb-2">
        <div v-for="task in openTasks" :class="'alert ' + getClass(task.id)" style="transition: 0.5s ease-in-out" :key="task.id">
            <div class="d-flex flex-row p-1 justify-content-between align-items-center">
                <div class="d-flex flex-row align-items-center">
                    <i class="far fa-bell"></i>
                    <div class="ml-4 justify-content-start text-left" data-toggle="tooltip" :title="'Aangemaakt op ' + task.created_at" :data-id="task.id">
                        <strong v-if="task.task_type">
                            {{ task.task_type.name }}
                        </strong>
                        <br/>
                        <span>
                            {{ task.description }}
                        </span>
                    </div>
                </div>
                <div v-if="!task.is_automated">
                    <div class="btn btn-secondary pointer">
                        <div class="form-item--checkbox pointer">
                            <input @click="markTaskAsComplete(task)" type="checkbox" class="form-check-input pointer" :id="'complete-'+task.id">
                            <label class="form-check-label pointer" :for="'complete-'+task.id">Taak afronden</label>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <i data-toggle="tooltip" title="Deze taak wordt automatisch door het systeem op voltooid gezet nadat de actie is uitgevoerd." class="fal fa-cog"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.btn-secondary:hover {
    background-color: #F5F5FA !important;
    border-color: #F5F5FA !important;
    color: #8181A5 !important;
}
</style>
<script>
export default {
    name: "OrderTasks",
    data() {
        return {
            completedIds: [],
        }
    },
    computed: {
        order() {
            return this.$store.state.order;
        },
        openTasks() {
            return this.$store.state.order.tasks.filter(t => t.completed_at === null);
        },
        completedTasks() {
            return this.$store.state.order.tasks.filter(t => t.completed_at !== null);
        },
    },
    methods: {
        markTaskAsComplete(task) {
            this.$store.dispatch('markTaskAsComplete', task).then(() => {
                this.completedIds.push(task.id);
            });
        },
        getClass(id) {
            const completed = this.completedIds.findIndex(i => i === id) !== -1;
            if (completed) {
                return 'alert--success';
            }

            return 'alert--orange'
        }
    }
}
</script>
