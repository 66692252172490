<template>
    <div class="summary-item">
        <div v-if="orderItem.product.image_url" class="tag tag--withImage tag--square"><div class="tag-image" :style="'background-image: url(' + orderItem.product.image_url + ')'"></div></div>&nbsp;

        <div class="summary-item-title">
            {{orderItem.quantity}}x {{orderItem.product.name}}
            <div class="summary-item-subtitle">
                {{orderItem.attributes.map(a => a.product_attribute.name + ': ' + a.option.name).join(', ')}}
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'short-product-teaser',
        props: {
            orderItem: { type: Object }
        }
    }
</script>
