<template>
    <div>
        <h2>
            Foto's
        </h2>
        <div class="documents">
            <div v-for="(document, index) in order.documents" class="document">
                <div v-if="document.is_image" class="document-box document-box--image">
                    <img loading="lazy" @click="documentIndex = index" class="pointer"
                         :src="'/documents/' + document.id" :alt="document.filename">
                </div>
                <div v-if="document.is_inline && !document.is_image" class="document-box document-box--file">
                    <a @click="documentIndex = index" href="#" class="document-link">
                        <div class="document-link-icon"><i class="fal fa-eye"></i></div>
                        <div class="document-link-title">{{ document.filename }}</div>
                    </a>
                </div>
                <div v-if="!document.is_inline && !document.is_image" class="document-box document-box--file">
                    <a :href="'/documents/' + document.id" class="document-link">
                        <div class="document-link-icon"><i class="fal fa-download"></i></div>
                        <div class="document-link-title">{{ document.filename }}</div>
                    </a>
                </div>
            </div>
            <div class="document-add" @click="$refs.fileInput.click()"><i class="fal fa-plus-circle"></i></div>
        </div>
        <label>
            <input class="d-none" multiple type="file" id="file" ref="fileInput" v-on:change="handleFileUpload()"/>
        </label>

        <div v-for="(batch, uuid) in order.pickup_pictures_batches">
            <h6>Batch {{ batch[0].date_formatted }}</h6>
            <div class="documents">
                <div v-for="(image, i) in batch" class="document">
                    <div class="document-box document-box--image pointer">
                        <img loading="lazy" @click="onImageClick(uuid, i)" :src="'/documents/' + image.document.id"
                             :alt="image.document.filename">
                    </div>
                </div>
            </div>

            <div class="alert alert--success mb-4 text-left" v-if="batch[0].status === 'approved'">
                <i class="fa fa-fw fa-check"></i>
                Foto's goedgekeurd door {{ batch[0].reviewed_by_formatted }}
                <p class="small">op {{ batch[0].reviewed_at_formatted }}</p>
            </div>
            <div class="alert alert--danger mb-4 text-left" v-if="batch[0].status === 'denied'">
                <i class="fa fa-fw fa-times-hexagon"></i>
                Foto's afgekeurd door {{ batch[0].reviewed_by_formatted }}<p v-if="batch[0].reason" class="small mb-1">
                Reden: {{
                    batch[0].reason
                }}</p>
                <p class="small">op {{ batch[0].reviewed_at_formatted }}</p>
            </div>


            <div class="btn btn-success mb-2 w-100 btn-block"
                 v-if="batch[0].status === 'provided'" @click="showSendRequestModal(uuid)">
                <i class="far fa-paper-plane"></i>&nbsp;Goedkeuren & ophaalverzoek verzenden
            </div>

            <div class="d-flex w-100">
                <div class="btn btn-primary mb-2 w-100"
                     v-if="batch[0].status === 'provided'" @click="approvePictures(uuid)">
                    <i class="far fa-check-circle"></i>&nbsp;Goedkeuren
                </div>
                <div class="btn btn-danger mb-2 w-100 ml-2"
                     v-if="batch[0].status === 'provided'"
                     @click="showDenyPhotosModal(uuid)">
                    <i class="far fa-times"></i>&nbsp;Afkeuren
                </div>
            </div>

        </div>

        <CoolLightBox :items="getItems()"
                      :index="index"
                      :useZoomBar="true"
                      :slideshow="false"
                      @close="index = null"/>
        <CoolLightBox :items="getDocuments()"
                      :index="documentIndex"
                      :useZoomBar="true"
                      :slideshow="false"
                      @close="documentIndex = null"/>

        <deny-photos-modal ref="photosDeniedModal" @onClose="closeDenyPhotosModal()"
                           :show="showPhotosDeniedModal" :uuid="selectedBatchId"></deny-photos-modal>

        <send-request-from-photos-modal ref="sendRequestFromPhotosModal" @onClose="closeSendRequestModal()"
                           :show="showSendRequestFromPhotosModal" :uuid="selectedBatchId"></send-request-from-photos-modal>
    </div>
</template>

<script>

import ExtraCostDetails from "./ExtraCostDetails";
import Modal from "../Modal";
import DenyPhotosModal from "./DenyPhotosModal";
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import SendRequestFromPhotosModal from "./sendRequestFromPhotosModal.vue";

export default {
    name: "DocumentDetails",
    components: {SendRequestFromPhotosModal, Modal, DenyPhotosModal, ExtraCostDetails, CoolLightBox},

    data: () => ({
        showPhotosDeniedModal: false,
        showSendRequestFromPhotosModal: false,
        selectedBatchId: null,
        index: null,
        selectedUuid: null,
        documentIndex: null,
    }),
    computed: {
        order() {
            return this.$store.state.order;
        }
    },
    methods: {
        showDenyPhotosModal(batchId) {
            this.showPhotosDeniedModal = true
            this.selectedBatchId = batchId
        },
        closeDenyPhotosModal() {
            this.showPhotosDeniedModal = false
            this.selectedBatchId = null
        },
        showSendRequestModal(batchId) {
            this.showSendRequestFromPhotosModal = true
            this.selectedBatchId = batchId
        },
        closeSendRequestModal() {
            this.showSendRequestFromPhotosModal = false
            this.selectedBatchId = null
        },
        approvePictures(batchId, sendRequest = false) {
            this.$store.dispatch('approvePickupPictures', {
                batch_id: batchId,
                sendRequest: sendRequest,
            });
        },
        handleFileUpload() {
            let files = Array.from(this.$refs.fileInput.files);
            let formData = new FormData();
            files.forEach((file, index) => {
                formData.append('files[]', file);
            })
            this.$store.dispatch('uploadFiles', formData).then(() => {
                this.$refs.fileInput.type = ''
                this.$refs.fileInput.type = 'file'
            });
        },
        onImageClick(uuid, index) {
            this.index = index;
            this.selectedUuid = uuid;
        },
        getItems() {
            for (const uuid in this.order.pickup_pictures_batches) {
                if (uuid === this.selectedUuid) {
                    return this.order.pickup_pictures_batches[uuid].map(item => '/documents/' + item.document.id)
                }
            }
            return [];
        },
        getDocuments() {
            return this.order.documents.map(document => {
                return {
                    title: document.filename,
                    src: '/documents/' + document.id,
                    mediaType: document.is_inline && !document.is_image ? 'iframe' : 'image'
                }
            })
        }
    }
}
</script>
