<template>
  <div v-if="!$store.state.order.webshop" class="box">
    <div class="box-header">
      <h1>Selecteer een webshop</h1>
    </div>
    <div class="box-content">
      <div class="webshops">
        <label class="webshop" v-for="option in webshops">
          <input type="radio" :id="option.id" :value="option.id" v-model="selectedWebshopId">
          <div for="option.id" class="webshop-box">
            <div class="webshop-image">
              <img :src="option.image_url"/>
            </div>
            <div class="webshop-name">
              {{ option.name }}
            </div>
          </div>
        </label>
      </div>
    </div>
    <div class="box-actions">
      <a class="btn btn-primary" href="#" @click="save">Volgende <i class="far fa-arrow-circle-right"></i></a>
    </div>
  </div>
</template>

<script>

export default {
  name: "WebshopForm",
  props: ['webshops'],

  data: () => ({
    editing: false,
    selectedWebshopId: '',
    selectedWebshop: null,
  }),

  mounted() {
    if (!this.$store.state.order.webshop) {
      this.editing = true
    }
  },
  watch: {
    selectedWebshopId: function (val) {
      if (!val) {
        return alert('Selecteer een webshop!');
      }
      this.selectedWebshop = this.webshops.find(w => w.id === parseInt(val));
      this.$store.dispatch('getPaymentMethodsForWebshop', {
        webshop_id: this.selectedWebshopId,
      });
      this.$store.commit('setWebshop', {
        webshop: this.selectedWebshop,
      });
    }
  },

  methods: {
    save() {
      if (!this.selectedWebshop) {
        return alert('Selecteer een webshop!');
      }
      this.$store.commit('setWebshop', {
        webshop: this.selectedWebshop,
      });
    }
  }
}
</script>
