<template>
    <modal ref="modal" :show="$store.state.cancelPickup" @onClose="close">
        <template #title>
            <span >Ophaling annuleren</span>
        </template>

        <div>
            <order-item-teaser v-if="order.id" :with-customer="true" :order="order" :order-item="Object.assign({}, orderItem)"></order-item-teaser>
        </div>

        <div>
            <div class="alert alert--warning">
                <b>Door deze actie zullen de volgende dingen in gang worden gezet.</b>
                <ol>
                    <li>Ophaalaanvragen zullen worden geannuleerd.</li>
                    <li>Als er een definitieve leverdatum bekend is, wordt deze verwijderd.</li>
                    <li>Tijdsindicaties worden op onbekend gezet.</li>
                    <li>Een nieuwe mutatie (ophaaldatum geannuleerd) wordt gemaakt.</li>
                    <li>De klant en leverancier (indien gekoppeld) ontvangt een email.</li>
                </ol>
            </div>
        </div>

        <template #footer>
            <div>
                <button type="button" @click="close" class="btn btn-default">
                    Sluiten
                </button>
                <button type="button" @click="save" class="btn btn-primary">
                    <span>Ophalen annuleren</span>
                </button>
            </div>
        </template>
    </modal>
</template>

<script>

    import Modal from "./Modal";
    import OrderItemTeaser from "./OrderItemTeaser";
    import FormControl from "./FormControl";

    export default {
        name: "CancelPickupForm",
        components: {
            FormControl,
            OrderItemTeaser,
            Modal
        },

        data: () => ({
            loading: false,
        }),
        computed:{
            orderItem(){
                return this.order.order_items[0];
            },
            order(){
                return this.$store.state.order;
            }
        },

        methods: {
            close() {
                this.$store.dispatch('closeCancelPickup');
            },
            save(){
                this.$store.dispatch('cancelPickup').then(() => {
                    this.close();
                });
            }
        },
    }
</script>
