export default {
	methods: {
		formatTimestamp (string) {
			const parts = string.split(':');

			if (parts.length  < 3) return string;

			return parts[0] + ':' + parts[1]
		}
	}
}