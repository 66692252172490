<template>
    <div class="order-period" v-if="orderItem">
        <div class="order-period-label"><span><div class="order-period-label-icon"></div>Huurperiode<div class="order-period-label-icon"></div></span></div>
        <div class="order-period-progress">
	        <div class="order-period-progress-bar" :style="'width: '+orderItem.rent_used_percentage+'%; background: ' + (orderItem.rent_used_percentage === 100 ? '#E53925' : '#B4F8D6')"></div>
	        <div class="order-period-progress-indicator" :style="'left: '+orderItem.rent_used_percentage+'%;'"></div>
        </div>
        <div class="order-period-progress-labels">
	        <div class="order-period-progress-label">
		        <span>{{order.delivery_at_formatted}}</span>
	        </div>
	        <div class="order-period-progress-label">
		        <span>{{orderItem.rent_free_ends_at_formatted}}</span>
	        </div>
        </div>
        <div class="order-period-product">
	        <div v-if="orderItem.product.image_url" class="tag tag--withImage"><div class="tag-image" :style="'background-image: url(' + orderItem.product.image_url + ')'"></div></div>&nbsp;

	        <div class="box-product-name">
	            {{orderItem.product.name}}
	            <div class="box-product-type">
	                {{orderItem.rent_free_diff}}
	            </div>
	        </div>
        </div>
    </div>
</template>

<script>
    import ProductTeaser from "./ProductTeaser";
    export default {
        name: "RentalPeriodIndicator",
        components: { ProductTeaser },
        props: ['orderItem'],
        computed:{
            order() {
                return this.$store.state.order;
            }
        }
    }
</script>
