<template>
    <modal ref="modal" :show="$store.state.overrulingPickup" @onClose="close" :saveDisabled="false">
        <template #title>
            <span >Ophaling overschijven</span>
        </template>

        <div>
            <order-item-teaser v-if="order.id" :with-customer="true" :order="order" :order-item="Object.assign({}, orderItem)"></order-item-teaser>
        </div>

        <div>
            <div class="alert alert--orange">
                <strong>

                    <i class="fa fa-exclamation-triangle"></i> Let op, er zit geen enkele validatie in deze functie.
                </strong>
                <br>
                De ingevulde gegevens zullen zonder validatie of mail worden overgenomen op de order.
            </div>
        </div>
        <div v-if="!loading">

            <div class="form-row w-100">
                <div class="form-item w-100">
                    <label class="control-label">Ophaaldatum</label>
                    <input v-model="pickupAt" type="text" class="js-datepicker form-control">
                </div>
            </div>

        </div>
        <div v-else class="col-4 d-flex justify-content-center align-items-center">
            <div><i class="fa text-info fa-spinner fa-spin fa-2x"></i></div>
        </div>
        <div>
            <div class="alert alert--warning">
                <b>Door deze actie zullen de volgende dingen in gang worden gezet.</b>
                <ol>
                    <li>Ophaalaanvragen zullen worden verwijderd.</li>
                    <li>Alle openstaande mutaties worden gemarkeerd als gelezen.</li>
                    <li>Alle taken van het type Leverancier mutatie zullen worden gecompleted.</li>
                </ol>
                <b>Wanneer er een achteraf factuur is zal het volgende gebeuren:</b>
                <ol>
                    <li>Als de ophaaldatum in het verleden valt zal de factuur definitief gemaakt worden.</li>
                    <li>Als de ophaaldatum in de toekomst valt zal de factuur ingepland worden om als definitief gemarkeerd te worden.</li>
                </ol>
            </div>
        </div>

        <template #footer>
            <div>
                <button type="button" @click="close" class="btn btn-default">
                    Annuleren
                </button>
                <button :disabled="pickupAt == null" type="button" @click="save" class="btn btn-primary">
                    <span>Wijzigingen doorvoeren</span>
                </button>
            </div>
        </template>
    </modal>
</template>

<script>

    import Modal from "./Modal";
    import OrderItemTeaser from "./OrderItemTeaser";
    import FormControl from "./FormControl";

    export default {
        name: "OverrulePickupForm",
        components: {
            FormControl,
            OrderItemTeaser,
            Modal
        },

        data: () => ({
            pickupAt: null,
            loading: false,
        }),
        computed:{
            orderItem(){
                return this.order.order_items[0];
            },
            order(){
                return this.$store.state.order;
            },
        },

        methods: {
            close() {
                this.pickupAt = null;
                this.$store.dispatch('closeOverrule');
            },
            save(){
                this.$store.dispatch('overrulePickup', {
                    pickup_at: this.pickupAt,
                }).then(() => {
                    this.close();
                });
            }
        },
    }
</script>
