<template>
    <div class="rrule-setup-day">
        <div class="rrule-setup-day-label">Op</div>
        <div class="rrule-setup-day-control">
            <div class="btn-group">
                <button v-for="day in $parent.getDays()" :class="$parent.buttonClasses(day.abbr)" type="button" @click="toggleByDay(day.abbr)">
                    {{ day.short }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'week-form',
        props: ['disabled'],

        methods: {
            toggleByDay(day) {
                if (this.disabled) {
                    return;
                }
                let index = this.$parent.byDay.indexOf(day)

                if (index > -1) {
                    return this.$parent.byDay.splice(index, 1)
                }

                this.$parent.byDay.push(day)
            },
        }
    }
</script>
