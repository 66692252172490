<template>
    <div v-show="$store.state.order.id && $store.getters.isEditable && $store.state.order.order_status_id == 'concept'">
        <div>
            <a @click="deleteConceptOrder" class="btn-danger btn">
                Order annuleren
                <i class="fal fa-ban"></i>
            </a>
        </div>
    </div>

</template>

<script>

    import Modal from "./Modal";
    import ExtraCostForm from "./Exceptions/ExtraCostForm";

    export default {
        name: "DeleteConceptOrderForm",
        props: [],
        data: () => ({
            loading: false,
        }),

        mounted() {

        },
        watch: {},
        methods: {
            deleteConceptOrder() {
                this.loading = true;
                this.$store.dispatch('deleteConceptOrder').then(() => {
                    this.loading = false;
                });
            },
        }

    }
</script>
