<template>
    <div>
        <div class="box-header">
            <h2 class="box-title">
                Bijzonderheden
                <div class="badge">
                    {{order.extra_costs_by_date.length}}
                </div>
            </h2>

            <div class="box-header-tools">
                <div @click="showModal = true" class="btn btn-link btn-link--blue pointer" ><span>Aanpassen</span> <i class="fal fa-chevron-right"></i></div>
            </div>

        </div>
        <div class="box-content">
	        <div class="notifications">
		        <div v-for="(extraCost, i) in order.extra_costs_by_date.slice(0,2)">
		            <extra-cost-details :extra-cost="extraCost" :key="i"></extra-cost-details>
		        </div>
	        </div>
            <document-details v-if="order.id"></document-details>
            <button @click="showExtraCostsModal = true" type="button" class="btn w-100 btn-primary">Bekijk alle bijzonderheden <i class="fa fa-comments-dollar"></i></button>
        </div>
        <modal @onClose="onClose" @onSave="onSave" :show="showModal">
            <template v-slot:title>
                Bijzonderheden
            </template>
            <template v-slot>
                <order-item-teaser v-if="order.order_items.length" :with-customer="true" :order="order" :order-item="Object.assign({}, order.order_items[0])"></order-item-teaser>

                <div v-if="!loading">
                    <extra-cost-form ref="extraCostElement"></extra-cost-form>
                </div>
                <div v-else class="col-4 d-flex justify-content-center align-items-center">
                    <div><i class="fa text-info fa-spinner fa-spin fa-2x"></i></div>
                </div>
                <document-details></document-details>
            </template>
        </modal>
        <modal @onClose="onExtraCostsClose" :show="showExtraCostsModal" :hide-save="true">
            <template v-slot:title>
                Bijzonderheden
            </template>
            <template v-slot>
                <div class="notifications">
                    <div v-for="(extraCost, i) in order.extra_costs_by_date">
                        <extra-cost-details :extra-cost="extraCost" :key="i"></extra-cost-details>
                    </div>
                </div>
                <document-details v-if="order.id"></document-details>
            </template>
        </modal>
    </div>
</template>

<script>

    import FormControl from "../FormControl";
    import ProductDetails from "../ProductDetails";
    import ExtraCostDetails from "./ExtraCostDetails";
    import Modal from "../Modal";
    import ExtraCostForm from "./ExtraCostForm";
    import OrderItemTeaser from "../OrderItemTeaser";
    import DocumentDetails from "./DocumentDetails";

    export default {
        name: "ExceptionDetails",
        components: { DocumentDetails, OrderItemTeaser, ExtraCostForm, Modal, ExtraCostDetails, ProductDetails, FormControl },

        data: () => ({
            showModal: false,
            showExtraCostsModal: false,
            loading: false,
        }),
        computed: {
            order(){
                return this.$store.state.order;
            }
        },

        methods: {
            onClose() {
                this.showModal = false;
            },
            onExtraCostsClose(){
                this.showExtraCostsModal = false;
            },
            onSave() {
                this.loading = true;
                this.$refs.extraCostElement.save().then(() => {
                    this.loading = false;
                    this.showModal = false;
                }).catch(err => {
                    this.loading = false;
                })
            }
        }

    }
</script>
