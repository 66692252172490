<template>
    <div>
        <a :data-modal="'/suppliers/'+supplier.id+'/modal'" data-toggle="tooltip"
           data-title="Klik om naar deze leverancier te gaan" @click="closeParentModal()">
        <span>{{ location.name }}</span>
        <span v-if="supplier.oms_type">{{ supplier.oms_type }}</span>
        </a>
            <i class="far fa-external-link-square" v-if="supplier.sync_orders_with_oms === true" data-toggle="tooltip"
               :data-title="supplier.oms_integration"></i>
    </div>
</template>

<script>
import EventBus from "../../../../../eventbus";

export default {
    name: "LocationName",
    props: ['location', 'supplier'],
    methods: {
        closeParentModal() {
            EventBus.$emit('globalCloseModals');
        }
    }

}
</script>
