<template>
    <div>

        <div class="row">
            <div class="col-12" v-if="success">
                <div class="alert alert-success">
                    <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
                    <i class="icon fa fa-ban"></i> <span @click="reload">Gegevens opgeslagen, klik hier om de pagina te verversen.</span>
                </div>
            </div>
        </div>
        <div class="row" v-if="!saving && show">


            <div class="col-6">
                <div v-for="productGroup in productGroups" :key="productGroup.id">
                    <label :for="'#product-group-' + productGroup.id" v-text="productGroup.name"></label>
                    <select :data-group="productGroup.id" multiple class="select2 js-group-day-select form-control">
                        <option :value="day.id" v-for="day in days"
                                :selected="productGroup.selected.findIndex(d => d == day.id) !== -1"
                                v-text="day.name"></option>
                    </select>
                </div>
            </div>
            <div class="col-6 flex-column justify-content-between">
                <div>
                    <div>
                        <div v-if="validated === false" class="alert alert-danger">
                            <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
                            <i class="icon fa fa-ban"></i> Ongeldige invoer
                        </div>
                        <div class="form-row row mb-2">
                            <div class="col-3 align-items-center">
                                <input :disabled="validated" :maxlength="4" class="form-control" placeholder="Van"
                                       type="text"
                                       v-model="from_zip_code">
                            </div>

                            <div class="col-3 align-items-center">
                                <input :disabled="validated" :maxlength="4" class="form-control"
                                       placeholder="t/m postcode"
                                       type="text"
                                       v-model="till_zip_code">
                            </div>
                            <div class="col-6">
                                <button :disabled="validated" type="button" @click="validateInput"
                                        class="btn w-100 btn-primary">
                                    Stel postcodereeks in
                                </button>
                            </div>
                        </div>
                        <div class="form-row row mb-2">
                            <div class="col-6 align-items-center">
                                <select :disabled="validated" class="select2 js-municipality-select form-control">
                                    <option>Selecteer een gemeente</option>
                                    <option v-for="municipality in municipalities" :value="municipality.id"
                                            v-text="municipality.name" :key="municipality.id"></option>
                                </select>
                            </div>

                            <div class="col-6">
                                <button :disabled="validated" type="button" @click="validateInput"
                                        class="btn w-100 btn-primary">
                                    Stel gemeente in
                                </button>
                            </div>
                        </div>

                        <div class="form-row row mb-2">
                            <div class="col-6 align-items-center">
                                <select :disabled="validated" class="js-city-select form-control">
                                    <option>Selecteer een plaats</option>
                                    <option v-for="city in cities" :value="city.id"
                                            v-text="city.text" :key="city.id"></option>
                                </select>
                            </div>

                            <div class="col-6">
                                <button :disabled="validated" type="button" @click="validateInput"
                                        class="btn w-100 btn-primary">
                                    Stel plaats in
                                </button>
                            </div>
                        </div>
                        <hr>
                        <div class="form-row row mb-2">
                            <div class="col-6 align-items-center">
                                <select :disabled="!validated" class="select2 js-supplier form-control">
                                    <option>Selecteer een leverancier</option>
                                    <option v-for="supplier in suppliers" :value="supplier.id"
                                            v-text="supplier.name" :key="supplier.id"></option>
                                </select>
                            </div>

                            <div class="col-6">
                                <button :disabled="!validated" type="button" @click="addSupplier"
                                        class="btn w-100 btn-primary">
                                    Leverancier toevoegen
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="justify-content-end d-flex flex-fill">
                        <div class="mt-5">
                            <button type="button" @click="save" class="btn btn-primary">Opslaan</button>
                        </div>
                    </div>

                </div>

            </div>

            <div v-if="validated" class="col-12 mt-4">
                <h1 v-text="getTitleFormatted()"></h1>

                <table class="table table-striped w-100">
                    <thead>
                    <tr>
                        <th :width="calculateWidth()+ '%'">
                            Leverancier
                        </th>
                        <th :width="calculateWidth()+ '%'" v-for="group in productGroups"
                            v-text="group.name"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="supplier in selectedSuppliers" :key="'supplier-tr-'+supplier.id">
                        <td :width="calculateWidth()+ '%'" v-text="supplier.name"></td>
                        <td :width="calculateWidth()+ '%'"
                            v-for="group in supplier.productGroups">
                            <template>

                                <select :data-supplier="supplier.id" :data-group="group.id" multiple
                                        :class="'form-control js-day-select-' + supplier.id">
                                    <option :value="day.id" v-for="day in days"
                                            :selected="group.selected.findIndex(d => d == day.id) !== -1"
                                            v-text="day.short"
                                            :key="day.id + supplier.id"></option>
                                </select>
                            </template>
                        </td>
                    </tr>
                    </tbody>

                </table>

            </div>

        </div>
        <div v-if="saving" class="d-flex align-items-center justify-content-center">
            <h4>
                Aan het opslaan...
            </h4>
            <div>&nbsp;<i class="fas fa-3x fa-spin fa-spinner"></i></div>
        </div>
    </div>

</template>

<script>
    export default {
        name: "MassInsertOperatingDays",
        props: ['productGroupProps', 'municipalities', 'suppliers'],
        data: () => ({
            productGroups: [],
            selectedSuppliers: [],
            cities: [],
            from_zip_code: null,
            till_zip_code: null,
            municipality_id: null,
            city_id: null,
            supplier: null,
            validated: null,
            saving: false,
            success: false,
            show: true,
            days: [
                {
                    id: 1,
                    englishName: 'monday',
                    name: 'Maandag',
                    short: 'ma',
                },
                {
                    id: 2,
                    englishName: 'tuesday',
                    name: 'Dinsdag',
                    short: 'di',
                },
                {
                    id: 3,
                    englishName: 'wednesday',
                    name: 'Woensdag',
                    short: 'wo',
                },
                {
                    id: 4,
                    englishName: 'thursday',
                    name: 'Donderdag',
                    short: 'do',
                },
                {
                    id: 5,
                    englishName: 'friday',
                    name: 'Vrijdag',
                    short: 'vr',
                },
                {
                    id: 6,
                    englishName: 'saturday',
                    name: 'Zaterdag',
                    short: 'za',
                },
                {
                    id: 0,
                    englishName: 'sunday',
                    name: 'Zondag',
                    short: 'zo',
                },
            ]
        }),


        methods: {
            calculateWidth() {
                return 100 / (this.productGroups.length + 1);
            },
            addSupplier() {
                this.selectedSuppliers.push({
                    id: this.supplier.id,
                    name: this.supplier.name,
                    productGroups: Object.assign([], this.productGroups.map(group => Object.assign({}, group))),
                });

                Vue.nextTick(() => {
                    // DOM updated
                    let self = this;
                    $('.js-day-select-' + this.supplier.id).each(function () {
                        $(this).select2();
                        $(this).on('change', function () {
                            let supplierId = parseInt($(this).data('supplier'));
                            let groupId = parseInt($(this).data('group'));
                            self.selectedSuppliers = self.selectedSuppliers.map((supplier) => {
                                if (supplier.id === supplierId) {
                                    supplier.productGroups = supplier.productGroups.map(group => {
                                        if (group.id === groupId) {
                                            group.selected = $(this).val();
                                        }
                                        return group;
                                    })
                                }

                                return supplier;
                            })
                        });

                    });
                });
            },
            reload() {
                location.reload()
            },
            validateInput() {
                if (this.from_zip_code) {
                    if (this.from_zip_code && this.till_zip_code) {
                        this.validated = true;
                        return;
                    }
                    this.validated = true;
                    return;
                }
                if (this.municipality_id) {
                    this.validated = true;
                    return;
                }

                if (this.city_id) {
                    this.validated = true;
                    return;
                }


                this.validated = false;
            },
            getTitleFormatted() {
                if (this.from_zip_code && this.till_zip_code) {
                    return 'Postcodereeks ' + this.from_zip_code + ' - ' + this.till_zip_code
                }
                if (this.from_zip_code) {
                    return 'Postcode ' + this.from_zip_code
                }
                if (this.municipality_id) {
                    return 'Gemeente ' + this.municipalities.find(m => m.id === parseInt(this.municipality_id)).name
                }

                if (this.city_id) {
                    return 'Plaats ' + this.cities.find(m => m.id === parseInt(this.city_id)).text
                }
            },
            updateGroupValues(productGroupId, values) {
                this.productGroups = this.productGroups.map((group) => {
                    if (group.id == productGroupId) {
                        group.selected = values;
                    }
                    return group;
                })
            },
            save() {
                this.saving = true;
                return axios.post('/suppliers/operating-days', {
                    selected_suppliers: this.selectedSuppliers,
                    from_zip_code: this.from_zip_code,
                    till_zip_code: this.till_zip_code,
                    municipality_id: this.municipality_id,
                    city_id: this.city_id,
                }).then(res => {
                    this.saving = false;
                    this.show = false;
                    this.success = res.data.success;
                    return res;
                })
            }
        },


        mounted() {
            this.productGroups = this.productGroupProps.map(group => {
                group.selected = group.default_delivery_days.map(day => {
                    let i = this.days.findIndex(d => {
                        return d.englishName === day
                    });
                    return this.days[i].id
                });

                return group;
            });
            let self = this;

            Vue.nextTick(() => {
                $('.js-group-day-select').on('select2:select', function () {
                    let productGroupId = $(this).data('group');
                    let values = $(this).val();
                    self.updateGroupValues(productGroupId, values)
                }).on('select2:unselect', function () {
                    let productGroupId = $(this).data('group');
                    let values = $(this).val();
                    self.updateGroupValues(productGroupId, values)
                });

                $('.js-municipality-select').on('change', () => {
                    this.municipality_id = $('.js-municipality-select').val();
                });
                $('.js-city-select').select2({
                    dropdownParent: $('.js-city-select').closest('.form-row'),
                    width: '100%',
                    minimumInputLength: 2,
                    ajax: {
                        url: '/suppliers/operating-days/cities',
                        dataType: 'json',
                        data: (params) => {
                            return { search: params.term, type: 'public' };
                        },
                        processResults: (data) => {
                            let items = [];
                            data.data.forEach(item => {
                                items.push({ id: item.id, text: item.name })
                            });

                            this.cities = items;

                            return { results: items }
                        }
                    }
                }).on('change', () => {
                    this.city_id = $('.js-city-select').val();
                });

                $('.js-supplier').on('change', () => {
                    let id = parseInt($('.js-supplier').val());
                    this.supplier = Object.assign({}, this.suppliers.find(s => s.id == id));
                })
            })
        },
    }
</script>
