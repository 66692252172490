<template>
    <div class="summary">
        <div class="summary-item" v-if="withCustomer && order.customer">
            <div class="tag tag--withImage tag--square tag--withImageFull">
                <div class="tag-image"
                     :style="'background-image: url(' + order.webshop.image_url + ')'"></div>
            </div>
            <div class="summary-item-title">
                {{order.customer.company_name}}
                <div class="summary-item-subtitle">
                    {{order.customer.full_name}}
                </div>
            </div>
        </div>
        <short-product-teaser v-if="orderItem" :order-item="orderItem"/>
        <address-teaser :address="order.delivery_address"></address-teaser>
        <div class="summary-item">
            <div class="tag tag--square tag--withIcon">
                <div class="tag-icon tag-icon--euro"></div>
            </div>
            <div class="summary-item-title noWrap">
                {{orderItem.total_price_excl | format_money}}
                <div class="summary-item-subtitle noWrap">
                    {{orderItem.total_price_incl | format_money}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ShortProductTeaser from "./ShortProductTeaser";
    import AddressTeaser from "./AddressTeaser";

    export default {
        name: "OrderItemTeaser",
        components: { AddressTeaser, ShortProductTeaser },
        props: ['orderItem', 'withCustomer'],
        computed: {
            order: function () {
                return this.$store.state.order;
            },
        }
    }
</script>
