<template>
    <div>
        <div v-if="order.id">
            <div class="form-row" v-if="linkManual">
                <div class="col">
                    <form-control class="form-item--compact" name="supplier_id" label="Leverancier" :edit="true">
                        <template v-slot:input>
                            <div class="form-item-select">
                                <dropdown-select class="form-view"
                                                 @onSelect="onLocationSelect"
                                                 :preload="false"
                                                 :itemCallback="(item) => ({
                                                    id: item.id,
                                                    value: item.name,
                                                 })"
                                                 url="/company/api/sales/orders/supplier/locations">
                                </dropdown-select>
                            </div>
                        </template>
                    </form-control>
                </div>
                <div class="col">
                    <form-control class="form-item--compact" name="placement_costs_excl" label="Leverkosten Excl. BTW" :edit="true">
                        <template v-slot:input>
                            <input v-model="placementExcl" type="text" class="form-control">
                        </template>
                    </form-control>
                </div>
                <div class="col">
                    <form-control class="form-item--compact" name="pickup_costs_excl" label="Ophaalkosten Excl. BTW" :edit="true">
                        <template v-slot:input>
                            <input v-model="pickupExcl" type="text" class="form-control">
                        </template>
                    </form-control>
                </div>
                <div class="col">
                    <form-control class="form-item--compact" name="pickup_costs_excl" label="Wekelijkse huurprijs Excl. BTW" :edit="true">
                        <template v-slot:input>
                            <input v-model="rentExcl" type="text" class="form-control">
                        </template>
                    </form-control>
                </div>

            </div>
            <div class="row">
                <div class="col">
                    <button v-if="!linkManual" @click="linkManual = true" class="btn w-100 btn-primary">Andere leverancier aanvragen</button>
                    <button v-if="linkManual" @click="linkManual = false" class="btn w-100 btn-secondary">Annuleren</button>
                </div>
                <div class="col" v-if="linkManual">
                    <button type="button" :disabled="disabledClass !== ''" @click="saveRequest" :class="'btn w-100 btn-primary ' + disabledClass">Aanvraag opslaan</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import FormControl from "../FormControl";

export default {
    name: "ManualRentSupplierForm",
    components: { FormControl },

    data: () => ({
        drag: false,
        locations: [],
        rentExcl: '',
        placementExcl: '',
        pickupExcl: '',
        supplier: null,
        location: null,
        linkManual: false,
    }),
    computed: {
        order() {
            return this.$store.state.order;
        },
        disabledClass() {
            if (!this.location) {
                return 'disabled';
            }
            if (this.rentExcl === '') {
                return 'disabled';
            }
            if (this.pickupExcl === '') {
                return 'disabled';
            }
            if (this.placementExcl === '') {
                return 'disabled';
            }
            return '';
        }
    },

    methods: {
        onLocationSelect(value) {
            this.location = value;
        },
        saveRequest() {
            this.$store.dispatch('saveManualRentSupplier', {
                location: this.location,
                pickup_costs_excl: this.pickupExcl,
                placement_costs_excl: this.placementExcl,
                rent_excl: this.rentExcl,
                delivery_at: this.$store.state.order.preferred_delivery_at,
            }).then(() => {
                this.linkManual = false;
            })
        },
    }
}
</script>
