<template>
    <a style="color: inherit" class="text-decoration-none js-outbound-call" :href="'tel:' + number" :data-number="number" :data-webshop="webshop ? webshop.id : ''">
        <slot></slot>
    </a>
</template>

<script>
    export default {
        name: "OutboundCallButton",
        props: ['number', 'webshop'],
    }
</script>
