import outsourceActions from "./outsourceActions";

export default {
    onError({commit, state}, err) {
        if (err && err.response.data && err.response.data.errors) {
            let errors = err.response.data.errors;
            commit('setErrors', errors);
            console.log(errors);
            toastr.error('Er is iets foutief ingevuld');
        } else {
            toastr.error(err.response.data.message);
        }
        throw err;
    },
    requestTimeIndication({dispatch, state}) {
        if (state.order.id) {
            return axios.post('/company/api/sales/orders/' + state.order.id + '/supplier/request-time-indication').then(res => {
                window.triggerAlert('Tijdsindicatie verzonden.');
                return true;
            }).catch(err => dispatch('onError', err))
        }
    },
    unlinkSupplier({commit, state, dispatch}) {
        commit('setSupplier', {
            supplier: null,
        });

        if (state.order.id) {
            return axios.post('/company/api/sales/orders/' + state.order.id + '/supplier/unlink').then(res => {
                commit('setOrder', res.data.order);
                window.triggerAlert('Leverancier ontkoppeld');
                return true;
            }).catch(err => dispatch('onError', err))
        }
    },
    updateSupplierRequests({commit, state, dispatch}, { requests, send }) {
        commit('updateSupplierRequests', requests);

        if (state.order.id) {
            let newData = {
                //reduce the data sent to the server because it's a lot otherwise.
                supplier_requests: requests.map(request => ({
                    id: request.id,
                    supplier_id: request.supplier.id,
                    location_id: request.location.id,
                    delivery_at: request.delivery_at,
                    should_send: request.should_send,
                })),
                send_supplier_requests: send,
            };
            return axios.post('/company/api/sales/orders/' + state.order.id + '/supplier-requests', newData).then(res => {
                commit('setOrder', res.data.order);
                window.triggerAlert();
                return true;
            }).catch(err => dispatch('onError', err))
        }
    },
    uploadFiles({commit, state, dispatch}, formData) {
        return axios.post('/company/api/sales/orders/' + state.order.id + '/documents', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(res => {
            commit('setOrder', res.data.order);
            window.triggerAlert('Bestanden toegevoegd');
        }).catch(err => dispatch('onError', err))
    },
    sendPickupRequest({commit, state, dispatch}, {date}) {
        return axios.post('/company/api/sales/orders/' + state.order.id + '/pickup-requests', {
            date,
        }).then(res => {
            commit('setOrder', res.data.order);
            window.triggerAlert('Aanvraag verzonden!');
        }).catch(err => dispatch('onError', err))
    },
    showProductSelection({commit, state, dispatch}, payload) {
        state.isEditingOrderItem = true;
        if (payload && payload.order_item) {
            state.editingOrderItem = Object.assign({}, payload.order_item);
        }
    },
    saveOrderItem({commit, state, dispatch}, payload) {
        return axios.post('/company/api/sales/order/order-items', {
            ...payload,
            customer_id: state.order.customer.id,
            date: state.order.delivery_at ? state.order.delivery_at : state.order.preferred_delivery_at,
        }).then((res) => {
            commit('addOrderItems', res.data.data);

            if (state.order.id) {
                return axios.post('/company/api/sales/orders/' + state.order.id + '/products', {
                    order_items: state.order.order_items,
                }).then(res => {
                    commit('setOrder', res.data.order);
                    return res;
                }).catch(err => {
                    dispatch('onError', err)
                    return false;
                });
            }
            return true;
        })
    },
    saveInvoicedOrderItem({commit, state, dispatch}, payload) {
        return axios.post(`/company/api/sales/orders/${state.order.id}/${payload.order_item_id}/change-order-item`, {
            id: payload.order_item_id,
            product_id: payload.product_id,
            attributes: payload.attributes,
            quantity: payload.quantity,
            changed_product: payload.changed_product,
            changed_option: payload.changed_option,
            custom_price_incl: payload.custom_price_incl,
            custom_price_reason: payload.custom_price_reason,
            custom_price_type_id: payload.custom_price_type_id,
            has_variation_discount: payload.has_variation_discount,
        }).then(res => {
            commit('setOrder', res.data.order);
            window.triggerAlert();
            return true;
        }).catch(err => dispatch('onError', err))
    },
    validate({commit, state, dispatch}, payload) {
        return axios.post('/company/api/sales/orders/validate', payload)
            .catch(err => dispatch('onError', err))
            .then((res) => {
                state.errors = {};
                return res.data.success;
            })
    },
    getPriceVariations({ commit, state, dispatch }, {attributes, product_id, address_id}){
        return axios.post('/company/api/sales/price-variations', {
            customer_id: state.order.customer.id,
            attributes,
            product_id,
            address_id,
            webshop_id: state.order.webshop.id,
        }).then(res => {
            return res.data;
        }).catch(err => dispatch('onError', err))
    },
    acceptSupplierRequest({commit, state, dispatch}, supplierRequest) {
        commit('setSupplier', {
            supplier: supplierRequest.supplier,
        });

        if (state.order.id) {
            return axios.post('/company/api/sales/orders/' + state.order.id + '/supplier-requests/' + supplierRequest.id).then(res => {
                commit('setOrder', res.data.order);
                window.triggerAlert();
            }).catch(err => dispatch('onError', err))
        }
    },

    cancelPickupRequest({commit, state, dispatch}, payload) {
        if (state.order.id) {
            return axios.post('/company/api/sales/orders/' + state.order.id + '/pickup-requests/' + payload.request_id + '/cancel').then(res => {
                commit('setOrder', res.data.order);
                window.triggerAlert();

                return true;
            }).catch(err => dispatch('onError', err))
        }
    },
    setExtraCosts({commit, state, dispatch}, payload) {
        let data = {extra_costs: payload.extraCosts};
        return dispatch('validate', data).then(() => {
            commit('setExtraCosts', data);
        });
    },
    setGeneralData({commit, state, dispatch}, payload) {
        let data = {
            order_origin_id: payload.order_origin_id,
            payment_method_id: payload.payment_method_id,
            customer_id: payload.customer ? payload.customer.id : null,
            invoice_address_id: payload.invoice_address ? payload.invoice_address.id : null,
            phone_number_id: payload.phone_number ? payload.phone_number.id : null,
        };

        return dispatch('validate', data).then(async () => {
            commit('setOrderOrigin', payload.order_origin_id);
            commit('setCustomer', {
                customer: payload.customer,
                invoice_address: payload.invoice_address,
                phone_number: payload.phone_number,
            });

            if (payload.customer.id) {
                await dispatch('updateCustomer', payload.customer);
            }

            if (state.order.id) {
                return axios.post('/company/api/sales/orders/' + state.order.id + '/general-data', data).then(res => {
                    commit('setOrder', res.data.order);
                    window.triggerAlert();
                    return true;
                }).catch(err => dispatch('onError', err))
            }
        });
    },
    confirmOrder({commit, state, dispatch}) {
        state.disableOrderCreateButton = true;
        return axios.post('/company/api/sales/orders/' + state.order.id + '/confirm').then(res => {
            commit('setOrder', res.data.order);
            window.triggerAlert("Bestelling bevestigd");
            state.disableOrderCreateButton = false;

            return true;
        }).catch(err => {
            state.disableOrderCreateButton = false;
            dispatch('onError', err)
        })
    },
    showOverruleModal({commit, state, dispatch}) {
        state.overrulingPickup = true;
    },
    closeOverrule({commit, state, dispatch}) {
        state.overrulingPickup = false;
    },
    showCancelPickupModal({commit, state, dispatch}) {
        state.cancelPickup = true;
    },
    showRestartPurchaseInvoicing({commit, state, dispatch}) {
        state.restartPurchaseInvoicing = true;
    },
    closeRestartPurchaseInvoicing({commit, state, dispatch}) {
        state.restartPurchaseInvoicing = true;
    },
    closeCancelPickup({commit, state, dispatch}) {
        state.cancelPickup = false;
    },
    cancelCallId({commit, state, dispatch}) {
        console.log('dispatch');
        state.callId = null;
        commit('setCallId', null);
    },

    overrulePickup({commit, state, dispatch}, data) {
        return axios.post('/company/api/sales/orders/' + state.order.id + '/overrule-pickup', data).then(res => {
            commit('setOrder', res.data.order);
            window.triggerAlert("Ophaling overruled");

            return true;
        }).catch(err => dispatch('onError', err))
    },
    cancelPickup({commit, state, dispatch}) {
        return axios.post('/company/api/sales/orders/' + state.order.id + '/cancel-pickup').then(res => {
            commit('setOrder', res.data.order);
            window.triggerAlert("Ophaling geannuleerd");

            return true;
        }).catch(err => dispatch('onError', err))
    },
    restartPurchaseInvoicing({commit, state, dispatch}) {
        return axios.post('/company/api/sales/orders/' + state.order.id + '/restart-purchase-invoicing').then(res => {
            commit('setOrder', res.data.order);
            window.triggerAlert("Er is opnieuw gefactureerd");

            return true;
        }).catch(err => dispatch('onError', err))
    },
    triggerPurchaseInvoicing({commit, state, dispatch}) {
        return axios.post('/company/api/sales/orders/' + state.order.id + '/trigger-purchase-invoicing').then(res => {
            commit('setOrder', res.data.order);
            toastr.success('Facturatie inkoop is op de achtergrond gestart. Ververs over een paar seconden deze pagina.');

            return true;
        }).catch(err => dispatch('onError', err))
    },
    resendPaymentRequest({commit, state, dispatch}) {
        if (!state.order.id) {
            return;
        }
        bootbox.confirm(
            {
                message: "Weet je zeker dat je een betaalverzoek wil versturen?",
                buttons: {
                    confirm: {
                        label: 'Ja',
                    },
                    cancel: {
                        label: 'Annuleer',
                    }
                },
                callback: function (result) {
                    if (!result) {
                        return;
                    }
                    return axios.post('/company/api/sales/orders/' + state.order.id + '/resend-payment-request').then(res => {
                        window.triggerAlert("Betaalverzoek verstuurd.");

                        return true;
                    }).catch(err => dispatch('onError', err));
                }
            });


    },
    createOrder({commit, state, dispatch}, asConcept) {
        state.disableOrderCreateButton = true;
        state.isConcept = false;

        let data = {
            customer_id: state.order.customer ? state.order.customer.id : null,
            delivery_address_id: state.order.delivery_address ? state.order.delivery_address.id : null,
            invoice_address_id: state.order.invoice_address ? state.order.invoice_address.id : null,
            order_items: state.order.order_items,
            supplier_requests: state.order.supplier_requests,
            extra_costs: state.order.extra_costs,
            preferred_delivery_at: state.order.preferred_delivery_at,
            preferred_pickup_at: state.order.preferred_pickup_at,
            delivery_preference_id: state.order.delivery_preference_id,
            order_status_id: state.order.order_status_id,
            pickup_instructions: state.order.pickup_instructions,
            delivery_instructions: state.order.delivery_instructions,
            webshop_id: state.order.webshop ? state.order.webshop.id : null,
            payment_method_id: state.order.payment_method_id,
            order_origin_id: state.order.order_origin_id,
            project_reference: state.order.project_reference,
            notes: state.order.notes,
            call_id: state.callId,
            phone_number_id: state.order.phone_number ? state.order.phone_number.id : null,
            call_before_phone_number_id:  state.order.phone_number ? state.order.phone_number.id : null,
        };

        return dispatch('validate', data).then(() => {
            state.saving = true;
            if (!asConcept) {
                state.order.order_status_id = 'confirmed';
                data.order_status_id = state.order.order_status_id;
            }

            return axios.post('/company/api/sales/orders', data)
                .then((res) => {
                    window.location = res.data.redirect_url;
                }).catch(err => {
                    state.disableOrderCreateButton = false;
                    dispatch('onError', err)
                })
        });
    },
    cancelOrder({commit, state, dispatch}) {
        if (state.order.id) {
            return axios.post('/company/api/sales/orders/' + state.order.id + '/cancel').then(res => {
                commit('setOrder', res.data.order);
                window.triggerAlert("Bestelling geannuleerd");

                return true;
            }).catch(err => dispatch('onError', err))
        }
    },
    deleteConceptOrder({commit, state, dispatch}) {
        if (state.order.id) {
            return axios.delete('/company/api/sales/orders/' + state.order.id).then(res => {
                commit('setOrder', res.data.order);
                window.triggerAlert("Concept order geannuleerd");

                return true;
            }).catch(err => dispatch('onError', err))
        }
    },
    loadLinkedOrders({commit, state, dispatch}, deliveryAddress) {
        if (!deliveryAddress) {
            return;
        }
        return axios.get('/company/api/sales/addresses/' + deliveryAddress.id + '/linked-orders?except_order_id=' + state.order.id).then(res => {
            state.linkedOrders = res.data.data;
            return res.data.data;
        })
    },
    saveExtraCosts({commit, state, dispatch}, andInvoice) {
        let data = {
            extra_costs: state.order.extra_costs,
            invoice_costs: andInvoice
        };
        if (state.order.id) {
            return axios.post('/company/api/sales/orders/' + state.order.id + '/extra-costs', data).then(res => {
                commit('setOrder', res.data.order);
                window.triggerAlert("Bestelling geannuleerd");
                return true;
            }).catch(err => dispatch('onError', err))
        }
    },
    saveCustomer({dispatch, state, commit}, customer) {

        state.errors = {};
        return axios.post('/company/api/sales/orders/customers', customer).then((res) => {
            state.order.customer = res.data.data;

            return res.data.data;
        }).catch(err => dispatch('onError', err));
    },
    updateCustomer({dispatch, state, commit}, customer) {

        state.errors = {};
        return axios.post('/company/api/sales/orders/customers/' + customer.id, customer).then((res) => {
            state.order.customer = res.data.data;
            return res.data.data;
        }).catch(err => dispatch('onError', err));
    },
    setCustomer({commit, state, dispatch}, payload) {
        let data = {
            customer_id: payload.customer.id,
            delivery_address_id: payload.delivery_address ? payload.delivery_address.id : null,
            invoice_address_id: payload.invoice_address ? payload.invoice_address.id : null,
        };
        return dispatch('validate', data).then(() => {
            commit('setCustomer', payload);


            if (state.order.id) {
                return axios.post('/company/api/sales/orders/' + state.order.id + '/customer', data).then(res => {
                    commit('setOrder', res.data.order);
                    window.triggerAlert("Bestelling geannuleerd");
                    return true;
                }).catch(err => dispatch('onError', err))
            }
        });
    },
    setDeliveryInfo({commit, state, dispatch}, payload) {
        return dispatch('validate', payload).then(() => {
            commit('setDeliveryInfo', payload);
            if (state.order.id) {
                return axios.post('/company/api/sales/orders/' + state.order.id + '/delivery-info', payload).then(res => {
                    commit('setOrder', res.data.order);
                    return res.data.data
                }).catch(err => dispatch('onError', err))
            }

            return true;
        });
    },
    getPaymentMethodsForWebshop({commit, state, dispatch}, payload) {
        return axios.post('/company/api/webshops/payment-methods', payload).then(res => {
            commit('setPaymentMethods', res.data.data);
            return res.data.data
        }).catch(err => dispatch('onError', err));
    },
    updateSupplierNumber({commit, state, dispatch}, number) {
        if (state.order.id) {
            return axios.post('/company/api/sales/orders/' + state.order.id + '/supplier-nr', {
                number: number,
            }).then(res => {
                commit('setOrder', res.data.order);
                return res.data.data
            }).catch(err => dispatch('onError', err))
        }
    },
    swapOrder({commit, state, dispatch}, payload) {
        state.disableOrderCreateButton = true
        return axios.post('/company/api/sales/orders/' + state.order.id + '/swap', payload).then(res => {
            commit('setOrder', res.data.order);
            return res.data.data
        }).catch(err => dispatch('onError', err)).finally(() => {
            state.disableOrderCreateButton = false;
        })
    },
    updatePrio({commit, state, dispatch}, payload) {
        let prio = state.order.prio;
        if (prio === 3) {
            prio = 0;
        } else {
            prio++;
        }

        state.order.prio = prio;
        let prioClass = '';
        if (prio === 1) {
            prioClass = 'success';
        }

        if (prio === 2) {
            prioClass = 'warning';
        }

        if (prio === 3) {
            prioClass = 'danger';
        }
        state.order.prio_class = prioClass;

        if (state.order.id) {
            return axios.post('/company/api/sales/orders/' + state.order.id + '/change-prio', payload).then(res => {
                commit('setOrder', res.data.order);
                return res.data.data
            }).catch(err => dispatch('onError', err))
        }

    },
    saveManualSupplier({commit, state, dispatch}, payload) {
        return axios.post('/company/api/sales/orders/' + state.order.id + '/manual-supplier', {
            location_id: payload.location.id,
            price_excl: payload.price_excl,
            delivery_at: payload.delivery_at,
        }).then(res => {
            commit('setOrder', res.data.order);
            return res.data
        }).catch(err => dispatch('onError', err))
    },

    saveManualRentSupplier({commit, state, dispatch}, payload) {
        return axios.post('/company/api/sales/orders/' + state.order.id + '/manual-rent-supplier', {
            location_id: payload.location.id,
            rent_excl: payload.rent_excl,
            placement_costs_excl: payload.placement_costs_excl,
            pickup_costs_excl: payload.rent_excl,
            delivery_at: payload.delivery_at,
        }).then(res => {
            commit('setOrder', res.data.order);
            return res.data
        }).catch(err => dispatch('onError', err))
    },
    approvePickupPictures({commit, state, dispatch}, payload) {
        return axios.post('/company/api/sales/orders/' + state.order.id + '/approve-pickup-pictures', {
            batch_id: payload.batch_id,
            send_request: payload.sendRequest,
        }).then(res => {
            if (payload.sendRequest) {
                return dispatch('sendPickupRequest', {date: res.data.order.preferred_pickup_at});
            }
            commit('setOrder', res.data.order);
            return res.data;
        });
    },
    markTaskAsComplete({commit, state}, task) {
        return axios.post('/master-data/tasks/mark', {
            id: task.id,
            checked: true,
        }).then(res => {
            console.log('done');
            setTimeout(() => {
                commit('markTaskAsComplete', task.id);
            }, 3000);
            return res.data;
        });
    },
    denyPickupPictures({commit, state, dispatch}, payload) {
        return axios.post('/company/api/sales/orders/' + state.order.id + '/deny-pickup-pictures', {
            batch_id: payload.batch_id,
            reason: payload.reason,
        }).then(res => {
            commit('setOrder', res.data.order);
            return res.data;
        });
    },
    ...outsourceActions
}
