<template>
    <div>
        <div v-if="order.id">
            <div class="row">
                <div class="col-12">
                    <button v-if="!linkManual" @click="linkManual = true" class="btn w-100 btn-primary">Andere leverancier aanvragen</button>
                    <button v-if="linkManual" @click="linkManual = false" class="btn w-100 btn-secondary">Annuleren</button>
                </div>
            </div>
            <div class="row" v-if="linkManual">
                <div class="col">
                    <form-control class="form-item--compact" name="supplier_id" label="Leverancier" :edit="true">
                        <template v-slot:input>
                            <div class="form-item-select">
                                <dropdown-select class="form-view"
                                                 @onSelect="onLocationSelect"
                                                 :preload="false"
                                                 :itemCallback="(item) => ({
                                                    id: item.id,
                                                    value: item.name,
                                                 })"
                                                 url="/company/api/sales/orders/supplier/locations">
                                </dropdown-select>
                            </div>
                        </template>
                    </form-control>
                </div>
                <div class="col">
                    <form-control class="form-item--compact" name="price_excl" label="Prijs Excl. BTW" :edit="true">
                        <template v-slot:input>
                            <input v-model="priceExcl" type="text" class="form-control">
                        </template>
                    </form-control>
                </div>
                <div class="col">
                    <button type="button" :disabled="disabledClass !== ''" @click="saveRequest" :class="'btn w-100 btn-primary form-item--noLabel ' + disabledClass">Aanvraag opslaan</button>
                </div>
            </div>
        </div>


    </div>

</template>

<script>

    import FormControl from "../FormControl";

    export default {
        name: "ManualSupplierForm",
        components: { FormControl },

        data: () => ({
            drag: false,
            locations: [],
            priceExcl: '',
            location: null,
            linkManual: false,
        }),
        computed: {
            order() {
                return this.$store.state.order;
            },
            disabledClass() {
                if (!this.location) {
                    return 'disabled';
                }
                if (this.priceExcl === '') {
                    return 'disabled';
                }
                return '';
            }
        },
        methods: {
            onLocationSelect(value) {
                this.location = value;
            },
            saveRequest() {
                this.$store.dispatch('saveManualSupplier', {
                    location: this.location,
                    price_excl: this.priceExcl,
                    delivery_at: this.$store.state.order.preferred_delivery_at,
                }).then(() => {
                    this.linkManual = false;
                })
            },
        }
    }
</script>
