<template>
    <div class="input-group">
        <input type="text" id="human-readable" readonly class="form-control" :aria-label="t('labels.repeat')"
               :value="formatted" :disabled="!recurrent">

        <div class="input-group-append">
            <button class="btn btn-primary" type="button" :disabled="!recurrent" @click="toggleEditing()"
                    v-if="!editing || !recurrent">
                <i class="far fa-edit"></i>
            </button>

            <button class="btn btn-success js-btn-recurrency" type="button" :disabled="!valid" @click="save"
                    v-else>
                <i class="far fa-check"></i>
            </button>
        </div>
    </div>
</template>

<script>
    import Trans from "./mixins/Trans";

    export default {
        name: 'advanced-input',

        mixins: [Trans],

        props: {
            recurrent: Boolean,
            editing: Boolean,
            valid: Boolean,
            formatted: String
        },

        computed: {
            intRecurrent: {
                get() {
                    return this.recurrent
                },

                set(val) {
                    this.$emit('changeRecurrent', val);

                    if (val) this.$parent.editing = true
                }
            }
        },

        methods: {
            toggleEditing() {
                this.$emit('toggleEditing')
            },
            save(){
                this.$emit('toggleEditing')
                this.$emit('save');
            }
        }
    }
</script>
