<template>
    <div>
        <span v-if="!supplierRequest.is_available_in_evening">
            <i class="far text-warning fa-exclamation-triangle fa-fw" data-toggle="tooltip" title="Leverancier accepteert geen orders na 17u"></i>
        </span>
        <span v-if="!supplierRequest.is_available_in_weekend">
            <i class="far text-warning fa-exclamation-triangle fa-fw" data-toggle="tooltip" title="Leverancier accepteert geen orders in het weekend"></i>
        </span>
        <span v-if="(supplierRequest.supplier.accepts_cash === false && order.payment_method.is_cash_payment) || (supplierRequest.supplier.accepts_card === false && order.is_card_payment)">
            <i class="far text-warning fa-exclamation-triangle fa-fw" data-toggle="tooltip" :title="'Leverancier accepteert geen ' + getPaymentMethod().name"></i>
        </span>
        <span v-if="supplierRequest.is_unavailable">
            <i class="far text-warning fa-exclamation-triangle fa-fw" data-toggle="tooltip" title="Leverancier is niet beschikbaar op huidige datum. (Volmelding)"></i>
        </span>
        <span v-if="supplierRequest.oms_error">
            <i class="far text-danger fa-exclamation-triangle fa-fw" data-toggle="tooltip" :title="`OMS ERROR: ${supplierRequest.oms_error}`"></i>
        </span>
    </div>

</template>

<script>
    export default {
        name: "SupplierRequestWarning",
        props: ['supplierRequest'],
        mounted() {
            $('[data-toggle="tooltip"]').tooltip();
        },
        computed:{
            order(){
                return this.$store.state.order;
            }
        },
        methods: {
            getPaymentMethod() {
                return this.$store.state.paymentMethods.find(p => p.id === this.order.payment_method_id)
            },
        }

    }
</script>
