<template>
    <div v-if="linkedOrders.length">
        <div class="box-header">
            <h2 class="box-title">Openstaande orders</h2>
        </div>
        <div class="box-content">
            <div class="box-card box-card--single" v-for="linkedOrder in linkedOrders">
	            <div class="box-card-content">
		            <div class="box-product box-product--linked">
		                <div class="box-product-name-wrapper">
	                        <div class="box-product-image">
	                            <div class="tag-image" :style="'background-image: url(' +linkedOrder.product_image+');'"/>
	                        </div>
	                        <div class="box-product-name">
	                            {{linkedOrder.product}}
	                            <div class="box-product-type">
	                                {{linkedOrder.option_name}}
	                            </div>
	                        </div>
		                </div>
	                    <a data-confirm="Weet je zeker dat je deze pagina wil verlaten?" :href="'/orders/' + linkedOrder.id + '/edit'"><i class="far fa-arrow-right"></i></a>
	                </div>
	                <div class="form-row">

                        <form-control class="col form-item--compact" v-if=linkedOrder.delivery_at label="Leverdatum" name="delivery_at" :value="linkedOrder.delivery_at_formatted" :edit="false"></form-control>
                        <form-control class="col form-item--compact" v-if="!linkedOrder.delivery_at" label="Voorkeur Leverdatum" :value="linkedOrder.preferred_delivery_at_formatted" :edit="false"></form-control>
                        <form-control class="col form-item--compact" v-if=linkedOrder.pickup_at label="Ophaaldatum" name="delivery_at" :value="linkedOrder.pickup_at_formatted" :edit="false"></form-control>
                        <form-control class="col form-item--compact" v-if=!linkedOrder.pickup_at label="Voorkeur ophaaldatum" :value="linkedOrder.preferred_pickup_at_formatted" :edit="false"></form-control>

	                </div>
	                <div v-if="!linkedOrder.supplier_id" class="alert alert--orange">
	                    Nog geen leverancier gekoppeld
	                </div>
                    <div v-else>
                         <span v-if="linkedOrder.supplier.image_url" class="logo logo--withImage">
						                        <span class="logo-image" :style="'background-image: url(' + supplierRequest.supplier.image_url + ')'"></span>
						                    </span>
                        <span>{{linkedOrder.supplier.name}}</span>
                    </div>
	            </div>
            </div>
            <div v-if="!linkedOrders.length">
                Geen openstaande orders op dit adres
            </div>
        </div>
    </div>
</template>

<script>

    import FormControl from "./FormControl";

    export default {
        name: "LinkedOrders",
        components: {
            FormControl,
        },
        mounted() {
            if (this.deliveryAddress) {
                this.loadLinkedOrders(this.deliveryAddress);
            }
        },

        computed: {
            deliveryAddress() {
                return this.$store.state.order.delivery_address;
            },
            linkedOrders() {
                return this.$store.state.linkedOrders;
            }
        },
        methods: {
            loadLinkedOrders(deliveryAddress) {
                this.$store.dispatch('loadLinkedOrders', deliveryAddress);
            }
        },
        watch: {
            deliveryAddress: function (val) {
                if (!val) {
                    return;
                }
                this.loadLinkedOrders()
            }
        },
    }
</script>
