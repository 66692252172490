<template>
    <modal @onClose="$emit('onClose')" :show="show">
        <order-item-teaser v-if="orderItem" :order-item="orderItem"></order-item-teaser>

        <h3>Gekoppelde leverancier</h3>
        <table class="table table--noHeader" v-if="supplier">
            <tbody>
            <tr>
                <td>
                    <supplier-name :supplier="supplier"></supplier-name>
                </td>
                <td>
                    {{ order.accepted_supplier_request.calculated_price_excl | format_money }} (Excl. BTW)
                </td>
                <td>
		                <span class="text-success" v-if="marginPrice > 0">
		                    +{{ marginPrice | format_money }}
		                </span>
                    <span class="text-danger" v-if="marginPrice <= 0">
		                    -{{ marginPrice | format_money }}
		                </span>
                </td>
                <td>
                    Aangenomen op: <span>{{ order.accepted_supplier_request.accepted_at_date }} om {{ order.accepted_supplier_request.accepted_at_time }}</span>
                </td>
                <td>
                    <a :href="'/suppliers/' + supplier.id + '/rating'" class="btn btn-light" title="Waardering wijzigen" data-toggle="data-tooltip">
                        <i class="fal fa-star"></i>
                    </a>

                    <button type="button" @click="unlink = !unlink" :class="'btn ' + (unlink === true ? 'btn-primary' : 'btn-light')">
                        <i class="fal fa-unlink"></i>
                    </button>

                    <outbound-call-button v-if="supplier && supplier.order_intake_phone" :number="supplier.order_intake_phone">
                        <span class="btn btn-light" :title="supplier.order_intake_phone" data-toggle="data-tooltip">
                            <i class="fal fa-phone"></i>
                        </span>
                    </outbound-call-button>
                </td>
            </tr>
            </tbody>
        </table>

        <div class="d-flex justify-content-end">
            <button class="btn btn-secondary" @click="onOutsourceClick">Uitbesteden</button>
        </div>

        <h3>Plaatsing en ophaling</h3>
        <div v-if="supplier">

            <form-control label="Afleveradres" name="delivery_address_id" :value="order.delivery_address.full_address" :edit="false">
            </form-control>

            <div class="form-row">
                <div class="col">

                    <form-control label="Leverdatum" name="delivery_at" :value="order.delivery_at_formatted" :edit="false">
                        <template #action>
                            <i title="Geaccepteerd door leverancier" data-toggle="tooltip" class="fa text-success fa-check"></i>
                        </template>
                    </form-control>
                </div>
                <div class="col" v-if="hasNotSentPickupRequest">
                    <form-control label="Voorkeur ophaaldatum" name="date" :value="order.preferred_pickup_at" :edit="true">
                        <template #input>
                            <input class="form-control js-datepicker" type="text" v-model="pickupAt">
                        </template>
                        <template #label-icon>
                            <i title="Nog geen aanvraag verzonden!" data-toggle="tooltip" class="fa text-danger fa-times"></i>
                        </template>
                    </form-control>
                </div>
                <div class="col" v-if="pickupRequestIsPending">
                    <form-control label="Aangevraagde ophaaldatum" name="pickup_at" :value="order.preferred_pickup_at_formatted" :edit="false">
                        <template #label-icon>
                            <i title="Aanvraag verstuurd." data-toggle="data-tooltip" class="far text-warning fa-clock"></i>
                        </template>
                        <template #action>
                            <i @click="cancelRequest()" title="Aanvraag annuleren" data-toggle="data-tooltip" class="far pointer text-danger fa-times"></i>
                        </template>
                    </form-control>
                </div>
                <div class="col" v-if="order.pickup_at">
                    <form-control label="Definitieve ophaaldatum" name="pickup_at" :value="order.pickup_at_formatted" :edit="false">
                        <template #action>
                            <i title="Geaccepteerd door leverancier" data-toggle="data-tooltip" class="fa text-success fa-check"></i>
                        </template>
                    </form-control>
                </div>
            </div>
        </div>

        <div class="alert alert--warning" v-if="unlink">
            <strong>
                Let op, door deze keuze wordt de leverancier losgekoppeld
            </strong>
            <ul>
                <li>Er zal geen container geplaatst kunnen worden</li>
                <li>Er zal geen container opgehaald kunnen worden</li>
                <li>Inkoop memoriaal wordt mogelijk teruggedraaid</li>
            </ul>
        </div>
        <template #footer>
            <div>
                <button type="button" @click="close" class="btn btn-default">
                    <span v-if="!(unlink || hasNotSentPickupRequest)">Sluiten</span>
                    <span v-else>Annuleren</span>
                </button>
                <loading-button v-if="unlink || hasNotSentPickupRequest" child-class="btn btn-primary" @click.native="save" :loading="loading">
                    <span v-if="unlink">Leverancier ontkoppelen</span>
                    <span v-if="hasNotSentPickupRequest && !unlink">Aanvraag ophaling versturen</span>
                </loading-button>
            </div>
        </template>
    </modal>

</template>

<script>

import Modal from "../Modal";
import ProductTeaser from "../ProductTeaser";
import OrderItemTeaser from "../OrderItemTeaser";
import FormControl from "../FormControl";
import SupplierName from "./SupplierName";
import OutboundCallButton from "../../../OutboundCallButton";

export default {
    name: "SupplierModal",
    props: ['show', 'tooltip'],
    components: { SupplierName, FormControl, OrderItemTeaser, ProductTeaser, Modal, OutboundCallButton },
    data: () => ({
        unlink: false,
        pickupAt: null,
        loading: false,
    }),
    computed: {
        hasNotSentPickupRequest() {
            return !this.order.pickup_at && !this.order.pending_pickup_requests.length;
        },
        pickupRequestIsPending() {
            return this.order.preferred_pickup_at && !this.order.pickup_at && this.order.pending_pickup_requests.length;
        },
        supplier: function () {
            return this.$store.state.order.supplier;
        },
        orderItem: function () {
            return this.$store.state.order.order_items[0];
        },
        order: function () {
            return this.$store.state.order;
        },
        marginPrice: function () {
            return this.orderItem.total_price_excl - this.order.accepted_supplier_request.calculated_price_excl
        },
    },
    mounted() {
        $('[data-toggle="data-tooltip"]').tooltip();
        this.pickupAt = this.order.preferred_pickup_at;
    },
    methods: {
        onOutsourceClick() {
            this.close();
            this.$store.dispatch('startOutsourcing');
        },
        save() {
            this.loading = true;
            if (this.unlink) {
                this.unlinkSupplier();
                return;
            }
            this.$store.dispatch('sendPickupRequest', {
                date: this.pickupAt,
            }).then(() => {
                this.close();
            })
        },
        cancelRequest() {
            if (this.loading){
                return;
            }
            this.loading = true;
            this.$store.dispatch('cancelPickupRequest', {
                request_id: this.order.pending_pickup_requests[0].id,
            }).then(() => {
                this.loading = false;
            })
        },
        close() {
            this.loading = false;
            this.unlink = false;
            this.$emit('onClose');
        },
        unlinkSupplier() {
            this.$store.dispatch('unlinkSupplier').then(() => {
                this.close();
            })
        },
    },

}
</script>
