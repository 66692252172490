<template>
    <modal ref="modal" :show="$store.state.restartPurchaseInvoicing" @onClose="close">
        <template #title>
            <span>Herstart inkoopfacturatie</span>
        </template>

        <div>
            <order-item-teaser v-if="order.id" :with-customer="true" :order="order" :order-item="Object.assign({}, orderItem)"></order-item-teaser>
        </div>

        <div>
            <p>Mocht er na deze actie nog geen inkoopregel verschijnen. Controleer dan of er een inkoopprijs is ingevuld, en deze zichtbaar is in de order.</p>
            <div class="alert alert--warning">
                <b>Door deze actie zullen de volgende dingen in gang worden gezet.</b>
                <ol>
                    <li>Order item zal worden gemarkeerd als 'niet gefactureerd'</li>
                    <li>Er worden geen acties in het boekhoudpakket verricht</li>
                    <li>Mocht er een inkoopprijs zijn zal er een nieuwe inkoopregel aangemaakt worden met de meest recente prijs.</li>
                    <li>De nieuwe inkoopregel is binnen een paar seconden beschikbaar.</li>
                </ol>
            </div>
        </div>

        <template #footer>
            <div>
                <button type="button" @click="close" class="btn btn-default">
                    Sluiten
                </button>
                <loading-button child-class="btn btn-primary" @click.native="save" :loading="loading">
                    Herstart facturatie
                </loading-button>
            </div>
        </template>
    </modal>
</template>

<script>

import Modal from "./Modal";
import OrderItemTeaser from "./OrderItemTeaser";
import FormControl from "./FormControl";

export default {
    name: "RestartPurchaseInvoiceForm",
    components: {
        FormControl,
        OrderItemTeaser,
        Modal
    },

    data: () => ({
        loading: false,
    }),
    computed: {
        orderItem() {
            return this.order.order_items[0];
        },
        order() {
            return this.$store.state.order;
        }
    },

    methods: {
        close() {
            this.$store.dispatch('closeRestartPurchaseInvoicing');
        },
        save() {
            this.loading = true;
            this.$store.dispatch('restartPurchaseInvoicing').then(() => {
                this.close();
                this.loading = false;
                window.location.reload();
            }).catch(() => {
                this.loading = false;
                this.close();
                window.triggerAlert('Er is iets fout gegaan.', 'error');
            });
        }
    },
}
</script>
