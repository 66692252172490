export default {
    updateSupplierRequests(state, payload) {
        state.order.supplier_requests = payload;
    },
    setCustomer(state, payload) {
        state.order.customer = payload.customer;
        state.order.invoice_address = payload.invoice_address;
        state.order.phone_number = payload.phone_number;
    },
    setWebshop(state, payload) {
        state.order.webshop = payload.webshop;
    },
    setDeliveryPreferences(state, payload) {
        state.deliveryPreferences = payload
    },
    setPickupPreferences(state, payload) {
        state.pickupPreferences = payload
    },
    setOrderStatuses(state, payload) {
        state.orderStatuses = payload
    },
    markTaskAsComplete(state, id) {
        const index = state.order.tasks.findIndex(t => t.id === id);
        if (index === -1) {
            return;
        }
        state.order.tasks[index].completed_at = 'NOW';
    },
    setPaymentMethod(state, id) {
        state.order.payment_method_id = id
    },
    setIsProduction(state, isProduction) {
        state.isProduction = isProduction;
    },
    setCustomPriceTypes(state, customPriceTypes) {
        state.customPriceTypes = customPriceTypes;
    },
    setOrderOrigin(state, id) {
        state.order.order_origin_id = id
    },
    setPhoneNumber(state, payload) {
        state.order.phone_number = payload
    },
    setVatRates(state, payload) {
        state.vatRates = payload;
    },
    setOrderOrigins(state, payload) {
        state.orderOrigins = payload;
    },
    setExtraCostTypes(state, payload) {
        state.extraCostTypes = payload;
    },
    setPaymentMethods(state, payload) {
        state.paymentMethods = payload;
    },
    setErrors(state, payload) {
        state.errors = payload;
    },
    setCanCreateOrdersToday(state, payload) {
        state.canCreateOrdersToday = payload;
    },
    setExtraCosts(state, payload) {
        state.order.extra_costs = payload.extra_costs;
    },
    addOrderItems(state, newOrderItems) {
        newOrderItems.map(newOrderItem => {
            let oldOrderItemIndex = state.order.order_items.findIndex(o => {
                console.log(o.track_id, newOrderItem.track_id);
                return o.track_id === newOrderItem.track_id;
            });
            if (oldOrderItemIndex !== -1) {
                //setting index is not reactive
                let orderItemArray = Object.assign([], state.order.order_items);
                orderItemArray[oldOrderItemIndex] = newOrderItem;
                state.order.order_items = orderItemArray;
            } else {
                state.order.order_items.push(newOrderItem);
            }

        });
        state.isEditingOrderItem = false;
        state.editingOrderItem = {};
    },
    setDeliveryInfo(state, payload) {
        if (payload.delivery_address) {
            payload.delivery_address.full_address = payload.delivery_address.name;
        }

        state.order.preferred_delivery_at = payload.preferred_delivery_at;
        state.order.preferred_pickup_at = payload.preferred_pickup_at;
        state.order.delivery_preference_id = payload.delivery_preference_id;
        state.order.pickup_preference_id = payload.pickup_preference_id;
        state.order.preferred_pickup_at_formatted = payload.preferred_pickup_at_formatted;
        state.order.preferred_delivery_at_formatted = payload.preferred_delivery_at_formatted;
        state.order.delivery_address = payload.delivery_address;

        state.order.project_reference = payload.project_reference;

        state.order.call_before = payload.call_before;
        state.order.notes = payload.notes;
        state.order.payment_method_id = payload.payment_method_id;
        state.order.delivery_instructions = payload.delivery_instructions;
        state.order.pickup_instructions = payload.pickup_instructions;

        state.order.payment_method_id = payload.payment_method_id;
        state.order.payment_method = state.paymentMethods.find(p => p.id === payload.payment_method_id);

        return true;
    },
    setSupplier(state, { supplier }) {
        state.order.supplier = supplier;
    },
    setOrder(state, data) {
        state.order = data;
        if (data.id) {
            state.isConcept = false;
        }
    },
    setCallId(state, data) {
        state.callId = data;
    },
}
